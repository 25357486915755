import * as React from 'react';
import { useState, useRef } from 'react';
import { createdClaimSchema, DraftClaimStatus, submittedClaimSchema } from '@hobt/claim-domain';
import { ModuleMapping, UserRole } from '@hobt/constants';
import { LoanValidationErrorCode, LoanValidationResponse } from '@hobt/loan-domain';
import { hbtResolver } from '@hobt/schema-validator';
import { deepCopy } from '@hobt/utils';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

// Constants & Helper Functions
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

// Cards, Components , and Props
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { ProgressBar } from 'Components/ProgressBar';
import { StickyContainer } from 'Components/StickyContainer';
import { defaultClaimSubmissionData, pathNames, jsonReplaceToNull } from 'Constants';
import ClaimsAttachments from 'Feature/Claims/components/Cards/Attachments';
import BorrowerDetails from 'Feature/Claims/components/Cards/BorrowerDetails';
import ClaimsDetails from 'Feature/Claims/components/Cards/ClaimsDetails';
import ClaimsLenderDetails from 'Feature/Claims/components/Cards/ClaimsLenderDetails';
import DefaultManagement from 'Feature/Claims/components/Cards/DefaultManagement';
import DisplayNotificationCard, {
  sitecoreCardNameToValidationFieldKeyMapCreate
} from 'Feature/Claims/components/Cards/DisplayNotificationCard';
import ManualReview from 'Feature/Claims/components/Cards/ManualReview';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';
import IncomeDetails from 'Feature/Claims/components/Cards/IncomeDetails';
import ClaimsIndicatorDetailsSubmission from 'Feature/Claims/components/Cards/IndicatorDetailsSubmission';
import ListingPeriod from 'Feature/Claims/components/Cards/ListingPeriod';
import PartialPaymentDetails from 'Feature/Claims/components/Cards/PartialPaymentDetails';
import ClaimsPaymentCentre from 'Feature/Claims/components/Cards/PaymentCentre';
import ClaimsPropertyDetails from 'Feature/Claims/components/Cards/PropertyDetails';
import ClaimsValidation from 'Feature/Claims/components/Cards/Validation';
import ClaimsSubmissionFormProps from 'Feature/Claims/models/ClaimsSubmissionFormProps';
import GlossaryComponent from 'Feature/PageComponents/components/GlossaryComponent';
import { GlossaryComponentPropsFields } from 'Feature/PageComponents/models/GlossaryComponentProps';

// Submission service
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { getLoanValidationError } from 'Constants/Utils/ErrorMessage/loanValidationError';
import { convertBooleanValuesToStringInJsonObject } from 'Constants/Utils/JSONConverters/convertBooleanValuesToStringInJsonObject';
import { convertStringValuesToBoolean } from 'Constants/Utils/JSONConverters/convertStringToBoolean';
import { draftClaimJsonReplacer } from 'Constants/Utils/JSONReplacers/draftClaimJsonReplacer';
import BorrowerCharges from 'Feature/Claims/components/Cards/BorrowerCharges';
import { useLoanValidationService } from 'Feature/Claims/components/Cards/Validation/claimValidation.service';
import { ClaimValidationData } from 'Feature/Claims/components/Cards/Validation/types';
import HBTFormContextProvider from 'Feature/Claims/components/HBTFormContext';
import { ClaimsSubmissionCardProps } from 'Feature/Claims/models/ClaimsSubmissionFormCardsProps';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { useFeature } from 'flagged';
import { useAuthenticationContext } from 'Foundation/Authentication';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import { config } from '../../../../config';
import {
  masterEditStatusCall,
  saveClaimsDraft,
  submitClaimsRequest
} from './ClaimsSubmissionFormService';
import { useDraftClaimClient } from './draftClaimClient';
import LoanNotificationCard from './LoanNotificationCard';
import {
  BorrowerCharge,
  ClaimsSubmitResponse,
  DraftClaimResponseData,
  PartialPayment
} from './types';

const claimsSubmissionComponentsStep1: Record<string, React.FC<any>> = {
  claimsValidation: ClaimsValidation,
  claimsPaymentCentre: ClaimsPaymentCentre
};

const claimsSubmissionComponentsStep2: Record<string, React.FC<any>> = {
  claimsIndicatorDetails: ClaimsIndicatorDetailsSubmission,
  claimsLenderDetails: ClaimsLenderDetails,
  claimsBorrowerDetails: BorrowerDetails,
  claimsPropertyDetails: ClaimsPropertyDetails,
  claimDefaultManagement: DefaultManagement,
  claimsDetails: ClaimsDetails,
  claimsListingPeriod: ListingPeriod,
  claimsBorrowersCharges: BorrowerCharges,
  claimsIncomeDetails: IncomeDetails,
  claimsPartialPaymentDetails: PartialPaymentDetails,
  claimsAttachments: ClaimsAttachments,
  claimsManualReview: ManualReview
};

const ClaimsSubmissionForm = (props: ClaimsSubmissionFormProps) => {
  const history = useHistory();
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isExternalPortal = config.app.siteType === FeatureFlags.EXTERNAL;
  const [isValidLoanAndClaimType, setIsValidLoanAndClaimType] = useState<boolean>(true);
  const [isBypass, setBypass] = useState<boolean>(false);
  const [validLoanAndClaimType, setValidLoanAndClaimType] = useState<boolean>(false);
  const [showStep2Cards, setShowStep2Cards] = useState<boolean>(false);
  const [showClaimsToast, setShowClaimToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string | FieldValue>('');
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [draftClaimData, setDraftClaimData] = useState<DraftClaimResponseData>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showGlossary, setShowGlossary] = useState<boolean>(false);
  const [startOnGlossary, setStartOnGlossary] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [{ isLoading, spinnerHeading, spinnerDescription }, setLoadingStatus] = useState<Spinner>({
    isLoading: false
  });
  const [masterUserEdit, setMasterUserEdit] = useState<boolean>(false);
  const [sessionTimeout, setSessionTimeout] = useState<boolean>(false);
  const [continueSession, setContinueSession] = useState<boolean>(false);
  const [showEditInProgressToast, setShowEditInProgressToast] = useState<any | boolean>(true);
  const [isCurrentUserLocking, setIsCurrentUserLocking] = React.useState<boolean>(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState<boolean>(false);
  const [editStatusData, setEditStatusData] = React.useState({
    isBeingEdited: false,
    isBeingEditedByUserID: ''
  });

  const urlParams = new URLSearchParams(isServer() === false ? window.location.search : '');
  const id = urlParams.get('id');
  const draftClaimID = id ?? '';
  const errorNotificationRef = useRef<HTMLDivElement | null>(null);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const isMasterUser =
    isUserInRoles(
      ModuleMapping.claim,
      [UserRole.MasterUser],
      sitecoreContext?.user?.moduleRoleMapping
    ) && siteTypeIsInternal === true;

  const isDraftClaim = draftClaimData?.profile?.statusCode === DraftClaimStatus.InDraft;

  const loggedInUserID: string = sitecoreContext?.user?.userID;

  // Master user has access to all drafts regardless of owner
  const isDraftOwner = isMasterUser ? true : loggedInUserID === draftClaimData?.recordCreatedUserID;

  const isSubmitButtonDisabled = !isMasterUser
    ? loggedInUserID !== draftClaimData?.recordCreatedUserID
    : false;

  const { getDraftClaim } = useDraftClaimClient(authenticationContext, {
    timeout: config.claimApi.requestTimeout
  });

  const { validateLoanNumber } = useLoanValidationService(authenticationContext, {
    timeout: config.claimApi.requestTimeout
  });

  const modifyData = (data: any) => {
    const result = data;

    if (result.indicator?.manualReviewRequiredFlag) {
      result.indicator.manualReviewRequiredFlag =
        result.indicator.manualReviewRequiredFlag === true ||
        result.indicator.manualReviewRequiredFlag === 'true';
    }

    if (result.detail?.receivedDefaultInterestRatePercent) {
      result.detail.receivedDefaultInterestRatePercent = Number(
        result.detail.receivedDefaultInterestRatePercent
      );
    }

    if (result.detail?.receivedOutstandingPrincipalBalanceAmount) {
      result.detail.receivedOutstandingPrincipalBalanceAmount = Number(
        result.detail.receivedOutstandingPrincipalBalanceAmount
      );
    }

    if (result.detail?.receivedTaxAccountBalanceAmount) {
      result.detail.receivedTaxAccountBalanceAmount = Number(
        result.detail.receivedTaxAccountBalanceAmount
      );
    }

    if (result.property?.address?.provinceCode) {
      result.property.address.provinceCode = result.property?.address?.provinceCode?.toString();
    }

    if (result.listings && result.listings.length !== 0) {
      for (let i = 0; i < result.listings.length; i += 1) {
        if (result.listings[i].appraisalValue && result.listings[i].appraisalValue !== '') {
          result.listings[i].appraisalValue = Number(result.listings[i].appraisalValue);
        }

        if (result.listings[i].priceAmount && result.listings[i].priceAmount !== '') {
          result.listings[i].priceAmount = Number(result.listings[i].priceAmount);
        }
      }
    }

    return result;
  };

  const getDraftClaimData = async () => {
    try {
      setIsPageLoading(true);

      const response = await getDraftClaim(draftClaimID);
      const data = modifyData(response);

      setDraftClaimData(data);
    } catch (error) {
      setIsPageLoading(false);
      // @ts-ignore
      throw new Error(`Could not get draft claim data. ${error?.message}`);
    }
  };

  React.useEffect(() => {
    if (draftClaimID != null && draftClaimID !== '') {
      getDraftClaimData();
    }
  }, [draftClaimID]);

  const validationMethods = useForm({
    resolver: hbtResolver(createdClaimSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      cmhcLoanAccountNumber: undefined,
      approvedLenderReferenceNumber: '',
      approvedLenderFinancialInstitutionCode: '',
      approvedLenderTransitNumber: ''
    }
  } as Record<string, any>);

  const methods = useForm({
    resolver: hbtResolver(submittedClaimSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      ...defaultClaimSubmissionData
    }
  } as Record<string, any>);

  const {
    dirtyFields: isDirtyFieldsValidationMethods,
    isSubmitSuccessful: isValidationSubmitSuccessful
  } = validationMethods.formState;
  const { dirtyFields: isDirtyFieldsMethods, isSubmitSuccessful: isSubmissionSubmitSuccessful } =
    methods.formState;

  const isSubmissionFormDirty =
    !isValidationSubmitSuccessful &&
    !isSubmissionSubmitSuccessful &&
    (Object.keys(isDirtyFieldsValidationMethods).length > 0 ||
      Object.keys(isDirtyFieldsMethods).length > 0);

  React.useEffect(() => {
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  React.useEffect(() => {
    if (isServer() === false) {
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  // isDirty check
  React.useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if (isSubmissionFormDirty && !isBypass) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }
        return null;
      };
    }
  }, [isDirtyFieldsValidationMethods, isDirtyFieldsMethods, isBypass]);

  const isProcessInMicsDraftClaim =
    draftClaimData?.profile?.statusCode === DraftClaimStatus.ProcessInMics;

  const isDraftLocked =
    draftClaimData?.editingStatus !== undefined &&
    draftClaimData?.editingStatus?.isBeingEdited == true &&
    loggedInUserID !== draftClaimData?.editingStatus?.isBeingEditedByUserID;

  const handleEditLock = async (draftClaimID: string, isBeingEdited: boolean) => {
    let EditingStatus = {
      isBeingEdited
    };

    masterEditStatusCall(authenticationContext, draftClaimID, EditingStatus)
      .then((res: any) => {
        setEditStatusData(res.data?.data[0]?.editingStatus);
      })
      .catch((err: any) => {
        console.log(`something went wrong ${err}`);
      });
  };

  // Unlock draft when browser closes
  React.useEffect((): any => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault();
      if (conditionForUnlock) handleEditLock(draftClaimID, false);
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up the event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Unlock draft if current tab is closed
  React.useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (conditionForUnlock) handleEditLock(draftClaimID, false);
      event.preventDefault();
      event.returnValue = '';
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const conditionForLockOnComponentMount = React.useMemo(() => {
    if (isMasterUser) {
      return false;
    }
    return isDraftClaim && !isDraftLocked && isDraftOwner;
  }, [isDraftClaim, isDraftLocked, isDraftOwner, masterUserEdit, isMasterUser]);

  // making lock api call when component mounts
  React.useEffect((): any => {
    if (conditionForLockOnComponentMount) {
      if (draftClaimData?.draftClaimRecordID)
        handleEditLock(draftClaimData?.draftClaimRecordID, true);
      return () => {
        if (draftClaimData?.draftClaimRecordID)
          handleEditLock(draftClaimData?.draftClaimRecordID, false);
      };
    }
  }, [isDraftClaim, isMasterUser, isDraftLocked, conditionForLockOnComponentMount]);

  const conditionForUnlock = React.useMemo(() => {
    return (
      draftClaimData?.editingStatus?.isBeingEdited === true &&
      loggedInUserID === draftClaimData?.editingStatus?.isBeingEditedByUserID
    );
  }, [draftClaimData, loggedInUserID]);

  React.useEffect(() => {
    if (
      editStatusData?.isBeingEdited === true &&
      loggedInUserID === editStatusData?.isBeingEditedByUserID
    )
      setIsCurrentUserLocking(true);
  }, [editStatusData]);

  // Custom event listener for session timeout
  React.useEffect(() => {
    // Add event listener for the custom event
    const handleEvent = (e: any) => {
      setSessionTimeout(e?.detail?.sessionTimeOut);
      setContinueSession(e?.detail?.continueSession);
    };
    window.addEventListener('logoutEvent', handleEvent);
    return () => {
      window.removeEventListener('logoutEvent', handleEvent);
    };
  }, []);

  // Releasing lock when session times out
  React.useEffect(() => {
    if (isCurrentUserLocking) {
      if (sessionTimeout) {
        handleEditLock(draftClaimID, false);
      } else if (continueSession) {
        handleEditLock(draftClaimID, true);
      }
    }
  }, [sessionTimeout, isCurrentUserLocking]);

  // API unmount call for master user
  React.useEffect(() => {
    return () => {
      if (isDraftClaim && isMasterUser && editStatusData?.isBeingEdited === true) {
        handleEditLock(draftClaimID, false);
      }
    };
  }, [isDraftClaim, editStatusData]);

  // Logout event for claims
  const dispatchDefaultEvent = (editStatusData: any) => {
    const event = new CustomEvent('claimEvent', {
      detail: {
        draftClaimID,
        editingStatus: editStatusData
      }
    });
    window.dispatchEvent(event);
  };

  // For relesing locks when log out is clicked
  React.useEffect(() => {
    dispatchDefaultEvent(editStatusData);
    return () => {
      dispatchDefaultEvent(editStatusData);
    };
  }, [editStatusData]);

  const handleToastClose = () => {
    setShowEditInProgressToast(false);
    !isMasterUser && history.push(`/${i18n.language}${pathNames.claimsInventory}`);
  };

  React.useEffect(() => {
    if (isLoading === true) {
      // once the draft data has loaded and front-end logic has finished running, loading status can be updated
      setLoadingStatus({
        isLoading: false
      });
    }

    if (isPageLoading === true) {
      setIsPageLoading(false);
    }

    if (draftClaimData != null) {
      if (Array.isArray(draftClaimData.borrowers) && draftClaimData.borrowers.length === 0) {
        draftClaimData.borrowers = deepCopy(defaultClaimSubmissionData.borrowers);
      }

      const transformedDraftClaimData = draftClaimJsonReplacer(
        convertBooleanValuesToStringInJsonObject(draftClaimData)
      );

      if (
        Array.isArray(transformedDraftClaimData.borrowerCharges) &&
        transformedDraftClaimData.borrowerCharges.length > 0
      ) {
        transformedDraftClaimData.borrowerCharges.forEach(
          ({ code }: BorrowerCharge, index: number) => {
            methods.setValue(`borrowerCharges[${index}].code`, code);
          }
        );
      }

      methods.reset({ ...defaultClaimSubmissionData, ...transformedDraftClaimData });
    }
  }, [draftClaimData]);

  const updateClaimValidationData = () => {
    const data: any = validationMethods.getValues();
    methods.register('claimTypeIndicator');
    methods.setValue('claimTypeIndicator', data.claimTypeIndicator);

    methods.register('cmhcLoanAccountNumber');
    methods.setValue('cmhcLoanAccountNumber', data.cmhcLoanAccountNumber);

    methods.register('approvedLenderReferenceNumber');
    methods.setValue('approvedLenderReferenceNumber', data.approvedLenderReferenceNumber);

    methods.register('approvedLenderFinancialInstitutionCode');
    methods.setValue(
      'approvedLenderFinancialInstitutionCode',
      data.approvedLenderFinancialInstitutionCode
    );

    methods.register('approvedLenderTransitNumber');
    methods.setValue('approvedLenderTransitNumber', data.approvedLenderTransitNumber);

    if (
      methods.getValues()?.partialPaymentReceivedFlag === null ||
      methods.getValues()?.partialPaymentReceivedFlag === 'false'
    ) {
      methods.unregister('partialPayments');
    }

    if (
      methods.getValues()?.rentalIncomeReceivedFlag === null ||
      methods.getValues()?.rentalIncomeReceivedFlag === 'false'
    ) {
      methods.unregister('netOperatingIncomesRentalIncomes');
    }
  };

  const masterEdit = () => {
    if (isMasterUser && isDraftClaim) {
      setMasterUserEdit(true);
      handleEditLock(draftClaimID, true);
    }
  };

  React.useEffect(() => {
    updateClaimValidationData();
  }, [methods.register]);

  React.useEffect(() => {
    if (methods?.getValues()?.authorizedOfficers?.length === 1) {
      methods?.setValue(
        'lender.authorizedOfficers',
        methods?.getValues()?.lender?.authorizedOfficers?.slice(0, 1)
      );
    }
  }, [methods?.getValues()?.authorizedOfficers?.length]);

  const submitClaimsSubmissionForm = async (): Promise<void> => {
    setIsSubmitButtonClicked(true);
    setLoadingStatus({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description'
    });

    updateClaimValidationData();
    // TO DO : Need to investigate using the form data instead of the getValues data
    const formData: any = methods.getValues();

    formData.borrowerCharges = formData.borrowerCharges?.filter?.(
      (borrowcharges: BorrowerCharge) => borrowcharges.code !== ''
    );

    formData.partialPayments = formData.partialPayments?.filter(
      (partialPayment: PartialPayment) => partialPayment.receivedAmount !== undefined
    );

    const listingObj = formData.listings;
    delete listingObj.exceptionToListingFlag;

    /* eslint-disable no-console */
    console.log(`Data`, formData);
    formData.listings = Object.values(listingObj).slice();

    const result = await submitClaimsRequest(
      authenticationContext,
      JSON.parse(JSON.stringify(formData, convertStringValuesToBoolean))
    );

    switch (result) {
      case ClaimsSubmitResponse.Created: // Claims Submission Form successfully submitted
        setBypass(true);
        setToastState(ApplicationStates.SUCCESS);
        setToastMsg(i18n.t('ClaimsSubmission-Card-Save-ToastMessage'));
        setShowClaimToast(true);
        setLoadingStatus({
          isLoading: false
        });
        setIsSubmitButtonClicked(false);
        break;
      case ClaimsSubmitResponse.BadRequest: // trigger toast
      case ClaimsSubmitResponse.NotAuthorized:
      default:
        setBypass(false);
        setToastMsg(i18n.t('DefaultSubmission-OtherErrors'));
        setToastState(ApplicationStates.ERROR);
        setShowClaimToast(true);
        setLoadingStatus({
          isLoading: false
        });
        setIsSubmitButtonClicked(false);
    }
  };

  const onToastCloseHandler = () => {
    setShowClaimToast(false);
    if (isDraft) {
      setIsDraft(false);
      return;
    }
    history.push(`/${i18n.language}${pathNames.claimsInventory}`);
    setIsSubmitButtonClicked(false);
  };

  const getDraftValues = () => {
    updateClaimValidationData();
    // TO DO : Need to investigate using the form data instead of the getValues data
    const values = methods.getValues();
    const valuesStringified = JSON.stringify(values, jsonReplaceToNull);
    return JSON.parse(valuesStringified);
  };

  const saveDraft = async () => {
    if (isDraftClaim) {
      if (isMasterUser) setMasterUserEdit(false);

      handleEditLock(draftClaimID, false);
    }

    setIsSubmitButtonClicked(true);
    setLoadingStatus({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description'
    });

    const response = await saveClaimsDraft(authenticationContext, getDraftValues());

    setLoadingStatus({
      isLoading: false
    });
    setBypass(true);

    if (response === ClaimsSubmitResponse.Success) {
      setToastMsg(i18n.t('ClaimsSubmission-Card-Save-Draft-ToastMessage'));
      setToastState(ApplicationStates.SUCCESS);
      setShowClaimToast(true);
      setIsDraft(true);
      setIsSubmitButtonClicked(false);
    }
  };

  // TODO: This should handle the error of the forms and should not submit
  // Below code is for demo purpose and needs to be removed.
  const onError = async (errs: any): Promise<void> => {
    updateClaimValidationData();
    /* eslint-disable no-console */
    console.log('FORM ERRORS: ', errs); // Adding this here for troubleshooting until top of page validation and all cards are completed.
    /* eslint-disable no-console */

    console.log('data: ', methods.getValues()); // Adding this here for troubleshooting until top of page validation and all cards are completed.
    // TO DO : Need to investigate using the form data instead of the getValues data
    const formData: any = methods.getValues();
    const listingObj = formData.listings;
    delete listingObj.exceptionToListingFlag;

    formData.listings = Object.values(listingObj).slice();
    if (errorNotificationRef?.current != null) {
      errorNotificationRef.current.scrollIntoView();
    }
  };

  const updateLoanNumberValidationStatusOnDraftClaim = (isValid: boolean): void => {
    setValidLoanAndClaimType(isValid);
    setShowStep2Cards(isValid);
  };

  const createJsxElementForClaimsValidationCardStep1 = (
    card: ClaimsSubmissionCardProps,
    index: number
  ): JSX.Element | null => {
    if (
      card?.fields?.name?.value != null &&
      typeof claimsSubmissionComponentsStep1[card.fields.name.value] !== 'undefined'
    ) {
      return React.createElement(claimsSubmissionComponentsStep1[card.fields.name.value], {
        fields: card.fields,
        key: index,
        isValidLoanAndClaimType: validLoanAndClaimType,
        updateLoanNumberValidationStatusOnDraftClaim,
        siteType: config.app.siteType
      });
    }
    return null;
  };

  const createJsxElementForClaimsPaymentCentreCardStep1 = (
    card: ClaimsSubmissionCardProps,
    index: number
  ): JSX.Element | null => {
    if (
      card?.fields?.name?.value != null &&
      typeof claimsSubmissionComponentsStep1[card.fields.name.value] !== 'undefined'
    ) {
      return React.createElement(claimsSubmissionComponentsStep1[card.fields.name.value], {
        fields: card.fields,
        key: index
      });
    }
    return null;
  };

  const createJsxElementForClaimsSubmissionCardsStep2 = (
    card: ClaimsSubmissionCardProps,
    index: number
  ): JSX.Element | null => {
    if (
      card?.fields?.name?.value != null &&
      typeof claimsSubmissionComponentsStep2[card.fields.name.value] !== 'undefined'
    ) {
      if (card.fields.name.value === 'claimsManualReview') {
        return (
          <Element name={card.fields.name.value}>
            {React.createElement(claimsSubmissionComponentsStep2[card.fields.name.value], {
              fields: {
                ...card.fields,
                openGlossary: (glossaryName: string) => {
                  setShowGlossary(true);
                  setStartOnGlossary(glossaryName);
                }
              },
              key: index,
              siteType: config.app.siteType
            })}
          </Element>
        );
      }
      return (
        <Element name={card.fields.name.value}>
          {React.createElement(claimsSubmissionComponentsStep2[card.fields.name.value], {
            fields: {
              ...card.fields,
              openGlossary: (glossaryName: string) => {
                setShowGlossary(true);
                setStartOnGlossary(glossaryName);
              }
            },
            key: index,
            siteType: config.app.siteType
          })}
        </Element>
      );
    }
    return null;
  };

  const onCreateClaimSubmit: any = async (data: ClaimValidationData): Promise<void> => {
    setLoadingStatus({
      isLoading: true,
      spinnerHeading: 'Globals-Loading-Heading',
      spinnerDescription: 'Globals-Loading-Description'
    });

    const response = await validateLoanNumber(data);

    const { loanValidationResponse, draftClaim } = response;

    if (loanValidationResponse === LoanValidationResponse.Success) {
      setIsValidLoanAndClaimType(true);
      setValidLoanAndClaimType(true);
      setShowStep2Cards(true);
      if (draftClaim != null) {
        // some front-end logic is also causing delay so we want to keep the spinner displaying until all the logic is copmleted
        setDraftClaimData(draftClaim);
      } else {
        setLoadingStatus({
          isLoading: false
        });
      }
    } else {
      setValidLoanAndClaimType(false);
      setIsValidLoanAndClaimType(false);
      setValidationErrors([getLoanValidationError(loanValidationResponse)]);
      setLoadingStatus({
        isLoading: false
      });

      if (loanValidationResponse === LoanValidationResponse.InvalidLoanNumber) {
        // @ts-ignore
        validationMethods.setError('cmhcLoanAccountNumber', {
          type: 'manual',
          message: i18n.t(`Errors-${LoanValidationErrorCode.InvalidLoanNumberErrorCode}`)
        });
      }

      if (errorNotificationRef?.current != null) {
        errorNotificationRef.current.scrollIntoView();
      }
    }
  };

  const glossaryFields: GlossaryComponentPropsFields = {
    ...props?.rendering?.placeholders?.glossary[0]?.fields,
    isActive: showGlossary,
    startOnGlossary,
    closeCallback: () => {
      setShowGlossary(false);
    }
  };

  if (isPageLoading) {
    return <LoaderAnimation />;
  }

  return (
    <>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t(spinnerHeading ?? '') },
          description: { value: i18n.t(spinnerDescription ?? '') }
        }}
      />
      <Prompt
        when={isSubmissionFormDirty && !isBypass}
        message={i18n.t('DefaultSubmission-IsDirty')}
      />
      {isDraftClaim && isDraftLocked && (
        <ToastNotification
          type={ApplicationStates.CANCEL}
          isActive={showEditInProgressToast}
          title={i18n.t('DefaultSubmission-MasterEditToast-Title')}
          content={{
            value: i18n.t('DefaultSubmission-MasterEditToast-Content')
          }}
          onCloseCallback={handleToastClose}
        />
      )}
      <HBTFormContextProvider
        value={{ claimData: draftClaimData, uuid: draftClaimID, isClaimsDetails: false }}
      >
        <section className={`${styles.claimsSubmissionForm}`}>
          <div className="row">
            <div className="col-12">
              <h2 className="form-heading">
                <Text field={props.fields.pageTitle} />
                <span
                  className={`${styles.icon} material-icons-outlined icon--size-24`}
                  onClick={() => {
                    setShowGlossary(true);
                    setStartOnGlossary('');
                  }}
                  role="button"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      setShowGlossary(true);
                      setStartOnGlossary('');
                    }
                  }}
                  tabIndex={0}
                >
                  help_outline
                </span>
              </h2>
            </div>
          </div>
          <div ref={errorNotificationRef}>
            {isValidLoanAndClaimType ? (
              <DisplayNotificationCard
                errors={methods.formState.errors}
                cards={props.fields.steps[1].fields.leftContent[0].fields.cards}
                errorIcon={props.fields.formErrorIcon}
              />
            ) : (
              <LoanNotificationCard
                errors={validationErrors}
                errorIcon={props.fields.formErrorIcon}
              />
            )}
          </div>

          {
            <div className="row d-none d-xl-block">
              <div className="col-12">
                <div className="form-steps">
                  <Text field={props.fields.steps[0].fields.stepName} />
                </div>
                <h3 className="form-section-title" aria-hidden="true">
                  <Text field={props.fields.steps[0].fields.title} />
                </h3>
              </div>
            </div>
          }

          <fieldset disabled={isProcessInMicsDraftClaim}>
            <div className="row flex-column-reverse flex-xl-row">
              <section className="col-xl-8 col-md-12">
                <FormProvider {...validationMethods}>
                  <form
                    onSubmit={validationMethods.handleSubmit(onCreateClaimSubmit)}
                    id="claimValidationForm"
                    className="form claim-submission-form"
                    noValidate
                  >
                    {props.fields.steps[0].fields.leftContent?.map(
                      createJsxElementForClaimsValidationCardStep1
                    )}
                  </form>
                </FormProvider>
              </section>
              <div className="col-md-12 d-block d-xl-none">
                <div className="form-steps">
                  <Text field={props.fields.steps[0].fields.stepName} />
                </div>
                <h3 className="form-section-title">
                  <Text field={props.fields.steps[0].fields.title} />
                </h3>
              </div>
              <aside className={`${styles.paymentCenterMargin} col-xl-4 col-md-12`}>
                {props.fields.steps[0].fields.rightContent?.map(
                  createJsxElementForClaimsPaymentCentreCardStep1
                )}
              </aside>
            </div>
            {isValidLoanAndClaimType && showStep2Cards && (
              <>
                <hr className={`${styles.divider} form-divider`} />
                {
                  <div className="row">
                    <div className="col-12">
                      <div className="form-steps">
                        <Text field={props.fields.steps[1].fields.stepName} />
                      </div>
                      <h3 className="form-section-title">
                        <Text field={props.fields.steps[1].fields.title} />
                      </h3>
                    </div>
                  </div>
                }

                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(submitClaimsSubmissionForm, onError)}
                    id="claimsSubmissionForm"
                    className="form claim-submission-form"
                    noValidate
                  >
                    <div className="row">
                      <section className="col-xl-8 col-md-12">
                        <fieldset
                          disabled={
                            isDraftClaim
                              ? (isMasterUser && !masterUserEdit) ||
                                (!isMasterUser && !isDraftOwner) ||
                                isDraftLocked
                              : false
                          }
                        >
                          {props.fields.steps[1].fields.leftContent[0].fields.cards?.map(
                            createJsxElementForClaimsSubmissionCardsStep2
                          )}
                        </fieldset>
                      </section>
                      <aside className="col-xl-4 d-none d-xl-block">
                        {/* Possible TODO: Move the value of topOffset into configuration */}
                        <StickyContainer
                          shouldFillViewportHeight
                          containerId="claimsProgressBar"
                          topOffset={25}
                          shouldFillParentWidth={true}
                        >
                          <ProgressBar
                            bodyClassName="claims-progress-bar-body"
                            cards={props.fields.steps[1].fields.leftContent[0].fields.cards}
                            title={props.fields.steps[1].fields.rightContent[0].fields.heading}
                            isDisabled={isMasterUser || isSubmitButtonClicked}
                            isSecondaryBtnDisabled={
                              isMasterUser ? false : isDraftLocked || isSubmitButtonClicked
                            }
                            isSubmitButtonDisabled={
                              isMasterUser ? false : isDraftLocked || isSubmitButtonDisabled
                            }
                            primaryButtonText={
                              props.fields.steps[1].fields.rightContent[0].fields.submitButton
                            }
                            secondaryButtonText={
                              props.fields.steps[1].fields.rightContent[0].fields.saveDraftButton
                            }
                            tertiaryButtonText={
                              props.fields.steps[1].fields.rightContent[0].fields.editButton
                            }
                            tertiaryButtonOnClickCallback={masterEdit}
                            secondaryButtonOnClickCallback={saveDraft}
                            scrollDuration={500}
                            cardNameToValidationFieldMap={
                              sitecoreCardNameToValidationFieldKeyMapCreate
                            }
                            isMasterUser={isMasterUser}
                            isDraftClaim={isDraftClaim}
                          />
                        </StickyContainer>
                      </aside>
                      <div className="col-md-12 d-none d-md-block d-lg-none">
                        <ProgressBar
                          bodyClassName="claims-progress-bar-body"
                          cards={props.fields.steps[1].fields.leftContent[0].fields.cards}
                          title={props.fields.steps[1].fields.rightContent[0].fields.heading}
                          isDisabled={isMasterUser || isSubmitButtonClicked}
                          isSecondaryBtnDisabled={
                            isMasterUser ? false : isDraftLocked || isSubmitButtonClicked
                          }
                          isSubmitButtonDisabled={
                            isMasterUser ? false : isDraftLocked || isSubmitButtonDisabled
                          }
                          primaryButtonText={
                            props.fields.steps[1].fields.rightContent[0].fields.submitButton
                          }
                          secondaryButtonText={
                            props.fields.steps[1].fields.rightContent[0].fields.saveDraftButton
                          }
                          tertiaryButtonText={
                            props.fields.steps[1].fields.rightContent[0].fields.editButton
                          }
                          isDraftClaim={isDraftClaim}
                          isMasterUser={isMasterUser}
                          tertiaryButtonOnClickCallback={masterEdit}
                          secondaryButtonOnClickCallback={saveDraft}
                          scrollDuration={500}
                          cardNameToValidationFieldMap={
                            sitecoreCardNameToValidationFieldKeyMapCreate
                          }
                        />
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </>
            )}
          </fieldset>
        </section>
        <ToastNotification
          type={toastState}
          isActive={showClaimsToast}
          title={
            toastState === ApplicationStates.ERROR
              ? i18n.t('DefaultSubmission-ErrorToastTitle')
              : i18n.t('DefaultActions-SystemSuccessToast-Action-Title')
          }
          content={toastMsg}
          onCloseCallback={() => onToastCloseHandler()}
        />
        {props.rendering?.placeholders?.glossary &&
          React.createElement(GlossaryComponent, {
            fields: glossaryFields
          })}
      </HBTFormContextProvider>
    </>
  );
};

export default ClaimsSubmissionForm;
