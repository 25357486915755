import React, { useMemo } from 'react';
import {
  CorrespondenceApprovalAggregate,
  AdjudicationNoteCategoryInternal,
  CorrespondenceType
} from '@hobt/claim-domain';
import { ApprovalDecisionCode, Services } from '@hobt/constants';
import { useDocumentDownloader } from 'Components/Hooks/DocumentDownloader';
import SitecoreListItem from 'Constants/Types/SitecoreListItem';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { config } from '../../../../../config';
import { AddAttachmentsForm } from './AddAttachments';
import { AddCommentForm } from './AddComment';
import { AddInternalNoteForm } from './AddInternalNote';
import { CorrespondenceRowProps } from './CorrespondenceRow/types';
import { DocumentRequestForm } from './DocumentRequest';
import AttachmentsNotesCommentsDetailsProps, {
  FilterType,
  AttachmentsNotesCommentsDetailsState,
  ActionType,
  DrawerType,
  Action,
  SidedrawerData
} from './types';

type FeatureGroup = {
  [featureName: string]: boolean | FeatureGroup;
};

export const useCorrespondenceFunctions = (
  props: AttachmentsNotesCommentsDetailsProps,
  onSetCustomFilterParams: Function,
  refreshDataCallback: Function,
  state: AttachmentsNotesCommentsDetailsState<CorrespondenceApprovalAggregate>,
  dispatch: React.Dispatch<Action>,
  siteTypeInternal: boolean | FeatureGroup
) => {
  const {
    commentTypes,
    adjudicationNoteCategory,
    adjudicationDecisionCodes,
    categoryList,
    approvalDecision,
    approvedText,
    typeLabel,
    categoryLabel,
    deleteCorrespondenceLabel,
    documentRequestCodeCategoryList
  } = props.fields;

  const authContext = useAuthenticationContext();
  const apiClientConfig: ApiClientConfig = {
    timeout: config.defaultApi.requestTimeout
  };
  const correspondenceUrl: string = config.claimApi.urls.correspondences;
  const attachmentUrl: string = config.claimApi.urls.deleteAttachements;
  const { deleteWithAuth } = ApiClient(authContext, apiClientConfig);
  const { putWithAuth } = ApiClient(authContext, apiClientConfig);

  const { downloadDocument } = useDocumentDownloader(Services.CLAIM.toLowerCase(), () => {
    dispatch({ action: ActionType.SHOW_TOAST_API_ERROR });
  });

  // Function that builds dictionary
  const buildDictionary = (listItem: SitecoreListItem[]) => {
    const dictionary: Map<number, string> = new Map<number, string>();
    if (listItem != null) {
      listItem.forEach((item: SitecoreListItem) => {
        if (item.fields.itemValue.value != null && item.fields.itemName.value != null) {
          dictionary.set(Number(item.fields.itemValue.value), item.fields.itemName.value);
        }
      });
    }

    return dictionary;
  };

  // Dictionaries
  const commentTypesDictionary = useMemo(() => buildDictionary(commentTypes.fields.listItems), []);

  const adjudicationNoteCategoryDictionary = useMemo(
    () => buildDictionary(adjudicationNoteCategory.fields.listItems),
    []
  );

  const approvalDecisionCodesDictionary = useMemo(
    () => buildDictionary(adjudicationDecisionCodes.fields.listItems),
    []
  );
  const claimStatusOverrideDictionary = useMemo(
    () => buildDictionary(categoryList?.fields.listItems),
    []
  );

  const dismissToast = () => {
    dispatch({ action: ActionType.DISMISS_TOAST });
  };

  const onDocumentRequestError = () => {
    dispatch({
      action: ActionType.SHOW_TOAST_ERROR,
      payload: {
        errMsgType: 'Document-Download'
      }
    });
  };

  /**
   * @Deprecated
   * TODO: Hardening, should be done on backend
   */
  const correspondenceFilterFunction = (item: CorrespondenceApprovalAggregate) => {
    if (siteTypeInternal) {
      // filter type is set to default
      if (state.filteredValue === FilterType.Default) {
        return true;
      }

      // filter type is set to has Attachment
      if (state.filteredValue === FilterType.Attachments) {
        return (
          item.attachments != null ||
          (item.attachments != null && (item.attachments as any[]).length > 0)
        );
      }

      // else return items that match the comment type and doesn't have attachments
      return item.commentTypeCode === state.filteredValue && item.attachments == null;
    }

    return item.correspondenceTypeCode === CorrespondenceType.External;
  };

  const onFilter = (itemCategory: { label: string; value: string }) => {
    let param = commentTypesDictionary.get(Number(itemCategory.value));

    let { value } = itemCategory;

    if (param === undefined) {
      onSetCustomFilterParams([]);
    } else {
      if (param === 'Internal Note' || param === 'Note interne') {
        param = 'CommentTypeCode';
      }

      if (param === 'Attachment' || param === 'Pièce jointe') {
        param = 'AttachmentFlag';

        value = 'true';
      }

      if (
        param === 'Internal Note' ||
        param === 'Note interne' ||
        param === 'Comment' ||
        param === 'Commentaire'
      ) {
        param = 'CommentTypeCode';
      }

      const filter = { paramName: param, paramValue: value };

      if (filter != null) {
        onSetCustomFilterParams([filter]);
      } else {
        onSetCustomFilterParams([]);
      }
    }
  };

  const onDocumentSuccessCallback = () => {
    dispatch({ action: ActionType.HIDE_SIDE_DRAWER });
    dispatch({ action: ActionType.SHOW_TOAST_SUCCESS });
    refreshDataCallback();
  };
  const onDocumentCancelCallback = () => {
    dispatch({ action: ActionType.HIDE_SIDE_DRAWER });
  };
  const onDocumentErrorCallback = () => {
    dispatch({ action: ActionType.SHOW_TOAST_ERROR });
  };

  const openSideDrawer = (sideDrawer: DrawerType) => {
    if (
      siteTypeInternal === false &&
      (sideDrawer === DrawerType.AddInternalNoteForm ||
        sideDrawer === DrawerType.DocumentRequestForm)
    ) {
      return;
    }

    let sideDrawerData: SidedrawerData = {
      [DrawerType.AddAttachmentsForm]: {
        component: AddAttachmentsForm,
        fields: props.fields.addAttachmentFlyout.fields
      },
      [DrawerType.AddCommentForm]: {
        component: AddCommentForm,
        fields: props.fields.addCommentFlyout.fields
      }
    };

    if (siteTypeInternal === true) {
      sideDrawerData = {
        ...sideDrawerData,
        [DrawerType.AddInternalNoteForm]: {
          component: AddInternalNoteForm,
          fields: props.fields.addInternalNoteFlyout.fields
        },
        [DrawerType.DocumentRequestForm]: {
          component: DocumentRequestForm,
          fields: props.fields.documentRequestFlyout.fields
        }
      };
    }

    const { component, fields } = sideDrawerData[sideDrawer]!;
    dispatch({
      action: ActionType.SHOW_SIDE_DRAWER,
      payload: {
        sideDrawerComponent: component,
        sideDrawerComponentFields: fields
      }
    });
  };

  const closeSideDrawer = () => {
    dispatch({ action: ActionType.HIDE_SIDE_DRAWER });
  };

  const deleteCancelCorrespondenceCallback = () => {
    dispatch({ action: ActionType.DISMISS_DELETE_CONFIRMATION });
  };

  const deleteConfirmCorrespondenceCallback = async () => {
    if (state.correspondenceSelectedToDelete?.correspondenceId !== '') {
      await deleteWithAuth(
        `${correspondenceUrl}/${state.correspondenceSelectedToDelete?.correspondenceId}`
      )
        .then(() => {
          dispatch({ action: ActionType.SHOW_TOAST_Delete_SUCCESS });
        })
        .catch(() => {
          dispatch({ action: ActionType.SHOW_TOAST_API_ERROR });
        });
    } else {
      let data = {
        cmhcLoanAccountNumber: state.correspondenceSelectedToDelete.cmhcLoanAccountNumber,
        decisionTimestamp: state.correspondenceSelectedToDelete.decisionTimestamp,
        userID: state.correspondenceSelectedToDelete.userID
      };
      await putWithAuth(`${attachmentUrl}`, data)
        .then(() => {
          dispatch({ action: ActionType.SHOW_TOAST_Delete_SUCCESS });
        })
        .catch(() => {
          dispatch({ action: ActionType.SHOW_TOAST_API_ERROR });
        });
    }
    dispatch({ action: ActionType.DISMISS_DELETE_CONFIRMATION });
    refreshDataCallback();
  };

  const deleteCorrespondenceCallback = (
    correspondenceId: string,
    cmhcLoanAccountNumber: number,
    decisionTimestamp: string,
    userID: string
  ) => {
    dispatch({
      action: ActionType.SHOW_DELETE_CONFIRMATION,
      payload: { correspondenceId, cmhcLoanAccountNumber, decisionTimestamp, userID }
    });
  };

  const getCorrespondenceRowProps = (
    item: CorrespondenceApprovalAggregate,
    claimID: string
  ): CorrespondenceRowProps => {
    // Get Sender Name

    let senderName;
    if (item.attachments !== undefined) {
      if (
        (item.approverName &&
          (item.approvalDecisionCode === 1 || item.approvalDecisionCode === 2)) ||
        item.commentTypeCode === 2
      ) {
        senderName = item.approverName;
      } else {
        senderName = `${item.submitterFirstName ?? ''} ${item.submitterLastName ?? ''}`.trim();
      }
    } else if (
      item.remarkText &&
      item.remarkText.split(',')[0] === 'ClaimsDetails-ClaimApproved-AutomatedNote'
    ) {
      senderName = item.approverName;
    } else {
      senderName = item.correspondenceID
        ? `${item.submitterFirstName ?? ''} ${item.submitterLastName ?? ''}`.trim()
        : item.approverName;
    }

    // Get correspondence date string
    const displayTime = item.approverUserID ? item.decisionTimestamp : item.recordCreatedTimestamp;

    // Get correspondence tags
    const tags: string[] = [];

    const hasAttachments =
      ((item as any).attachmentFlag != null && (item as any).attachmentFlag === true) ||
      (Array.isArray(item.attachments) && item.attachments.length > 0);

    if (item.correspondenceID != null) {
      if (item.commentTypeCode != null) {
        const tag = commentTypesDictionary.get(item.commentTypeCode as number);
        if (tag != null) {
          tags.push(tag);
        }
      }
      if (hasAttachments) {
        const tag = commentTypesDictionary.get(FilterType.Attachments);
        if (tag != null && tags.indexOf(tag) === -1) {
          tags.push(tag);
        }
      }
    } else if (approvalDecision.value != null) {
      tags.push(approvalDecision.value);
    }

    const getDocumentRequestNotes = () => {
      if (
        Array.isArray(item.documentRequestCategoryCode) &&
        item.documentRequestCategoryCode.length > 0
      ) {
        const documentRequestCodes = item.documentRequestCategoryCode?.map(
          (document) =>
            documentRequestCodeCategoryList?.fields?.listItems?.find(
              (code) => code?.fields?.itemValue?.value === document
            )?.fields?.itemName?.value
        );

        return documentRequestCodes.length > 0 ? documentRequestCodes.join(', ') : undefined;
      }

      return undefined;
    };

    const getCorrespondenceTypeString = () => {
      if (item.correspondenceID) {
        if ((item as any).adjudicationNoteCategory) {
          return adjudicationNoteCategoryDictionary.get((item as any).adjudicationNoteCategory);
        }
        if ((item as any).attachmentFlag) {
          return commentTypesDictionary.get(FilterType.Attachments);
        }
        return commentTypesDictionary.get(item.commentTypeCode ?? -1);
      }
      if (item.approvalDecisionCode) {
        if (item.approvalDecisionCode === ApprovalDecisionCode.Approve) {
          return approvedText.value;
        }
        return approvalDecisionCodesDictionary.get(item.approvalDecisionCode);
      }
      return adjudicationNoteCategoryDictionary.get(AdjudicationNoteCategoryInternal.Other);
    };

    const correspondenceTypeString = getCorrespondenceTypeString();

    const documentRequestCodes = getDocumentRequestNotes();

    // Description
    const description = item.correspondenceID ? item.remarkText : item.decisionComment;

    const claimStatusOverrideTypeCode = item.claimStatusOverrideTypeCode
      ? claimStatusOverrideDictionary?.get(item.claimStatusOverrideTypeCode)
      : undefined;

    return {
      isInternal: false,
      isMasterUser: false,
      deleteCorrespondenceButtonLabel: deleteCorrespondenceLabel,
      senderName,
      dateString: displayTime,
      tags,
      correspondenceTypeString,
      description,
      attachments: item.attachments,
      onDocumentDownload: downloadDocument,
      documentTypeLabel: typeLabel,
      correspondenceId: item.correspondenceID ?? undefined,
      categoryLabelText: categoryLabel,
      claimStatusOverrideTypeCode,
      claimId: claimID,
      deleteCorrespondenceCallback,
      documentRequestCategoryCodes: documentRequestCodes
    };
  };

  return {
    onDocumentSuccessCallback,
    onDocumentErrorCallback,
    onDocumentCancelCallback,
    getCorrespondenceRowProps,
    dismissToast,
    onDocumentRequestError,
    correspondenceFilterFunction,
    onFilter,
    openSideDrawer,
    closeSideDrawer,
    deleteCancelCorrespondenceCallback,
    deleteConfirmCorrespondenceCallback
  };
};
