import React, { FC, useEffect } from 'react';
import {
  BorrowerChargesCode,
  borrowerChargesSchema,
  FinalBorrowerCharge
} from '@hobt/claim-domain';
import { LanguageShort } from '@hobt/constants';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import FormCard from 'Components/Common/FormCard';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { currencyFormat, getCurrencyFormat } from 'Components/Inputs/CommonFormFieldFormats';
import FormComment from 'Components/Inputs/FormComment';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import { LabelledBy as FormDropdownLabelledBy } from 'Components/Inputs/FormDropdown';
import FormNumber from 'Components/Inputs/FormNumber';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { TooltipIcon } from 'Components/Inputs/TooltipIcon';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import ClaimsBorrowerChargesProps from 'Feature/Claims/models/ClaimsBorrowerChargesProps';
import { ButtonType } from 'Feature/CommonComponents/UserControls';
import { GlossaryNames } from 'Feature/PageComponents/components/GlossaryComponent/types';
import i18n from 'i18next';
import { useFieldArray, useWatch, useFormContext, UseFieldArrayRemove } from 'react-hook-form';
import { LanguageSwitchTypeCode } from '../ClaimsLenderDetails/types';
import styles from './styles.module.scss';

const CURRENCY_FORMAT = {
  ...currencyFormat,
  allowNegative: true
};

type BorrowerCharge = {
  indexNumber?: number | string | null;
  code?: string;
  receivedDate?: string;
  receivedAmount: number | string | undefined;
  comment: string;
  originalClaimSequenceNumber?: any;
  adjustedAmount?: string | number | null;
  adjustedDate?: string | number | null;
  id?: any;
};

type TFieldArrayResult = {
  fields: BorrowerCharge[];
  append: any;
  remove: UseFieldArrayRemove;
};

const DEFAULT_BORROWER_CHARGE: BorrowerCharge = {
  code: '',
  receivedDate: undefined,
  receivedAmount: undefined,
  comment: ''
};

const ABRender: FC<{
  first: boolean;
  children?: React.ReactNode;
}> = ({ first, children }) => {
  const [FirstChild, SecondChild] = React.Children.toArray(children) as Array<JSX.Element>;
  return first ? FirstChild : SecondChild;
};

export default ({ fields }: ClaimsBorrowerChargesProps) => {
  const { toCurrency } = useTranslationHelpers();
  const {
    claimData,
    isClaimsDetails,
    isInEditMode,
    isFieldDisabled,
    isMasterUserEditingPostAdjudicationClaim
  } = useHBTFormContext();
  const { register, trigger, setValue, getValues } = useFormContext();
  const {
    fields: borrowerCharges,
    append,
    remove
  }: TFieldArrayResult = useFieldArray({
    name: 'borrowerCharges'
  });
  const charges = useWatch({ name: 'borrowerCharges' }) as BorrowerCharge[];
  const totalCharges = (charges ?? [])
    ?.map((c) => (c.receivedAmount as number) || 0)
    ?.reduce((total, charge) => total + charge, 0)
    ?.toFixed(2);

  const rowAdditions: Array<[number, FieldValue]> = [
    [1, fields.rowOne],
    [5, fields.rowFive],
    [10, fields.rowTen]
  ];

  useEffect(() => {
    // For the claim detail page, filter out previous borrower charges (if any).
    if (isClaimsDetails === true || isInEditMode === false) {
      const borrowerChargesData = claimData?.borrowerCharges?.filter?.(
        (borrowerCharge: FinalBorrowerCharge) =>
          borrowerCharge.originalClaimSequenceNumber === claimData?.sequenceNumber
      );

      // We use a setValue here instead of a remove as the remove function deletes the entirety of the data
      setValue('borrowerCharges', borrowerChargesData);

      borrowerChargesData?.forEach((borrowerCharge: FinalBorrowerCharge, index: number) => {
        if (borrowerCharge.indexNumber != null) {
          register(`borrowerCharges.${index}.indexNumber`);
          setValue(`borrowerCharges.${index}.indexNumber`, borrowerCharge.indexNumber);
        }
        if (borrowerCharge.adjustedAmount != null) {
          register(`borrowerCharges.${index}.adjustedAmount`);
          setValue(`borrowerCharges.${index}.adjustedAmount`, borrowerCharge.adjustedAmount);
        }

        if (borrowerCharge.adjustedDate != null) {
          register(`borrowerCharges.${index}.adjustedDate`);
          setValue(`borrowerCharges.${index}.adjustedDate`, borrowerCharge.adjustedDate);
        }

        if (borrowerCharge.comment != null) {
          register(`borrowerCharges.${index}.comment`);
          setValue(`borrowerCharges.${index}.comment`, borrowerCharge.comment);
        }
        /*
          set value of code after registering manually when the borrower charge is disabled and
          values are not set automatically by RHF since fields are not binded
        */

        if (isMasterUserEditingPostAdjudicationClaim === true) {
          register(`borrowerCharges[${index}].code`);
          setValue(`borrowerCharges[${index}].code`, borrowerCharge.code);
        }
      });
    }
  }, [isClaimsDetails, isInEditMode]);

  useEffect(() => {
    const selectedBorrowerCharges = [];
    for (let i = 0; i < borrowerCharges.length; i++) {
      if (borrowerCharges[i].originalClaimSequenceNumber) {
        if (borrowerCharges[i].originalClaimSequenceNumber === claimData?.sequenceNumber) {
          selectedBorrowerCharges.push(borrowerCharges[i]);
        }
      } else {
        selectedBorrowerCharges.push(borrowerCharges[i]);
      }
    }

    borrowerCharges?.forEach((borrowerCharge, index: number) => {
      if (borrowerCharge.indexNumber != null) {
        register(`borrowerCharges.${index}.indexNumber`);
        setValue(`borrowerCharges.${index}.indexNumber`, borrowerCharge.indexNumber);
      }

      if (borrowerCharge.adjustedAmount != null) {
        register(`borrowerCharges.${index}.adjustedAmount`);
        setValue(`borrowerCharges.${index}.adjustedAmount`, borrowerCharge.adjustedAmount);
      }

      if (borrowerCharge.adjustedDate != null) {
        register(`borrowerCharges.${index}.adjustedDate`);
        setValue(`borrowerCharges.${index}.adjustedDate`, borrowerCharge.adjustedDate);
      }

      /*
          set value of code after registering manually when the borrower charge is disabled and
          values are not set automatically by RHF since fields are not binded
        */

      if (isMasterUserEditingPostAdjudicationClaim === true) {
        register(`borrowerCharges.${index}.code`);
        setValue(`borrowerCharges.${index}.code`, borrowerCharge.code);
      }
    });
  }, [borrowerCharges.length]);

  return (
    <FormCard
      title={fields.cardTitle}
      headingLevel={2}
      sectionId="claims-borrowers-charges"
      fieldToValidate="borrowerCharges"
      toolTipButton={{
        id: 'borrowerGlossaryTooltip',
        name: 'borrowerGlossaryTooltip',
        ariaText: fields.cardGlossaryAriaText?.value ?? '',
        onClick: () => {
          fields?.openGlossary?.(GlossaryNames.BorrowerCharges);
        },
        buttonType: ButtonType.TEXT
      }}
    >
      <div className={styles.headings}>
        <span id="cbc_code" className={['heading', 'charge-code'].map((s) => styles[s]).join(' ')}>
          <Text field={fields.codeofCharge} />
        </span>
        <span
          id="cbc_paid-date"
          className={['heading', 'date-paid'].map((s) => styles[s]).join(' ')}
        >
          <Text field={fields.datePaid} />
        </span>
        <span id="cbc_amount" className={['amount'].map((s) => styles[s]).join(' ')}>
          <Text field={fields.amount} />
        </span>
        <span className="sr-only" id="cbc_comment">
          <Text field={fields.comments} />
        </span>
        <span className="sr-only" id="cbc_delete">
          Delete
        </span>
      </div>
      <hr className={styles.separator} />
      {borrowerCharges?.map((field, index) => (
        <React.Fragment key={field.id}>
          <div className={styles['charge-row']}>
            <FormDropdownLabelledBy
              name={`borrowerCharges[${index}].code`}
              className={styles['charge-code']}
              label="cbc_code"
              isDisabled={isFieldDisabled || isMasterUserEditingPostAdjudicationClaim}
              options={fields.borrowerChargeCodes[0].fields.listItems}
              defaultValue={field?.code}
            />
            <FormDatepicker
              name={`borrowerCharges[${index}].receivedDate`}
              className={`${styles['date-paid']} ${
                i18n.language === LanguageSwitchTypeCode.French ? styles.datePaidPullDown : ''
              }`.trim()}
              label={fields.datePaid}
              isReadOnly={isFieldDisabled || isMasterUserEditingPostAdjudicationClaim}
            />
            <FormNumber
              name={`borrowerCharges[${index}].receivedAmount`}
              className={styles.amount}
              label={fields.amount}
              formatProps={getCurrencyFormat(
                getValues(`borrowerCharges[${index}].receivedAmount`),
                CURRENCY_FORMAT,
                i18n.language as LanguageShort
              )}
              isReadOnly={isFieldDisabled || isMasterUserEditingPostAdjudicationClaim}
              onBlurValidationRequire
            />
            <button
              type="button"
              className={styles['delete-row-button']}
              onClick={() => remove(index)}
              disabled={isFieldDisabled || isMasterUserEditingPostAdjudicationClaim}
            >
              <span>
                <TooltipIcon
                  icon={fields.deleteIcon}
                  text={i18n.t('DefaultSubmission-Card-Delete')}
                  className={'icon-16'}
                />
              </span>
            </button>
            <ABRender first={charges[index]?.code === BorrowerChargesCode.Other}>
              <FormTextArea
                name={`borrowerCharges[${index}].comment`}
                className={['comment-row', 'single-comment-row'].map((s) => styles[s]).join(' ')}
                label={fields.comments ?? { value: '' }}
                attributeProps={{ rows: 1 }}
                charLimit={borrowerChargesSchema.properties.comment.maxLength}
                textAreaHelperText={fields.characterLimit.value}
                isReadOnly={isFieldDisabled || isMasterUserEditingPostAdjudicationClaim}
              />
              <FormComment
                name={`borrowerCharges[${index}].comment`}
                className={styles['comment-row']}
                label={fields.comments.value}
                updateLabel={fields.update?.value}
                cancelLabel={fields.cancel?.value}
                characterLimitLabel={fields.characterLimit?.value}
                addCommentLabel={fields.addComments?.value}
                editCommentLabel={fields.editComment?.value}
                isDisabled={isFieldDisabled || isMasterUserEditingPostAdjudicationClaim}
              />
            </ABRender>
          </div>
          <hr className={styles.separator} />
        </React.Fragment>
      ))}
      <div className={`${styles.full} d-flex flex-column align-items-end`}>
        <span>
          <Text tag={'h3'} field={fields.totalLabel} />
        </span>
        <output htmlFor="borrowerCharges">
          <strong>{toCurrency(parseFloat(totalCharges))}</strong>
        </output>
      </div>
      <hr className={styles['full-separator']} />
      <div className={`${styles.full} d-flex`}>
        {rowAdditions?.map(([count, fieldText]) => (
          <button
            key={count}
            className={styles['add-rows-button']}
            type="button"
            onClick={() => append(Array(count).fill(DEFAULT_BORROWER_CHARGE))}
            disabled={
              isFieldDisabled ||
              isMasterUserEditingPostAdjudicationClaim ||
              (count === 10 && borrowerCharges?.length > 290) ||
              (count === 5 && borrowerCharges?.length > 295) ||
              (count === 1 && borrowerCharges?.length > 299)
            }
            aria-label={`${fields.add?.value} ${fieldText.value}`}
          >
            <span aria-hidden={true} className={`${styles['add-rows-button_icon']} material-icons`}>
              add_circle_outline
            </span>
            <Text field={fieldText} />
          </button>
        ))}
      </div>
    </FormCard>
  );
};
