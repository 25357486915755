/* eslint-disable */
import axios from 'axios';
import * as msal from '@azure/msal-browser';
import i18n from 'i18next';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { ApplicationException, useAsyncError } from 'Foundation/Error';

import {
  AuthenticationContextProps,
  AuthenticationContextType,
  MethodOfEnteringApp,
  TermsAndConditionsFlags
} from './AuthenticationContext.types';
import { config } from '../../config';
import { pathNames, sitecoreRouteNames } from '../../Constants';

const METHODS_OF_ENTERING_APP_TO_CHECK_FOR_TERMS = [
  MethodOfEnteringApp.POST_LOGIN_REDIRECT,
  MethodOfEnteringApp.DEEP_LINKED_URL_ACCESSED_DIRECTLY_WHEN_ALREADY_LOGGED_IN
];
export const AuthenticationContext = React.createContext<AuthenticationContextType>(undefined!);

export const AuthenticationProvider: FC<AuthenticationContextProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  Redirect,
  sitecoreRouteName,
  sitecoreContextFactory,
  route,
  children
}: AuthenticationContextProps) => {
  const history = useHistory();
  const throwError = useAsyncError();

  const localStorageKeyTermsAccepted = 'terms-accepted';
  const localStorageValueTermsAccepted = 'Y';
  const appRootUri = !isServer() ? window.location.origin : '';
  const signInRedirectUrl = `${appRootUri}/${sitecoreRouteNames.redirect}`;
  const enableTerms = config.app.siteType === FeatureFlags.EXTERNAL;

  const axiosInstance = axios.create({ timeout: config.auditApi.requestTimeout });
  const cancelTokenSource = axios.CancelToken.source();

  // timestamp endpoints
  const logoutTimestampUrl = config.auditApi.urls.updateLogoutTimestamp;
  const termsOfUseTimestampUrl = config.auditApi.urls.updateTermsOfUseTimestamp;

  const msalConfig = {
    auth: {
      authority: config.auth.activeDirectory.authority || '',
      clientId: config.auth.activeDirectory.clientId || '',
      postLogoutRedirectUri: appRootUri,
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: number, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.info(message);
          }
        }
      }
    }
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  const initialIsAuthenticated = msalInstance && msalInstance.getAllAccounts().length > 0;
  const [isAuthenticated, setIsAuthenticated] = useState(initialIsAuthenticated ?? false);

  // set display to be true by default in case api call for the flag fails
  const [termsAndConditionsFlags, setTermsAndConditionsFlags] = useState<TermsAndConditionsFlags>({
    display: true
  });

  /**
   * use for update audit fields; error handling not included intentionally as
   * the error is not to be displayed to the user if any audit update fails;
   * ApiClient not being used as it makes user of useAuthenticationContext (depends
   * on this current context created here)
   */
  const patchWithAuth = async (resourceUrl: string) => {
    const bearerToken = await getToken([config.api.hbtApiScope ?? '']);

    const fullRequestConfig = {
      cancelToken: cancelTokenSource.token,
      headers: { Authorization: `Bearer ${bearerToken}` }
    };

    axiosInstance.patch(resourceUrl, {}, fullRequestConfig);
  };

  const getWithAuth = async (resourceUrl: string) => {
    const bearerToken = await getToken([config.api.hbtApiScope ?? '']);

    const fullRequestConfig = {
      cancelToken: cancelTokenSource.token,
      headers: { Authorization: `Bearer ${bearerToken}` }
    };

    return axiosInstance.get(resourceUrl, fullRequestConfig);
  };

  const handleLoginResponse = (loginResponse: msal.AuthenticationResult) => {
    return loginResponse.idToken != null;
  };

  const signInPopup = async () => {
    const loginRequest: msal.RedirectRequest = {
      scopes: ['openid'],
      redirectUri: signInRedirectUrl
    };

    const loginResponse = await msalInstance.loginPopup(loginRequest);
    handleLoginResponse(loginResponse);
  };

  const getSignInCompleteStartPath = () => {
    const basePath =
      enableTerms && termsAndConditionsFlags.display === true
        ? pathNames.termsAndConditions
        : pathNames.dashboard;
    return `/${i18n.language}${basePath}`;
  };

  const signInSilently = async (userEmail: string) => {
    const loginRequest = {
      scopes: ['openid'],
      loginHint: userEmail,
      redirectUri: signInRedirectUrl
    };

    try {
      const loginResponse = await msalInstance.ssoSilent(loginRequest);

      return handleLoginResponse(loginResponse);
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        const loginResponse = await msalInstance.loginPopup(loginRequest).catch((error: any) => {
          throw error;
        });

        return handleLoginResponse(loginResponse);
      }
      throw err;
    }
  };

  const clearUserDataFromSitecoreContext = () => {
    const context = sitecoreContextFactory.sitecoreContext;
    sitecoreContextFactory.updateSitecoreContext({
      ...context,
      user: null
    });
  };

  const resetTermsAcceptance = () => {
    if (!isServer()) {
      window.localStorage.removeItem(localStorageKeyTermsAccepted);
    }
  };

  const signOut = async () => {
    try {
      setIsAuthenticated(false);
      await patchWithAuth(logoutTimestampUrl);
      clearUserDataFromSitecoreContext();
      resetTermsAcceptance();
      await new Promise(resolve => setTimeout(resolve, 300)); // delaying the logout page redirection
      return msalInstance.logoutRedirect();
    } catch (err) {
      return Promise.reject();
    }
  };

  const signInWithRedirect = () => {
    resetTermsAcceptance();

    const loginRequest: msal.RedirectRequest = {
      scopes: ['openid'],
      redirectUri: signInRedirectUrl
    };

    return msalInstance.loginRedirect(loginRequest);
  };

  const getUsername = () => {
    const accounts = msalInstance.getAllAccounts();
    return accounts === null || accounts.length < 1 ? '' : accounts[0].username;
  };

  const getTokenPopup = async (requestedScopes: string[]): Promise<string | void> => {
    const request = {
      scopes: requestedScopes,
      redirectUri: window.location.origin
    };

    const tokenResponse = await msalInstance.acquireTokenPopup(request);
    return tokenResponse.accessToken;
  };

  const getToken = async (requestedScopes: string[]): Promise<string | void> => {
    try {
      const accounts = msalInstance.getAllAccounts();

      if (!accounts || accounts.length <= 0) {
        return getTokenPopup(requestedScopes);
      }

      const request = {
        scopes: requestedScopes,
        account: accounts[0],
        // redirectUri: window.location.origin
      };

      const tokenResponse = await msalInstance.acquireTokenSilent(request);

      return tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return getTokenPopup(requestedScopes);
      }
      throw error;
    }
  };

  const doesCurrentRouteRequireAuthentication = (): boolean => {
    if (!config.auth.validateAuthenticatedUsersOnSecureRoutes) {
      return false;
    }

    if (route.fields) {
      const requiresAuthentication = route.fields.isPrivate as any;
      if (requiresAuthentication && requiresAuthentication.value === false) {
        return false;
      }
    }

    // To be on safe side, default to needing authentication
    return true;
  };

  const acceptTermsAndConditions = (): void => {
    if (!isServer()) {
      window.localStorage.setItem(localStorageKeyTermsAccepted, localStorageValueTermsAccepted);
      patchWithAuth(termsOfUseTimestampUrl);
    }
  };

  const isTermsAndConditionsAccepted = (): boolean => {
    // If no validation of T and C is necessary, just pretend it's always accepted to skip the validation
    if (!enableTerms || !config.auth.validateTermsAndConditionsAcceptance) return true;

    if (!isServer()) {
      const termsAccepted = window.localStorage.getItem(localStorageKeyTermsAccepted);

      if (!termsAccepted || termsAccepted !== localStorageValueTermsAccepted) return false;
    }

    return true;
  };

  useEffect(() => {
    const { methodOfEnteringApp } = termsAndConditionsFlags;

    if (
      methodOfEnteringApp != null &&
      METHODS_OF_ENTERING_APP_TO_CHECK_FOR_TERMS.includes(methodOfEnteringApp)
    ) {
      history.push(getSignInCompleteStartPath());
    }
  }, [termsAndConditionsFlags.display]);

  useEffect(() => {
    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        // Post-login AD token.  Only time this handler will get called is on the redirect route as that is the only configured redirect route in AD.
        if (tokenResponse != null) {
          /**
           * update login timestamp; error handling not included intentionally as
           * the error is not to be displayed to the user if time stamp update fails
           */
          patchWithAuth(config.auditApi.urls.updateLoginTimestamp);
          setIsAuthenticated(true);

          getWithAuth(config.userApi.urls.getTermsOfUseDisplayFlag)
            .then((termsDisplayResponse) => {
              setTermsAndConditionsFlags({
                display: termsDisplayResponse?.data?.data?.[0]?.displayTermsOfUseAcceptance ?? true,
                methodOfEnteringApp: MethodOfEnteringApp.POST_LOGIN_REDIRECT
              });
            })
            .catch((_err) => {
              setTermsAndConditionsFlags({
                display: true,
                methodOfEnteringApp: MethodOfEnteringApp.POST_LOGIN_REDIRECT
              });
            });
        }
        // User directly navigated to a URL or deep linked
        if (msalInstance.getAllAccounts().length > 0) {
          // Force the user to accept or reject terms and conditions
          if (
            !isTermsAndConditionsAccepted() &&
            doesCurrentRouteRequireAuthentication() &&
            isAuthenticated &&
            !isServer() &&
            !window.location.pathname.endsWith(pathNames.termsAndConditions)
          ) {
            getWithAuth(config.userApi.urls.getTermsOfUseDisplayFlag)
              .then((termsDisplayResponse) => {
                setTermsAndConditionsFlags({
                  display:
                    termsDisplayResponse?.data?.data?.[0]?.displayTermsOfUseAcceptance ?? true,
                  methodOfEnteringApp:
                    MethodOfEnteringApp.DEEP_LINKED_URL_ACCESSED_DIRECTLY_WHEN_ALREADY_LOGGED_IN
                });
              })
              .catch((_err) => {
                setTermsAndConditionsFlags({
                  display: true,
                  methodOfEnteringApp:
                    MethodOfEnteringApp.DEEP_LINKED_URL_ACCESSED_DIRECTLY_WHEN_ALREADY_LOGGED_IN
                });
              });
          }
        }
        // Unauthorized user
        else if (
          config.auth.validateAuthenticatedUsersOnSecureRoutes &&
          doesCurrentRouteRequireAuthentication()
        ) {
          history.push(`/${i18n.language}${pathNames.landing}`);
        }
      })
      .catch(() => {
        throwError(new ApplicationException('Errors-AzureSignInFailure'));
      });
  }, []);

  /*
    Force the user to accept or reject terms and conditions.
  */
  if (
    doesCurrentRouteRequireAuthentication() &&
    isAuthenticated &&
    !isTermsAndConditionsAccepted() &&
    termsAndConditionsFlags.display === true &&
    !window.location.pathname.endsWith(pathNames.termsAndConditions)
  ) {
    getWithAuth(config.userApi.urls.getTermsOfUseDisplayFlag)
      .then((termsDisplayResponse) => {
        setTermsAndConditionsFlags({
          display: termsDisplayResponse?.data?.data?.[0]?.displayTermsOfUseAcceptance ?? true,
          methodOfEnteringApp: MethodOfEnteringApp.FORCE_ACCEPT_TERMS
        });
      })
      .catch((_err) => {
        setTermsAndConditionsFlags({
          display: true,
          methodOfEnteringApp: MethodOfEnteringApp.FORCE_ACCEPT_TERMS
        });
      });
  }

  return termsAndConditionsFlags.display === true &&
    termsAndConditionsFlags.methodOfEnteringApp === MethodOfEnteringApp.FORCE_ACCEPT_TERMS ? (
    <Redirect push to={`/${i18n.language}${pathNames.termsAndConditions}`} />
  ) : (
    <>
      <AuthenticationContext.Provider
        value={{
          isAuthenticated,
          displayTermsOfUseFlag: termsAndConditionsFlags.display,
          signInPopup,
          signInWithRedirect,
          signOut,
          getUsername,
          getToken,
          getWithAuth,
          signInSilently,
          doesCurrentRouteRequireAuthentication,
          isTermsAndConditionsAccepted,
          acceptTermsAndConditions
        }}
      >
        {children}
      </AuthenticationContext.Provider>
    </>
  );
};

export const useAuthenticationContext = () => React.useContext(AuthenticationContext);
