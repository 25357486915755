export enum ComponentType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Currency = 'currency'
}

export enum LanguageTypeCode {
  English = 1,
  French = 2
}

export enum LanguageSwitchTypeCode {
  English = 'en',
  French = 'fr'
}

export enum FormInputRadioType {
  Yes = 'true',
  No = 'false'
}

export enum BorrowerTypeCode {
  Borrower = 1,
  CoBorrower = 2,
  Guarantor = 3
}

export enum OwnerType {
  BorrowerCoborrower = 1,
  Guarantor = 2
}

export enum ProvinceCode {
  Alberta = '48',
  BritishColumbia = '59',
  Manitoba = '46',
  NewBrunswick = '13',
  NewfoundlandAndLabrador = '10',
  NorthwestTerritories = '61',
  NovaScotia = '12',
  Nunavut = '62',
  Ontario = '35',
  PrinceEdwardIsland = '11',
  Quebec = '24',
  Saskatchewan = '47',
  Yukon = '60'
}

export enum SubmitDefaultReturn {
  InvalidLoanNumber,
  InvalidLoanStatus,
  Success,
  Created,
  BadRequest,
  NotAuthorized,
  DuplicateLoanNumber,
  Error
}

export enum SchemaToUse {
  SUBMITTED = 'submitted',
  PROPOSED = 'proposed'
}

export enum TargetSection {
  DEFAULTSUBMISSION = 'defaultSubmission',
  WORKOUTPLAN = 'workoutPlan'
}

export enum ValidationErrorMessageType {
  MaxString,
  LoanNumberOverride
}

export enum SubmittedDefaultCheckboxStage {
  NoCheckboxStage = 0,
  CheckboxStageOne = 1,
  CheckboxStageTwo = 2,
  CheckboxStageThree = 3,
  CheckboxStageFour = 4
}

export enum DefaultSubmissionResponce {
  Success,
  Created,
  BadRequest,
  NotAuthorized,
  DuplicateLoannumber,
  Error
}
