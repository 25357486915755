import i18n from 'i18next';
import React from 'react';

import { ClaimsInventoryTabEnum } from 'Feature/Claims/components/ClaimsInventory/types';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';

import { TableHeaderProps } from './types';
import styles from './styles.module.scss';

const InternalTableHeader = ({
  fields,
  currentTab,
  hideRowActionMenu,
  onSort,
  getSortDirectionForField
}: TableHeaderProps) => {
  const claimTypeCodeLanguage =
    i18n.language === 'en' ? 'ClaimTypeCodeEnglish' : 'ClaimTypeCodeFrench';

  return (
    <>
      {!hideRowActionMenu && (
        <DataTableHeaderItem
          name="rowAction"
          customStyles={styles.hideText}
          displayText={fields.rowActionLabel}
        />
      )}
      <DataTableHeaderItem name="cmhcLoan" displayText={fields.loanLabel} colspan={2} />
      <DataTableHeaderItem
        sortable
        sortDirection={getSortDirectionForField('claimTypeIndicator')}
        onSortCallback={onSort}
        name="claimTypeIndicator"
        displayText={fields.mainSupp}
      />
      {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
        <DataTableHeaderItem
          sortable
          sortDirection={getSortDirectionForField('claimReceivedDate')}
          onSortCallback={onSort}
          name="claimReceivedDate"
          displayText={fields.receivedLabel}
        />
      )}
      {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
        <>
          <DataTableHeaderItem name="age" displayText={fields.ageLabel} />
          <DataTableHeaderItem name="language" displayText={fields.languageLabel} />
        </>
      )}
      <DataTableHeaderItem
        name={claimTypeCodeLanguage}
        displayText={fields.typeOfClaimLabel}
        sortable
        sortDirection={getSortDirectionForField(claimTypeCodeLanguage)}
        onSortCallback={onSort}
      />
      {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
        <>
          <DataTableHeaderItem name="claimStatus" displayText={fields.claimStatusLabel} />
          <DataTableHeaderItem name="officerName" displayText={fields.claimsOfficerNameLabel} />
          <DataTableHeaderItem name="pendingCode" displayText={fields.pendingCodeLabel} />
          <DataTableHeaderItem name="province" displayText={fields.provinceLabel} />
        </>
      )}
      <DataTableHeaderItem
        name={i18n.language === 'en' ? 'ApprovedLenderEnglishName' : 'ApprovedLenderFrenchName'}
        displayText={fields.lenderNameLabel}
        sortable
        sortDirection={getSortDirectionForField('lenderName')}
        onSortCallback={onSort}
      />
      <DataTableHeaderItem name="lenderReference" displayText={fields.lenderReferenceLabel} />
      {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
        <DataTableHeaderItem
          name="ClaimPaidDate"
          displayText={fields.datePaidLabel}
          sortable
          sortDirection={getSortDirectionForField('ClaimPaidDate')}
          onSortCallback={onSort}
        />
      )}
      {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
        <DataTableHeaderItem name="lastUpdated" displayText={fields.lastUpdatedLabel} />
      )}
      {currentTab.name === ClaimsInventoryTabEnum.Draft && (
        <DataTableHeaderItem name="recordUpdatedTimestamp" displayText={fields.lastSaved} />
      )}
    </>
  );
};

export default InternalTableHeader;
