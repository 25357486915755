import React, { useState, useMemo, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useFeature } from 'flagged';
import i18n from 'i18next';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ModuleMapping, UserRole, Module } from '@hobt/constants';

import { useAuthenticationContext } from 'Foundation/Authentication';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { SideMenuButton } from 'Feature/Header/components/SideMenu/SideMenuButton';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';

import SideMenuList from './SideMenuList';
import SideMenuLink from './SideMenuLink';
import { SideMenuProps, SideMenuItem } from './SideMenu.types';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { DefaultApiCallHandler } from 'Feature/DefaultsInventory/components/api/ApiCallHandlers';
import { config } from '../../../../config';
import { masterEditStatusCall as claimMasterEditStatusCall } from 'Feature/Claims/components/ClaimsSubmissionForm/ClaimsSubmissionFormService';

const SECTION_ID = 'sideMenu';
const SideMenu: React.FC<SideMenuProps> = ({ text, sideMenuItems, logoutLabel }) => {
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const loggedInUser = sitecoreContext?.user?.userID;

  const user = useMemo(() => sitecoreContext?.user, []);
  const isInternal = useFeature(FeatureFlags.INTERNAL);

  const [draftDefaultID, setDraftDefaultID] = useState<string>('');
  const [editingStatus, setEditingStatus] = useState({
    isBeingEdited: false,
    isBeingEditedByUserID: ''
  });
  const [draftClaimID, setDraftClaimID] = useState<string>('');
  const { masterEditStatusCall: defaultMasterEditStatusCall } = DefaultApiCallHandler(
    draftDefaultID,
    authenticationContext,
    {
      timeout: config.defaultApi.requestTimeout
    }
  );

  const userModuleAccess = useMemo(
    () =>
      user != null && user.moduleRoleMapping != null
        ? Object.keys(user.moduleRoleMapping).map((itm) => parseInt(itm, 10))
        : undefined,
    []
  );

  const isMasterUser = useMemo(() => {
    const roles =
      user != null && user.moduleRoleMapping != null
        ? Object.entries(user.moduleRoleMapping)
            .map((el) => el[1] as number[])
            .reduce((acc, row) => acc.concat(row))
        : [];
    const uniqueRoles = Array.from(new Set(roles));

    return uniqueRoles.filter((r) => r === UserRole.MasterUser).length > 0;
  }, []);

  const [isActive, setIsActive] = useState(false);

  const moduleMap: { [key: string]: number } = {
    arrears: ModuleMapping.arrears,
    claims: ModuleMapping.claim,
    defaults: ModuleMapping.default,
    user: ModuleMapping.admin
  };

  const globalMenuAccesses: { [key: string]: boolean } = {
    dashboard: true,
    help: true,
    financialAuthorityApprovals:
      (isInternal as boolean) &&
      userModuleAccess !== undefined &&
      (userModuleAccess.includes(Module.Claim) ||
        userModuleAccess.includes(Module.Arrears) ||
        userModuleAccess.includes(Module.Default))
  };

  const sideMenuClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  const filterParentMenuItems = ({ fields }: SideMenuItem): boolean => {
    const itemName = fields?.name?.value;

    if (
      userModuleAccess !== undefined &&
      itemName != null &&
      userModuleAccess?.findIndex((access) => access === moduleMap[itemName]) >= 0
    ) {
      // check user lenderAdministrator or cmhcAdministrator
      if (
        user !== null &&
        moduleMap[itemName] === Module.Admin &&
        isUserInRoles(Module.Admin, [UserRole.CmhcAdministrator], user.moduleRoleMapping) ===
          false &&
        isUserInRoles(Module.Admin, [UserRole.LenderAdministrator], user.moduleRoleMapping) ===
          false
      ) {
        return false;
      }
      return true;
    }

    const globalAccesses = itemName != null ? globalMenuAccesses[itemName] : undefined;

    return globalAccesses !== undefined ? globalAccesses : false;
  };

  const preProcessSubNav = (menuItem: SideMenuItem): SideMenuItem => {
    const updatedMenuItem = { ...menuItem };

    if (
      menuItem.fields.name?.value === 'financialAuthorityApprovals' &&
      (!isMasterUser || !isInternal)
    ) {
      updatedMenuItem.fields.subMenu = updatedMenuItem.fields.subMenu?.filter(
        (item) => item.fields.name?.value !== 'delegateInventory'
      );
    }
    return updatedMenuItem;
  };

  // Logout event listener for defaults draft unlock
  React.useEffect(() => {
    const handleEvent = (e: any) => {
      setDraftDefaultID(e.detail.draftDefaultID);
      setEditingStatus(e.detail.editingStatus);
    };
    window.addEventListener('defaultEvent', handleEvent);
    return () => {
      window.removeEventListener('defaultEvent', handleEvent);
    };
  }, []);

  // Logout event listener for claims draft unlock
  React.useEffect(() => {
    const handleEvent = (e: any) => {
      setDraftClaimID(e.detail.draftClaimID);
      setEditingStatus(e.detail.editingStatus);
    };
    window.addEventListener('claimEvent', handleEvent);
    return () => {
      window.removeEventListener('claimEvent', handleEvent);
    };
  }, []);

  const logOut = async () => {
    if (draftDefaultID && loggedInUser === editingStatus?.isBeingEditedByUserID)
      await defaultMasterEditStatusCall(draftDefaultID, { isBeingEdited: false });

    if (draftClaimID && loggedInUser === editingStatus?.isBeingEditedByUserID)
      await claimMasterEditStatusCall(authenticationContext, draftClaimID, {
        isBeingEdited: false
      });

    authenticationContext.signOut();
  };

  return (
    <React.Fragment>
      <SideMenuButton onClick={sideMenuClickHandler} text={text} />
      <SideDrawer
        isActive={isActive}
        handleOutsideClick={() => {
          setIsActive(false);
        }}
      >
        <Scrollbars autoHide>
          <div className={styles.headerWrapper}>
            <Text tag="h2" field={text} />
            <div className={styles.closeButton}>
              <button
                type="button"
                data-testid={`${SECTION_ID}CloseButton`}
                aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
                onClick={sideMenuClickHandler}
              >
                <span className="material-icons align-self-center">close</span>
              </button>
            </div>
          </div>

          {sideMenuItems && (
            <div className={styles.sideMenuListWrapper}>
              <ul className="side-menu">
                {sideMenuItems
                  ?.filter(filterParentMenuItems)
                  .map(preProcessSubNav)
                  .map((menuItem: SideMenuItem, menuItemIndex: number) => (
                    <li
                      className="side-menu__item"
                      key={`${menuItem.fields.text.value}-item-${menuItemIndex}`}
                    >
                      <SideMenuLink
                        item={menuItem}
                        index={menuItemIndex}
                        onLinkClick={() => {
                          setIsActive(false);
                        }}
                        iterated={false}
                        className={'item-link'}
                      />
                      {menuItem.fields.subMenu && menuItem.fields.subMenu?.length > 0 && (
                        <SideMenuList
                          item={menuItem}
                          index={menuItemIndex}
                          iterated={false}
                          onLinkClick={() => {
                            setIsActive(false);
                          }}
                          className={'subitems-container'}
                        />
                      )}
                    </li>
                  ))}
                {authenticationContext.isAuthenticated && (
                  <li className="side-menu__item" key={`logout-item-${sideMenuItems.length}`}>
                    <a
                      className="side-menu__item-link"
                      role="button"
                      tabIndex={0}
                      data-testid="signOutLink"
                      onClick={logOut}
                      onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                        if (e.key === 'Enter') {
                          logOut();
                        }
                      }}
                    >
                      {logoutLabel.value}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
        </Scrollbars>
      </SideDrawer>
    </React.Fragment>
  );
};

export default SideMenu;
