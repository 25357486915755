import * as React from 'react';
import i18n from 'i18next';
import { DefaultRequestStatus } from 'Foundation/Api';
import { LanguageTypeCode } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import { TableRowProps } from './TableRow.types';
import { StatusIndicator } from '../StatusIndicator/StatusIndicator';
import { ActionMenu } from 'Components/Common/ActionMenu';
import { Button, ButtonType } from 'Feature/CommonComponents/UserControls';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Module, ModuleMapping, UserRole } from '@hobt/constants';
import { useFeature } from 'flagged';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { data } from 'jquery';

const getLocalizedDateString = (inputDate: string, includeTime: boolean) => {
  // Incoming date format: 2018-08-27 02:56:45
  const normalizedDate: string = inputDate.replace(' ', 'T');

  let date: Date;

  try {
    date = new Date(normalizedDate);
  } catch (e) {
    return '';
  }

  const dateString = new Intl.DateTimeFormat(`${i18n.language}-CA`, {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).format(date);

  const timeString = new Intl.DateTimeFormat(`${i18n.language}-CA`, {
    hour: 'numeric',
    minute: 'numeric'
  }).format(date);

  return `${dateString} ${includeTime ? `- ${timeString}` : ''}`;
};

const getLocalizedProvinceString = (province: number) => {
  return province ? i18n.t(`Globals-Provinces-${province.toString()}`) : '';
};

const getLocalizedLanguageString = (languageIdentifier: number) => {
  if (
    languageIdentifier === LanguageTypeCode.English ||
    languageIdentifier === LanguageTypeCode.French
  ) {
    return i18n.t(`Globals-Languages-${languageIdentifier}`);
  }
  return '---';
};

export const TableRow: React.FC<TableRowProps> = (props: TableRowProps) => {
  const isCancelDisabled = props.row.status === DefaultRequestStatus.Cancelled;
  const isInactivateDisabled =
    props.row.status === DefaultRequestStatus.Inactive ||
    props.row.status === DefaultRequestStatus.Cancelled;

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const isInternalSite = useFeature(FeatureFlags.INTERNAL);

  const isMasterUser: boolean = isUserInRoles(
    Module.Default,
    [UserRole.MasterUser],
    sitecoreContext?.user?.moduleRoleMapping
  );

  const isClerkUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.Clerk],
    sitecoreContext?.user?.moduleRoleMapping
  );

  const isDraftTab = props.row.status === DefaultRequestStatus.InDraft;

  const currentUserID = sitecoreContext?.user?.userID;

  const isClerkUserAndDraftSubmitter =
    isClerkUser && currentUserID === props.row.requestCreatedUserID;

  return (
    <tr
      className={`table__row ${props.row.isSelected ? 'selected' : ''} ${
        props.onClickHandler ? 'hover-row' : ''
      }`}
      onClick={props.onClickHandler}
      onKeyDown={props.onRowKeyDown}
      data-row-id={props.row.uuid}
      tabIndex={0}
      id={`rowFor-cmhcLoanNumber-${props.row.uuid}`}
    >
      <td
        className="table__data"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) => e.stopPropagation()}
        style={{ cursor: 'default' }}
      >
        {isInternalSite && (
          <ActionMenu name={'TestMenu'}>
            <ul>
              {props.row.status !== DefaultRequestStatus.InDraft && (
                <>
                  <li>
                    <Button
                      id="submissionButtonCancel"
                      name="submission.cancel"
                      buttonType={ButtonType.CONTEXTUAL}
                      displayText={i18n.t('DefaultsInventoryTable-ButtonText-CancelRequest')}
                      icon="clear"
                      onClick={() => props.onCancelClick && props.onCancelClick(props.row.uuid)}
                      disabled={isCancelDisabled}
                    />
                  </li>
                  <li>
                    <Button
                      id="submissionButtonActivate"
                      name="submission.inactivate"
                      buttonType={ButtonType.CONTEXTUAL}
                      displayText={i18n.t('DefaultsInventoryTable-ButtonText-InactivateRequest')}
                      icon="block"
                      onClick={() => props.onInactiveClick && props.onInactiveClick(props.row.uuid)}
                      disabled={isInactivateDisabled}
                    />
                  </li>
                </>
              )}

              {props.row.status == DefaultRequestStatus.InDraft && (
                <li>
                  <Button
                    id="draftDeletebtn"
                    name="submission.delete"
                    buttonType={ButtonType.CONTEXTUAL}
                    displayText={i18n.t('DefaultSubmission-Card-Delete')}
                    icon="delete"
                    onClick={() => props.onDeleteClick && props.onDeleteClick(props.row.uuid)}
                    disabled={isMasterUser ? false : !isClerkUserAndDraftSubmitter}
                  />
                </li>
              )}
            </ul>
          </ActionMenu>
        )}

        {!isInternalSite && isDraftTab && (
          <ActionMenu name={'delete menu'}>
            <ul>
              <li>
                <Button
                  id="draftDeletebtn"
                  name="submission.delete"
                  buttonType={ButtonType.CONTEXTUAL}
                  displayText={i18n.t('DefaultSubmission-Card-Delete')}
                  icon="delete"
                  onClick={() => props.onDeleteClick && props.onDeleteClick(props.row.uuid)}
                />
              </li>
            </ul>
          </ActionMenu>
        )}
      </td>

      {props.row.cols.map((item, idx: number) => {
        // Render empty cell
        if (item === undefined || item === null) {
          return (
            <td
              className="table__data"
              key={`row-${props.row.uuid}-col-empty${props.row.uuid}-${idx}`}
            />
          );
        }

        // Status Type
        if ('status' in item) {
          return (
            <td className="table__data" key={`row-${props.row.uuid}-col-status-${idx}`}>
              <StatusIndicator type={item.status} />
              {i18n.t(`Globals-ApplicationStatus-${item.textValue}`)}
            </td>
          );
        }

        // Text with Second Line
        if ('secondLineText' in item) {
          return (
            <td className="table__data" key={`row-${props.row.uuid}-col-secondLineText-${idx}`}>
              <p>{item.textValue}</p>
              <p>{item.secondLineText}</p>
            </td>
          );
        }

        // Text with Date
        if ('dateText' in item) {
          if ('textValue' in item) {
            return (
              <td className="table__data" key={`row-${props.row.uuid}-col-secondLineText-${idx}`}>
                <p>{item.textValue}</p>
                <p>{getLocalizedDateString(item.dateText, item.includeTime)}</p>
              </td>
            );
          }
          return (
            <td className="table__data" key={`row-${props.row.uuid}-col-text-${idx}`}>
              {getLocalizedDateString(item.dateText, item.includeTime)}
            </td>
          );
        }

        // Province Code
        if ('provinceCode' in item) {
          return (
            <td className="table__data" key={`row-${props.row.uuid}-col-text-${idx}`}>
              {getLocalizedProvinceString(item.provinceCode)}
            </td>
          );
        }

        // Language Identifier
        if ('languageIdentifier' in item) {
          return (
            <td className="table__data" key={`row-${props.row.uuid}-col-text-${idx}`}>
              {getLocalizedLanguageString(item.languageIdentifier)}
            </td>
          );
        }

        // Icon Field
        if ('showAttachmentIcon' in item) {
          return (
            <td className="table__data" key={`row-${props.row.uuid}-col-attachments-${idx}`}>
              <i className="material-icons icon--size-16" aria-hidden="true">
                {item.showNoteIcon && 'text_snippet'}
              </i>
              <i className="material-icons icon--size-16" aria-hidden="true">
                {item.showAttachmentIcon && `attach_file`}
              </i>
            </td>
          );
        }

        // else just simple text field
        return (
          <td className="table__data" key={`row-${props.row.uuid}-col-text-${idx}`}>
            {'textValue' in item && (item.textValue || '')}
          </td>
        );
      })}
    </tr>
  );
};
