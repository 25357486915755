import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { config } from '../../../../config';
import { ClaimsSubmitResponse, ErrorObject } from './types';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { AxiosResponse } from '@sitecore-jss/sitecore-jss';

const claimSubmissionUrl: string = config.claimApi.urls.submit;
const draftClaimUrl: string = config.claimApi.urls.draftClaim;
const apiClientConfig: ApiClientConfig = { timeout: config.defaultApi.requestTimeout };
const masterEditUrl: string = config.claimApi.urls.editingStatus;

export const errorCatch = (err: ErrorObject): number => {
  if (err && err.response && err.response.status) {
    switch (err.response.status) {
      case 400:
        return ClaimsSubmitResponse.BadRequest;
      case 401:
        return ClaimsSubmitResponse.NotAuthorized;
      case 500:
      default:
        return ClaimsSubmitResponse.Error;
    }
  } else {
    // err.response.status is undefined
    return ClaimsSubmitResponse.Error;
  }
};

// TODO: Update the schema if the fields needs to be updated
// TODO : Update the types of data if the all fields are coming through
export const submitClaimsRequest = async (
  authenticationContext: any,
  data: any
): Promise<number> => {
  try {
    await ApiClient(authenticationContext, apiClientConfig).postWithAuth(claimSubmissionUrl, data);
    return ClaimsSubmitResponse.Created;
  } catch (err) {
    return errorCatch(err as ErrorObject);
  }
};

export const saveClaimsDraft = async (authenticationContext: any, data: any): Promise<any> => {
  try {
    await ApiClient(authenticationContext, apiClientConfig).postWithAuth(draftClaimUrl, data);
    return ClaimsSubmitResponse.Success;
  } catch (err) {
    return errorCatch(err as ErrorObject);
  }
};

export const masterEditStatusCall = async (authenticationContext: any, params: any, body: any) => {
  try {
    if (!params) return;
    const editingStatusUrl = `${masterEditUrl}/${params}`;
    const editStatus = await ApiClient(authenticationContext, apiClientConfig).putWithAuth(
      editingStatusUrl,
      body
    );
    return editStatus as AxiosResponse;
  } catch (err) {
    return errorCatch(err as ErrorObject);
  }
};
