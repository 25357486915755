import { HbtIcon } from 'Components/Common/HbtIcon';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import React from 'react';
import i18n from 'i18next';
import { CorrespondenceRowProps } from 'Feature/Claims/components/Details/AttachmentsNotesCommentsDetails/CorrespondenceRow/types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import {
  convertTimestampToLocalDate,
  convertTimestampToLocalMinandHrs
} from 'Feature/Header/models/types';
import { DocumentAttachment } from 'Feature/Claims/components/Details/AttachmentsNotesCommentsDetails/DocumentAttachment';

import styles from './styles.module.scss';

export const CorrespondenceRow: React.FC<CorrespondenceRowProps> = ({
  correspondenceId,
  correspondenceTypeString,
  description,
  attachments,
  onDocumentDownload,
  documentTypeLabel,
  dateString,
  tags,
  categoryLabelText,
  claimId,
  senderName,
  claimStatusOverrideTypeCode,
  isInternal,
  deleteCorrespondenceButtonLabel,
  deleteCorrespondenceCallback,
  documentRequestCategoryCodes
}: CorrespondenceRowProps) => {
  const { isMasterUser } = useHBTFormContext();
  const { claimData } = useHBTFormContext();
  const { uuid } = useHBTFormContext();
  const Meta: React.FC = () => {
    return (
      <div className={styles.meta}>
        <span>{senderName !== '' ? senderName : defaultEmptyString}</span>

        <time dateTime={dateString}>
          {dateString != null
            ? `${convertTimestampToLocalDate(dateString)} ${convertTimestampToLocalMinandHrs(
                dateString
              )}`
            : defaultEmptyString}
        </time>

        <ul>
          {tags?.map((tag, idx) => (
            <li key={`${tag}-${idx}`}>{tag}</li>
          ))}
        </ul>
      </div>
    );
  };

  const Content: React.FC = () => (
    <div className={styles.content}>
      <Text tag="h3" field={{ value: correspondenceTypeString }} />

      {description?.split(',')[0] === 'ClaimsDetails-ClaimApproved-AutomatedNote' ? (
        <Text
          tag="p"
          field={{
            value: i18n
              .t(description?.split(',')[0])
              .replace('{amountPaid}', description?.split(',')[1])
          }}
        />
      ) : (
        <Text tag="p" field={{ value: description }} />
      )}

      {isInternal && (
        <>
          <Text tag="p" field={categoryLabelText} />
          <Text tag="p" field={{ value: claimStatusOverrideTypeCode }} />
        </>
      )}
      <Text tag="p" field={{ value: documentRequestCategoryCodes ?? '' }} />

      {attachments != null && (
        <ul>
          {attachments?.map((attachment, idx) => (
            <li key={`document-${attachment.documentID}-${idx}`}>
              <DocumentAttachment
                onDownloadClick={onDocumentDownload}
                entityId={claimId}
                correspondenceId={correspondenceId}
                attachment={attachment}
                documentTypeLabel={documentTypeLabel}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  const Delete: React.FC = () => {
    return isMasterUser ? (
      <div className={styles.delete}>
        <button
          type="button"
          aria-label={deleteCorrespondenceButtonLabel.value}
          onClick={() =>
            deleteCorrespondenceCallback(
              correspondenceId,
              claimData.cmhcLoanAccountNumber,
              dateString,
              uuid
            )
          }
        >
          <HbtIcon type="icon_delete" key={`${correspondenceId}IconButton`} />
        </button>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className={styles.row}>
      <Meta />
      <Content />
      <Delete />
    </div>
  );
};
