import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Card } from '../Card';
import { CardBody } from '../Card/CardBody';
import { CardFooter } from '../Card/CardFooter';
import { CardHeader } from '../Card/CardHeader';
import { Button } from '../Common/Button';
import { ProgressSteps } from './ProgressSteps';
import styles from './styles.module.scss';
import { ProgressBarProps, ProgressStepsProps } from './types';

const ProgressBar: React.FC<ProgressBarProps> = ({
  bodyClassName,
  cards,
  title,
  isDisabled = false,
  isSecondaryBtnDisabled = false,
  isTertiaryBtnDisabled = false,
  progressItemOnClickCallback,
  primaryButtonAriaLabel,
  primaryButtonText,
  primaryButtonOnClickCallback,
  /* TODO: Albert Review this prop in future sprint
    This prop is only used for the Claim Submission Details Tab in the Claims Adjudication Page
  */
  isSecondaryButton = true,
  isTertiaryButton = true,
  secondaryButtonText,
  tertiaryButtonText,
  isMasterUser,
  isDraftClaim,
  secondaryButtonAriaLabel,
  tertiaryButtonAriaLabel,
  secondaryButtonOnClickCallback,
  tertiaryButtonOnClickCallback,
  scrollDuration,
  cardNameToValidationFieldMap,
  footerButtonsRequired = true,
  isSubmitButtonDisabled
}: ProgressBarProps) => {
  const [showSecondaryButton, setShowSecondaryButton] = React.useState(false);
  const handleTertiaryButtonClick = () => {
    if (tertiaryButtonOnClickCallback) {
      tertiaryButtonOnClickCallback();
    }
    setShowSecondaryButton(true);
  };

  const handleSecondaryButtonClick = () => {
    if (secondaryButtonOnClickCallback) {
      secondaryButtonOnClickCallback();
    }
    setShowSecondaryButton(false);
  };
  return (
    <Card expandToMatchViewportHeight>
      <div className="d-none d-xl-block">
        <CardHeader separator>
          <Text tag="h5" field={title} />
        </CardHeader>
      </div>
      <CardBody className={bodyClassName}>
        <ProgressSteps
          cards={cards}
          onClickCallback={progressItemOnClickCallback}
          scrollDuration={scrollDuration}
          cardNameToValidationFieldMap={cardNameToValidationFieldMap}
        />
      </CardBody>

      {(primaryButtonText || secondaryButtonText) && footerButtonsRequired && (
        <CardFooter separator className={styles.progressBarSeparator}>
          <div className={`${styles.progressBarFooter}`}>
            <div className={`${styles.gridList}`}>
              {primaryButtonText && (
                <div className={styles.gridListItem}>
                  <Button
                    responsive
                    disabled={isSubmitButtonDisabled}
                    name="progressBarPrimaryButton"
                    text={primaryButtonText}
                    ariaText={primaryButtonAriaLabel || primaryButtonText}
                    onClick={primaryButtonOnClickCallback}
                    type="submit"
                    data-testid="ProgressBarPrimaryButton"
                  />
                </div>
              )}

              {/* save draft btn */}
              {(!isDraftClaim || (isDraftClaim && !isMasterUser)) && (
                <>
                  {secondaryButtonText && (
                    <div className={styles.gridListItem}>
                      <Button
                        secondaryButton={isSecondaryButton}
                        responsive
                        disabled={isSubmitButtonDisabled}
                        name="progressBarSecondaryButton"
                        text={secondaryButtonText}
                        onClick={secondaryButtonOnClickCallback}
                        ariaText={secondaryButtonAriaLabel || secondaryButtonText}
                        type="button"
                        data-testid="ProgressBarSecondaryButton"
                      />
                    </div>
                  )}
                </>
              )}

              {/* edit btn */}
              {isDraftClaim && isMasterUser && (
                <>
                  {!showSecondaryButton && tertiaryButtonText && (
                    <div className={styles.gridListItem}>
                      <Button
                        className={styles.tertiaryButton}
                        tertiaryButton={isTertiaryButton}
                        responsive
                        disabled={false}
                        name="progressBarTertiaryButton"
                        text={tertiaryButtonText}
                        ariaText={tertiaryButtonAriaLabel || tertiaryButtonText}
                        onClick={handleTertiaryButtonClick}
                        type="button"
                        data-testid="ProgressBarSecondaryButton"
                      />
                    </div>
                  )}
                  {showSecondaryButton && secondaryButtonText && (
                    <div className={styles.gridListItem}>
                      <Button
                        secondaryButton={isSecondaryButton}
                        responsive
                        disabled={isSecondaryBtnDisabled}
                        name="progressBarSecondaryButton"
                        text={secondaryButtonText}
                        ariaText={secondaryButtonAriaLabel || secondaryButtonText}
                        onClick={handleSecondaryButtonClick}
                        type="button"
                        data-testid="ProgressBarSecondaryButton"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </CardFooter>
      )}
    </Card>
  );
};

export { ProgressBar };
export type { ProgressBarProps, ProgressStepsProps };
