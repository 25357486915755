import React, { FC, useEffect } from 'react';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import getPathInObject from 'Constants/Utils/GetPathInObject';
import { isNumber } from 'Constants/Utils/isNumber';
import { useFormContext, useController } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import styles from './styles.module.scss';
import { Props, LabelledByProps, TUseControllerProps } from './types';

function numberUpdate(name: string, setValue: Function, onChange: Function) {
  return function _numberUpdate({ floatValue }: NumberFormatValues) {
    setValue(name, floatValue, { shouldDirty: true });
    onChange(floatValue);
  };
}

function phoneUpdate(name: string, setValue: Function, onChange: Function) {
  return function _phoneUpdate({ value }: NumberFormatValues) {
    setValue(name, value || null, { shouldDirty: true });
    onChange(value || null);
  };
}

const FormNumber: FC<Props> = ({
  name,
  label,
  className,
  formatProps,
  watch,
  allowNegative,
  maxLength,
  handleChange,
  isReadOnly,
  tabIndex,
  allowedValues,
  onBlur,
  onBlurValidationRequire
}) => {
  const {
    formState: { errors },
    setValue,
    getValues,
    trigger
  } = useFormContext();
  const message = getPathInObject(errors, `${name}.message`);

  const nameValue: any = getValues(name);
  let initialDefaultValue: string | number | undefined;
  if (nameValue != null) {
    initialDefaultValue = isNumber(nameValue) ? Number(nameValue) : nameValue;
  }

  const {
    field: { onChange, ref, ...field }
  }: TUseControllerProps = useController({
    name,
    defaultValue: initialDefaultValue
  });
  // TODO: Fetch update functions in same spot where configs sit
  const update = (formatProps?.type === 'tel' ? phoneUpdate : numberUpdate)(
    name,
    setValue,
    onChange
  );
  const customOnBlur = (e: any) => {
    if (onBlurValidationRequire) {
      trigger(name);
    } else {
      onBlur && onBlur(e);
    }
  };
  return (
    <FormInputWrapper name={name} label={label} className={className} watch={watch}>
      <NumberFormat
        getInputRef={(el: HTMLInputElement) => {
          ref.current = el;
        }}
        {...field}
        onValueChange={update}
        isNumericString={true}
        maxLength={maxLength ?? 20}
        isAllowed={allowedValues != null ? allowedValues : undefined}
        className={styles['number-format']}
        allowEmptyFormatting
        allowNegative={allowNegative}
        {...formatProps}
        aria-label={name}
        aria-errormessage={`${name}_error_message`}
        aria-invalid={Boolean(message)}
        // using read-only attribute since disabled attribute is not accessible(not tabbable and won't read in NVDA)
        {...(isReadOnly && { 'aria-disabled': true, readOnly: true })}
        onChange={handleChange}
        onBlur={customOnBlur}
        // @ts-ignore
        watch={watch}
        {...(tabIndex != null && { tabIndex })}
      />
    </FormInputWrapper>
  );
};

const LabelledBy: FC<LabelledByProps> = ({
  name,
  label,
  className,
  watch,
  formatProps,
  maxLength,
  defaultValue,
  isReadOnly,
  tabIndex
}) => {
  const {
    formState: { errors },
    setValue
  } = useFormContext();
  const message = getPathInObject(errors, `${name}.message`);
  const {
    field: { onChange, ref, ...field }
  }: TUseControllerProps = useController({ name, defaultValue });
  const update = (formatProps?.type === 'tel' ? phoneUpdate : numberUpdate)(
    name,
    setValue,
    onChange
  );

  // remove focus that gets set on load where this is being used (e.g. refund management)
  useEffect(() => {
    if (ref.current != null) {
      ref.current.blur();
    }
  }, [ref.current]);

  return (
    <FormInputWrapper name={name} className={className} watch={watch}>
      <NumberFormat
        getInputRef={(el: HTMLInputElement) => {
          ref.current = el;
        }}
        {...field}
        onValueChange={update}
        isNumericString={true}
        maxLength={maxLength ?? 20}
        className={styles['number-format']}
        allowEmptyFormatting
        allowNegative={false}
        {...formatProps}
        aria-errormessage={`${name}_error_message`}
        aria-invalid={Boolean(message)}
        aria-labelledby={label}
        {...(isReadOnly === true && { readOnly: isReadOnly })}
        {...(tabIndex != null && { tabIndex })}
      />
    </FormInputWrapper>
  );
};

export default FormNumber;
export { LabelledBy };
