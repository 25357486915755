import React, { useState, useEffect } from 'react';
import { InternalClaimStatus } from '@hobt/claim-domain';
import { AxiosResponse } from 'axios';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useFeature } from 'flagged';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useForm } from 'react-hook-form';
import { config } from '../../../../../config';
import { useHBTFormActionsContext, useHBTFormContext } from '../../HBTFormContext';
import { SuspenseConditionsData } from './types';

const useSuspenseConditions = () => {
  const { uuid, claimData, updateClaimData, setLoadingStatus } = useHBTFormContext();
  const { disableEditMode } = useHBTFormActionsContext();

  const claimID = uuid;
  const contextData = claimData;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const hookForm: any = useForm({
    resolver: undefined,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {}
  } as Record<string, any>);

  const [showPendingNotification, setShowPendingNotification] = useState<boolean>(false);
  const authContext = useAuthenticationContext();
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);

  useEffect(() => {
    if (siteTypeIsInternal === true) {
      if (contextData?.profile?.claimStatus?.code === InternalClaimStatus.Pending) {
        setShowPendingNotification(true);
      } else {
        setShowPendingNotification(false);
      }
    }
  }, [contextData]);

  const onNotificationDismiss = () => {
    setShowPendingNotification(false);
  };

  const handleErrors = (err: Record<string, Object>) => {
    // TODO: Implement
    // eslint-disable-next-line no-console
    console.log('errs', err);
  };

  const onSuccessCallBack = () => {
    disableEditMode();
    setShowSuccessToast(true);
    setShowErrorToast(false);
    setLoadingStatus?.({
      isLoading: false
    });
  };

  const onErrorCallBack = () => {
    setShowSuccessToast(false);
    setShowErrorToast(true);
    setLoadingStatus?.({
      isLoading: false
    });
  };

  const onChangeSuspenseReason = (_data: SuspenseConditionsData[]) => {};
  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };
  const { putWithAuth } = ApiClient(authContext, apiClientConfig);

  const updateSuspenseConditionRequest = (data: any) => {
    const url = `${config.claimApi.urls.finalClaim}/${claimID}`;

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description'
    });

    putWithAuth(url, { ...data })
      .then((_res: AxiosResponse) => {
        onSuccessCallBack();
        if (updateClaimData) {
          updateClaimData();
        }
      })
      .catch(() => {
        onErrorCallBack();
      });
  };

  // TODO: data is not being passed in correctly on servers (locally seems to work fine). using getValues() for now
  // TODO: type data correctly
  const handleSubmit = (_data: any) => {
    hookForm.unregister('indicator.claimReceivedDate' as any);
    const formValues = hookForm.getValues();
    updateSuspenseConditionRequest(formValues);
  };

  return {
    hookForm,
    handleSubmit,
    handleErrors,
    showErrorToast,
    showSuccessToast,
    setShowSuccessToast,
    setShowErrorToast,
    onChangeSuspenseReason,
    onNotificationDismiss,
    showPendingNotification
  };
};

export default useSuspenseConditions;
