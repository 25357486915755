import { FieldErrors, UseFormReturn } from 'react-hook-form';

export enum ClaimsSubmitResponse {
  InvalidLoannumber,
  InvalidLoanStatus,
  Success,
  Created,
  BadRequest,
  NotAuthorized,
  DuplicateLoannumber,
  Error
}

export type ErrorObject = {
  response?: Response;
};

export type Response = {
  status?: number;
  data: DataObject;
};

export type DataObject = {
  statusCode: number;
  status?: string;
  data: any | any[];
  error: ErrorEntity;
};

export type ErrorEntity = {
  errorMessage?: string;
  errorCode?: any;
};

export type Borrower = {
  indexNumber?: number;
  birthDate: string;
  claimBorrowerCategoryID: number;
  claimBorrowerTypeID: number;
  defaultReasonCode: number;
  financialAssessmentCompletedFlag: Boolean;
  firstName: string;
  judgmentBankruptcyInformationReceivedDate: string;
  informationSentToNationalRecoveriesCenterDate: string;
  judgmentActionTypeCode: number;
  lastName: string;
  otherDefaultReasonDescription: string;
  otherReasonNotPursuedDescription: string;
  reasonNotPursuedTypeCode: string;
};

export type BorrowerCharge = {
  indexNumber?: number;
  code: string;
  receivedDate: string;
  receivedAmount: number;
  adjustedAmount?: number;
  adjustedDate?: string;
  previouslyApprovedAmount?: number;
  previouslyApprovedDate?: string;
  previouslyApprovedComment?: string;
  acceptableAmount?: number;
  acceptableDate?: string;
  accruedInterestCalculations?: any;
  creditOfReceiptInterestCalculation?: any;
  comment?: string;
};

export type Detail = {
  additionalSecuritiesHeldByBorrowerTypeCode: number;
  britishColumbiaYukonSaleClosingDate: string;
  calendarDaysDeferredCount: number;
  claimFilingExtensionObtainedFlag: Boolean;
  contestedFlag: Boolean;
  dateApprovedLenderNotifiedToSubmitClaim: string;
  legalActionCompletedDate: string;
  legalActionStartedDate: string;
  offerAcceptedDate: string;
  deficiencySaleClosingDate: string;
  deficiencySalePrice: number;
  dueDateOfLastCompleteInstallmentPaid: string;
  evictionFlag: Boolean;
  interestAdjustmentDate: string;
  interestAtDefaultRate: number;
  legalActionTypeCode: number;
  legalSystemTypeCode: number;
  mobileHomeOnLeasedLandFlag: Boolean;
  mortgageTypeCode: number;
  otherLegalActionTypeDescription: string;
  propertyOccupancyStatusCode: number;
  propertySoldByTypeCode: number;
  propertyTransferredByTypeCode: number;
  propertyVacancyDate: string;
  propertyVacatedByTypeCode: number;
  realEstateCommissionPaid: number;
  representativeToObtainKey: Object;
  taxAccountBalanceAmount: number;
  taxAccountBalanceTypeCode: number;
  typeOfClaim: number;
  unpaidPrincipalBalanceAmount: number;
  warrantyTypeCode: number;
};

export type Lender = {
  address: {
    lineOneText: string;
    lineTwoText: string;
    municipalityName: string;
    postalCode: string;
    provinceCode: string;
  };
  approvedLenderCode: string;
  approvedLenderName: string;
  authorizedOfficers: [
    {
      emailID: string;
      firstName: string;
      lastName: string;
      phonenumber: string;
      positionTypeCode: number;
    }
  ];
  claimPayee: {
    name: string;
    transitnumber: string;
    typeCode: number;
  };
  correspondenceLanguageCode: number;
};

export type Listing = {
  appraisalDate: string;
  appraisalValue: number;
  date: string;
  expiryDate: string;
  priceAmount: number;
  comparativeMarketAnalysisDate: string;
  comparativeMarketAnalysisValue: number;
  periodID: number;
  minimumAcceptableSalePriceAmount: number;
  revisedPriceAmount: number;
};

export type PartialPayment = {
  comment: string;
  indexNumber?: number;
  previouslyApprovedAmount?: number;
  previouslyApprovedDate?: string;
  receivedAmount: number;
  receivedDate?: string;
};

export type Property = {
  address: {
    additionalStreetnumber: string;
    municipalityName: string;
    postalCode: string;
    provinceCode: string;
    streetDirectionTypeCode: number;
    streetName: string;
    streetnumber: string;
    streetTypeCode: number;
  };
  bedCount: number;
  unitCount: number;
  buildingTypeCode: number;
};

export type SubmittedClaim = {
  borrower: Object;
  borrowerCharge: Object;
  cashReceipt: Object;
  defaultManagement: Object;
  detail: Object;
  indicator: Object;
  lender: Object;
  listing: Object;
  main: Object;
  operation: Object;
  property: Object;
  validationResult: Object;
};

export type DraftClaimResponseData = {
  approvedLenderFinancialInstitutionCode?: string;
  approvedLenderReferenceNumber?: string;
  approvedLenderTransitNumber?: string;
  attachments?: Object[];
  borrowerCharges?: Object[];
  borrowers?: Object[];
  claimPayees?: [
    {
      approvedLenderEnglishName: string;
      approvedLenderFrenchName: string;
      payeeID: number;
      transitNumber: string;
    }
  ];
  claimTypeIndicator?: number;
  cmchLoanAccountNumber?: number;
  defaultManagement?: Object;
  detail?: Object;
  indicator?: Object;
  lender?: Object;
  lenderName?: {
    approvedLenderEnglishName: string;
    approvedLenderFrenchName: string;
  };
  listings?: Object[];
  netOperatingIncomesRentalIncomes?: Object[];
  operation?: Object;
  partialPaymentReceivedFlag?: boolean;
  partialPayments?: Object[];
  profile?: {
    statusCode: number;
  };
  draftClaimRecordID?: string;
  property?: Object;
  recordCreatedTimestamp: string;
  recordCreatedUserID: string;
  recordUpdatedTimestamp: string;
  recordUpdatedUserID: string;
  rentalIncomeReceivedFlag?: boolean;
  validationResult?: Object;
  editingStatus?: {
    isBeingEdited: boolean;
    isBeingEditedByUserID: string;
  };
};
