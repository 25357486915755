import { HbtFileExtensionType } from '@hobt/constants';
import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';

export interface AttachmentsViewComponentProps {
  fields: AttachmentsViewPropsFields;
}

export interface AddInternalNoteFormPropsFields {
  content: FieldValue;
  formTitle: FieldValue;
  submitBtn: FieldValue;
  cancelBtn: FieldValue;
  limitNumber: FieldValue;
  description: FieldValue;
  otherDescription: FieldValue;
  categoryList: FieldList;
  categoryLabel: FieldValue;
  characterLimit: FieldValue;
  propertyDeficiencyList: FieldList;
  propertyDeficiencyLabel: FieldValue;
  attachmentsView: AttachmentsViewComponentProps;
}

export interface AddInternalNoteFormProps {
  name?: string;
  fields: AddInternalNoteFormPropsFields;
  onCancelCallback?: () => void;
  onSuccessCallback?: () => void;
  onErrorCallback?: () => void;
}

export const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR,
  HbtFileExtensionType.PDF,
  HbtFileExtensionType.JPG,
  HbtFileExtensionType.DOC,
  HbtFileExtensionType.DOCX
];
