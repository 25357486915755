import React, { useRef, useState, useEffect } from 'react';
import { UserRole, Module } from '@hobt/constants';
import { defaultsPortal, draftDefaultsPortal } from '@hobt/form-schema-validators';
import { UserInfo } from '@hobt/user-domain';
import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'Components/Common/Button';
import { ConcatenateAmortizationValuesToString } from 'Components/Common/Helpers';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { StickyFooter } from 'Components/PageComponents/StickyFooter';
import { defaultClaimSubmissionData, pathNames, jsonReplaceToNull } from 'Constants';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import { removeEmptyFields } from 'Constants/Utils/RemoveEmptyFields';
import {
  Modal,
  ToastNotification,
  NotificationCard
} from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates, CardType } from 'Feature/CommonComponents/Enums';
import { ProgressBar, getProgressBarData } from 'Feature/CommonComponents/ProgressBarComponents';
import DefaultFormContextProvider from 'Feature/DefaultsInventory/components/DefaultFormContext';
import DefaultSubmissionFormProps from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useFeature } from 'flagged';
import { useAppInsightsContext } from 'Foundation/Analytics/AppInsightsContext';
import useCustomEvent from 'Foundation/Analytics/useCustomEvent';
import { DefaultRequestStatus } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import stickybits from 'stickybits';
import { config } from '../../../../config';
import ContentLoadingModal from '../../../PageComponents/components/ContentLoadingModal';

// Submission service
import {
  SubmitDefaultReturn,
  TargetSection,
  LanguageTypeCode,
  LanguageSwitchTypeCode
} from '../../models/typeCode.types';
import { ICard } from '../Card.types';
import { Assets, getAssetsDetailsProps } from '../Cards/Assets';
import { AttachmentForm, getAttachmentFormProps } from '../Cards/AttachmentForm';
import { Borrower, getBorrowerDetailsProps } from '../Cards/Borrower';
import {
  DefaultManagementTools,
  getDefaultManagementToolsProps
} from '../Cards/DefaultManagementTools';
import { GdsTdsCalculations, getGdsTdsCalculationsProps } from '../Cards/GdsTdsCalculations';
import { HistoryDetails, getDefaultHistoryProps } from '../Cards/History';
import { LenderAcknowledgment, getLenderAcknowledgmentProps } from '../Cards/LenderAcknowledgment';
import { LenderDetails, getLenderDetailsProps } from '../Cards/LenderDetails';
import { Liabilities, getLiabilitiesDetailsProps } from '../Cards/Liabilities';
import { LoanDetails, getLoanDetailsProps } from '../Cards/LoanDetails';
import { MonitoringStrategy, getMonitoringStrategyProps } from '../Cards/MonitoringStrategy';
import { MortgageObligations, getMortgageDetailsProps } from '../Cards/MortgageObligations';
import {
  OtherFinancialObligations,
  getfinancialObligationsProps
} from '../Cards/OtherFinancialObligations';
import {
  ReasonsDefaultManagement,
  getReasonsDefaultManagementProps
} from '../Cards/ReasonsDefaultManagement';
import FormCardHeader from '../FormCardHeader/index';
import HomeownerLoanCheck from '../HomeownerLoanCheck';
import { getHomeownerLoanCheckProps } from '../HomeownerLoanCheck/HomeownerLoanCheckData';

// Submission service
import GetErrorCount from '../TopErrorMessages';
import {
  saveDefaultDraft,
  submitDefaultRequest,
  submitDraftDefaultRequest
} from './DefaultSubmissionForm.service';
import { useDraftDefaultClient } from './draftDefaultClient';

// Cards
import { StepHeader } from './StepHeader';

// Analytics Service
import styles from './styles.module.scss';

const DefaultSubmissionForm: React.FC<any> = (props: DefaultSubmissionFormProps) => {
  // Redirecting
  const history = useHistory();
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);
  const errorCardId = 'DefaultSubmissionErrorForm';
  const [showDefaultToast, setShowDefaultToast] = useState<boolean>(false);
  const [draftSuccessToast, setDraftSuccessToast] = useState<boolean>(false);
  const [errorToastMsg, setErrorToastMsg] = useState<string | FieldValue>('');
  const [isDuplicateLoanNumberErrorToast, setIsDuplicateLoanNumberErrorToast] =
    useState<boolean>(false);
  const [showLoanWarning, setShowLoanWarning] = useState<boolean>(false);
  const [isIncorrectLoanNumber, setIsIncorrectLoanNumber] = useState<boolean>(false);
  const [isHomeOwnerLoan, setIsHomeOwnerLoan] = useState<boolean>(false);
  const [borrowerTotalMonthlyGrossIncome, setBorrowerTotalMonthlyGrossIncome] = useState<number>(0);
  const [totalMonthlyHouseholdHousingExpenses, setTotalMonthlyHouseholdHousingExpenses] =
    useState<number>(0);
  const [totalMonthlyHouseholdUnsecuredDebt, setTotalMonthlyHouseholdUnsecuredDebt] =
    useState<number>(0);
  const [totalAssetsArray, setTotalAssetsArray] = useState<number[]>([0]);
  const [totalLiabilitiesArray, setTotalLiabilitiesArray] = useState<number[]>([0]);
  const [cumulativeTotalAssets, setCumulativeTotalAssets] = useState<number>(0);
  const [cumulativeTotalLiabilities, setCumulativeTotalLiabilities] = useState<number>(0);
  const [isAttachmentUploading, setIsAttachmentUploading] = useState<boolean>(false);
  const [totalFormErrorCount, setTotalFormErrorCount] = useState(0);
  const [formHasError, setFormHasError] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState<string[]>([]);
  const [bypassIsDirty, setBypassIsDirty] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authenticationContext = useAuthenticationContext();

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [defaultSchema, setDefaultSchema] = useState(defaultsPortal);

  const [{ isLoading, spinnerHeading, spinnerDescription }, setLoadingStatus] = useState<Spinner>({
    isLoading: false
  });
  const [toastMsg, setToastMsg] = useState<string | FieldValue>('');
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [showClaimsToast, setShowClaimToast] = useState<boolean>(false);
  const [isBypass, setBypass] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const { getDraftDefault } = useDraftDefaultClient(authenticationContext, {
    timeout: config.claimApi.requestTimeout
  });
  const [draftDefaultData, setDraftDefaultData] = useState<any>();
  const urlParams = new URLSearchParams(isServer() === false ? window.location.search : '');
  const id = urlParams.get('id');
  const draftDefaultID = id ?? '';

  // App Insights
  const reactPlugin = useAppInsightsContext();
  const trackDefaultSubmission = useCustomEvent(reactPlugin, 'Default Form Submission', {});

  const sitecoreContextFactory = useSitecoreContext();
  const hbtSitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  let externalUserDetails: UserInfo | undefined;
  let lenderContactEmail: string;
  let lenderContactName: string;
  let lenderContactPhoneNumber: string;
  let contactPhoneNumberExtension: string;
  let preferredLanguageCode: number;

  // Get siteCore content
  if (isInternalSite !== undefined && isInternalSite === false) {
    externalUserDetails = hbtSitecoreContext?.user;
  }

  const moduleRoleMapping = hbtSitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);

  const isReadOnlyUser =
    isUserInRoles(Module.Default, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  if (externalUserDetails !== undefined && externalUserDetails.contact !== undefined) {
    lenderContactEmail = externalUserDetails.contact.emailID || '';
    lenderContactName =
      `${`${externalUserDetails.contact.firstName} ${externalUserDetails.contact.lastName}`}` || '';
    lenderContactPhoneNumber =
      (externalUserDetails.contact.phoneNumber && `${externalUserDetails.contact.phoneNumber}`) ||
      '';
    contactPhoneNumberExtension =
      (externalUserDetails.contact.extensionPhoneNumber &&
        `${externalUserDetails.contact.extensionPhoneNumber}`) ||
      '';
    preferredLanguageCode =
      Number(externalUserDetails.contact.preferredLanguageCode) ||
      (i18n.language && i18n.language === LanguageSwitchTypeCode.English
        ? LanguageTypeCode.English
        : LanguageTypeCode.French);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    getValues,
    reset,
    formState,
    clearErrors,
    trigger,
    unregister,
    setError
  } = useForm({
    resolver: joiResolver(defaultSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      lender: {
        approvedLenderName: '',
        approvedLenderReferenceNumber: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneNumberExtension: '',
        financialInstitutionCode: '',
        transitNumber: '',
        approvedLenderCode: '',
        contactEmail: ''
      },
      other: {
        correspondenceLanguage: '',
        defaultStatus: 1,
        validLoan: false,
        homeownerLoan: true
      },
      detail: {
        insuredPropertyStreetNumber: '',
        insuredPropertyUnitNumber: '',
        insuredPropertyStreetName: '',
        insuredPropertyStreetType: '',
        insuredPropertyStreetDirection: '',
        insuredPropertyMunicipalityName: '',
        insuredPropertyProvinceCode: '',
        updatedPropertyTax: null,
        updatedPropertyInsurance: null,
        updatedUtilities: null,
        updatedCondominiumFee: null,
        firstLoan: {
          occupancyType: '',
          rankingPriority: '',
          registeredMortgageHolder: '',
          unpaidBalance: '',
          requiredMonthlyPaymentPrincipalInterest: '',
          interestRate: '',
          nextRenewalDate: '',
          currentArrears: '',
          originalAmortizationPeriodYears: '',
          originalAmortizationPeriodMonths: '',
          currentAmortizationPeriodYears: '',
          currentAmortizationPeriodMonths: '',
          currentAmortizationPeriod: '0000',
          originalAmortizationPeriod: '0000'
        },
        secondLoan: {
          registeredMortgageHolder: '',
          unpaidBalance: '',
          requiredMonthlyPaymentPrincipalInterest: ''
        },
        cmhcLoanAccountNumber: '',
        purchasePriceOfHome: '',
        currentMarketValue: ''
      },
      borrower: [
        {
          borrowerFirstName: '',
          borrowerLastName: '',
          borrowerTypeID: '',
          mailingAddress: {
            streetNumber: '',
            unitNumber: '',
            streetName: '',
            streetType: '',
            streetDirection: '',
            municipalityName: '',
            provinceCode: ''
          },
          employment: {
            occupation: '',
            currentEmployment: '',
            industry: '',
            detailedEmploymentProspects: '',
            numberOfYearsInIndustry: ''
          },
          creditObtained: null,
          submittedMonthlyIncomeSource: {
            grossSalaryEmployment: '',
            employmentInsurance: '',
            investmentIncome: '',
            other: ''
          },
          numberOfDependents: ''
        }
      ],
      asset: [
        {
          owner: '1',
          primaryResidence: '',
          rentalProperty: '',
          vehicle: '',
          cashEquivalents: '',
          bankAccount: '',
          savingAccount: '',
          rrspRespRrif: '',
          other: ''
        }
      ],
      liability: [
        {
          owner: '1',
          personalLoanBalance: '',
          personalLoanMinimumMonthlyPayment: '',
          lineOfCreditBalance: '',
          lineOfCreditMinimumMonthlyPayment: '',
          creditCardBalance: '',
          creditCardMinimumMonthlyPayment: ''
        }
      ],
      financialObligation: [
        {
          owingTo: '',
          natureOfDebt: '',
          currentBalance: '',
          requiredMonthlyPaymentPrincipalInterest: '',
          currentArrears: ''
        }
      ],
      managementInformation: {
        causeOfDefault: '',
        description: '',
        submittedStageOne: {
          conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage: false,
          prepayAndReBorrow: false,
          mortgagePaymentDeferral: false
        },
        submittedStageTwo: {
          extendedMortgagePaymentDeferral: false,
          extensionOfAmortizationPeriod: false,
          specialPaymentArrangement: false,
          interestOnlyPayment: false,
          capitalization: false,
          newAmortizationPeriod: '0000'
        },
        submittedStageThree: {
          combinationFlag: false
        },
        submittedStageFour: {
          saleByBorrower: false,
          relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle: false
        },
        preApprovalRequired: null,
        lenderAcknowledgementAdditionalDetails: null,
        lenderAcknowledgementSignatureFullName: '',
        lenderAcknowledgementSignatureTitle: '',
        monitoringStrategy: '',
        dateHardshipBegan: '',
        submittedDefaultStage: 0
      },
      history: {
        defaultHistory: null,
        description: ''
      },
      grossDebtServiceTotalDebtService: {
        submittedHousingExpense: {
          propertyTax: '',
          heatingCost: '',
          fiftyPercentCondoFee: ''
        },
        submittedOtherMonthlyExpense: {
          personalLoan: '',
          lineOfCredit: '',
          creditCard: '',
          other: ''
        }
      },
      attachment: [
        {
          metadata: {
            description: '',
            documentType: undefined,
            fileName: '',
            fileSize: 0
          }
        }
      ]
    }
  });

  const { isDirty, dirtyFields } = formState;

  const commonCardProps: any = {
    register,
    errors,
    setValueHandler: setValue,
    control,
    watch,
    getValues,
    reset,
    trigger
  };

  const preApprovalValue: any = getValues('managementInformation.preApprovalRequired');
  const isPreApprovalRequired = preApprovalValue === 'true' || preApprovalValue === true;

  const lenderAcknowledgementDetails = getValues(
    'managementInformation.lenderAcknowledgementAdditionalDetails'
  );

  useEffect(() => {
    // Default values for defaults submission:
    register('other.defaultStatus');
    setValue('other.defaultStatus', DefaultRequestStatus.PendingCmhcReview); // On submission, the default status should be "Pending CMHC Review" (1)

    register('other.validLoan');
    setValue('other.validLoan', false); // Default loanValidationPassFail to false

    register('other.homeownerLoan');
    setValue('other.homeownerLoan', true);

    setValue('lender.contactEmail', isInternalSite ? '' : lenderContactEmail);
    setValue('lender.contactName', isInternalSite ? '' : lenderContactName);

    setValue('lender.contactPhoneNumber', isInternalSite ? '' : lenderContactPhoneNumber);
    setValue(
      'lender.contactPhoneNumberExtension',
      isInternalSite ? '' : contactPhoneNumberExtension
    );
    setValue('other.correspondenceLanguage', String(preferredLanguageCode));

    if (isServer() === false) {
      window.onbeforeunload = null;
    }

    if (getValues()?.attachment?.[0]?.metadata?.fileSize === 0) {
      unregister('attachment');
    }

    if (isPreApprovalRequired) {
      setError('managementInformation.lenderAcknowledgementAdditionalDetails', {
        type: 'manual',
        message: 'Required'
      });
      setValue('managementInformation.lenderAcknowledgementAdditionalDetails', null);
    }
  }, [isPreApprovalRequired]);

  // scroll fix for IE:
  useEffect(() => {
    stickybits('#progressBar', {
      stickyClass: 'IEsticky',
      stuckClass: 'IEstuck'
    });
  });

  // isDirty check
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if (
          (Object.keys(dirtyFields).length > 0 || isAttachmentUploading) &&
          bypassIsDirty === false
        ) {
          // clean up localStorage, if leaving the page without submitting
          localStorage.removeItem('submitWithErrors');
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }

        return undefined;
      };
    }
  }, [dirtyFields, bypassIsDirty]);

  const resetModal = (): void => {
    setFormHasError(false);
    setShowLoanWarning(false);
    setIsIncorrectLoanNumber(false);
    setBypassIsDirty(false);
  };
  const getDraftDefaultData = async () => {
    try {
      setIsPageLoading(true);

      const response = await getDraftDefault(draftDefaultID);
      // const data = modifyData(response);

      setDraftDefaultData(response);
    } catch (error) {
      setIsPageLoading(false);
      // @ts-ignore
      throw new Error(`Could not get draft claim data. ${error?.message}`);
    }
  };
  const getDraftValues = () => {
    // TO DO : Need to investigate using the form data instead of the getValues data
    setValue('other.defaultStatus', DefaultRequestStatus.InDraft);
    const values = getValues();
    return values;
  };

  useEffect(() => {
    if (draftDefaultID != null && draftDefaultID !== '') {
      getDraftDefaultData();
    }
  }, [draftDefaultID]);

  useEffect(() => {
    reset();
  }, [draftDefaultData]);

  useEffect(() => {
    isDraft && trigger();
  }, [isDraft]);

  const saveDraft = async () => {
    setIsDraft(true);
    setDefaultSchema(draftDefaultsPortal);
    const isValid = await trigger([
      'lender',
      'detail.cmhcLoanAccountNumber',
      'other.correspondenceLanguage'
    ]); // Manually trigger validation
    if (isValid) {
      setLoadingStatus({
        isLoading: true,
        spinnerHeading: 'Globals-Saving-Heading',
        spinnerDescription: 'Globals-Saving-Description'
      });
      setIsSubmitting(true);
      const result = await submitDraftDefaultRequest(
        authenticationContext,
        getDraftValues(),
        isBypass
      );
      // const response = await saveDefaultDraft(authenticationContext, getDraftValues());
      submitToastHandler(result);
    } else {
      onError(errors);
    }

    setDefaultSchema(defaultsPortal);
    setIsDraft(false);
  };
  const submitToastHandler = (result: any) => {
    switch (result) {
      case SubmitDefaultReturn.Created: // successful submission
        setDraftSuccessToast(true);
        setShowDefaultToast(true);
        setIsSubmitting(false);
        setLoadingStatus({
          isLoading: false
        });
        setBypass(true);
        setToastMsg(i18n.t('DefaultSubmission-Card-Save-Draft-ToastMessage'));
        setToastState(ApplicationStates.SUCCESS);
        // setShowClaimToast(true);
        break;
      case SubmitDefaultReturn.InvalidLoanNumber: // trigger validate modal
        setIsSubmitting(false);
        setIsIncorrectLoanNumber(true);
        setShowLoanWarning(true);
        break;
      case SubmitDefaultReturn.InvalidLoanStatus: // trigger validate modal
        setIsSubmitting(false);
        setShowLoanWarning(true);
        break;
      case SubmitDefaultReturn.BadRequest: // trigger toast
        setIsSubmitting(false);
        setShowDefaultToast(true);
        setErrorToastMsg(i18n.t('DefaultSubmission-BadRequest'));
        setBypassIsDirty(false);
        break;
      case SubmitDefaultReturn.NotAuthorized: // trigger toast
        setIsSubmitting(false);
        setShowDefaultToast(true);
        setErrorToastMsg(i18n.t('DefaultSubmission-NotAuthorized'));
        setBypassIsDirty(false);
        break;
      case SubmitDefaultReturn.DuplicateLoanNumber: // trigger toast
        setIsSubmitting(false);
        setShowDefaultToast(true);

        if (props.fields.duplicateLoanNumberWarning != null) {
          setErrorToastMsg(props.fields.duplicateLoanNumberWarning);
        }

        setBypassIsDirty(false);
        break;
      default:
        // trigger toast on all other errors
        setIsSubmitting(false);
        setShowDefaultToast(true);
        setErrorToastMsg(i18n.t('DefaultSubmission-OtherErrors'));
        setBypassIsDirty(false);
    }
  };
  // TODO : type for data, change from any
  const submitDefault = async (data: any, isBypass: boolean): Promise<void> => {
    setIsSubmitting(true);

    // check if description field is undefined
    const attachmentsWithDescription = data.attachment?.map((attachment: any) => {
      if (attachment.metadata.description === undefined) {
        attachment.metadata.description = '';
      }
      return attachment;
    });
    data.attachment = attachmentsWithDescription;

    const result = await submitDefaultRequest(authenticationContext, data, isBypass);
    switch (result) {
      case SubmitDefaultReturn.Created: // successful submission
        history.push(`/${i18n.language}/default-inventory`);
        break;
      case SubmitDefaultReturn.InvalidLoanNumber: // trigger validate modal
        setIsSubmitting(false);
        setIsIncorrectLoanNumber(true);
        setShowLoanWarning(true);
        break;
      case SubmitDefaultReturn.InvalidLoanStatus: // trigger validate modal
        setIsSubmitting(false);
        setShowLoanWarning(true);
        break;
      case SubmitDefaultReturn.BadRequest: // trigger toast
        setIsSubmitting(false);
        setShowDefaultToast(true);
        setErrorToastMsg(i18n.t('DefaultSubmission-BadRequest'));
        setBypassIsDirty(false);
        break;
      case SubmitDefaultReturn.NotAuthorized: // trigger toast
        setIsSubmitting(false);
        setShowDefaultToast(true);
        setErrorToastMsg(i18n.t('DefaultSubmission-NotAuthorized'));
        setBypassIsDirty(false);
        break;
      case SubmitDefaultReturn.DuplicateLoanNumber: // trigger toast
        setIsSubmitting(false);
        setShowDefaultToast(true);

        if (props.fields.duplicateLoanNumberWarning != null) {
          setErrorToastMsg(props.fields.duplicateLoanNumberWarning);
          setIsDuplicateLoanNumberErrorToast(true);
        }

        setBypassIsDirty(false);
        break;
      default:
        // trigger toast on all other errors
        setIsSubmitting(false);
        setShowDefaultToast(true);
        setErrorToastMsg(i18n.t('DefaultSubmission-OtherErrors'));
        setBypassIsDirty(false);
    }
  };
  const onError = (errs: any) => {
    trackDefaultSubmission({ frontendFormErrors: errs });
    setFormHasError(true);
    setTimeout(() => {
      setTotalFormErrorCount(document.querySelectorAll('[class^=thisFieldHasError]').length);
      setFormErrorMessages(GetErrorCount);
      const errorCardElement = document.getElementById(errorCardId);
      if (errorCardElement != null) {
        errorCardElement.focus();
      }
    }, 0);
    console.log('Form Errors:', errs);
    console.log('Get Values', getValues());
  };

  // TODO: type this data parameter
  const onSubmit = (data: any): void => {
    let cleansedData = data;

    cleansedData.detail.firstLoan.originalAmortizationPeriod =
      ConcatenateAmortizationValuesToString(
        getValues('detail.firstLoan.originalAmortizationPeriodYears'),
        getValues('detail.firstLoan.originalAmortizationPeriodMonths')
      );
    cleansedData.detail.firstLoan.currentAmortizationPeriod = ConcatenateAmortizationValuesToString(
      getValues('detail.firstLoan.currentAmortizationPeriodYears'),
      getValues('detail.firstLoan.currentAmortizationPeriodMonths')
    );
    cleansedData.managementInformation.submittedStageTwo.newAmortizationPeriod =
      ConcatenateAmortizationValuesToString(
        getValues('managementInformation.submittedStageTwo.newAmortizationPeriodYears' as any),
        getValues('managementInformation.submittedStageTwo.newAmortizationPeriodMonths' as any)
      );

    cleansedData = removeEmptyFields(cleansedData);

    localStorage.setItem('submitWithErrors', 'false');
    setBypassIsDirty(true); // prevent isDirty browser warning when submitting

    submitDefault(cleansedData, false);
  };

  const onReset = (): void => {
    clearErrors();
    history.push(`/${i18n.language}/dashboard`);
  };

  const bypassWarningSubmit = (data: any): void => {
    localStorage.setItem('submitWithErrors', 'true');
    resetModal();
    setBypassIsDirty(true); // prevent isDirty browser warning when submitting

    const strippedValues = removeEmptyFields(data);
    submitDefault(strippedValues, true);
  };

  // Creating card referance of use ref and send to progress bar
  const cardReference = useRef(
    Array.from({ length: 20 }, () => React.createRef() as React.MutableRefObject<HTMLSpanElement>)
  );

  return (
    <div>
      <DefaultFormContextProvider>
        <ContentLoadingModal
          display={isSubmitting}
          fields={{
            heading: { value: i18n.t('DefaultSubmission-Loading-Heading') },
            description: { value: i18n.t('DefaultSubmission-Loading-Description') }
          }}
        />

        {/* React Router prompt on route navigations */}
        <Prompt
          when={
            (Object.keys(dirtyFields).length > 0 || isAttachmentUploading) &&
            bypassIsDirty === false
          }
          message={i18n.t('DefaultSubmission-IsDirty')}
        />
        <form
          id="defaultSubmissionForm"
          className="form default-submission-form"
          onSubmit={handleSubmit(onSubmit, onError)}
          noValidate
        >
          {/* <!-- ============================== 4-COL BREADCRUMBS, TIMESTAMP, BUTTON ============================== --> */}
          {/* <!-- ............................ end TEXT BLOCK ............................ --> */}

          <div className={styles.layoutContainer}>
            <div>
              {/* <!-- ============================== Home owner CARD ============================== --> */}
              {formHasError && formErrorMessages.length >= 0 ? (
                <NotificationCard
                  notificationType={CardType.VERTICAL_ERROR}
                  title={i18n
                    .t('DefaultSubmission-Card-TopErrorMessage')
                    .replace('{count}', totalFormErrorCount.toString())}
                  message={formErrorMessages}
                  formWarningIcon={props.fields.formWarningIcon}
                  id={errorCardId}
                />
              ) : (
                <React.Fragment>
                  <FormCardHeader
                    {...{
                      introCardProps: {
                        testId: 'card_only_test',
                        image: props.fields.titleIcon,
                        title: {
                          value: props.fields.title.value
                        },
                        cardContent: {
                          value: props.fields.description.value
                        }
                      }
                    }}
                  />
                  <StepHeader title={props.fields.sectionOneTitle} />

                  <HomeownerLoanCheck
                    {...getHomeownerLoanCheckProps()}
                    id="homeOwnerLoanCheck"
                    isHomeOwnerLoan={isHomeOwnerLoan}
                    setIsHomeOwnerLoan={setIsHomeOwnerLoan}
                    errors={errors}
                    register={register as any}
                    setValueHandler={setValue as any}
                    control={control as any}
                  />
                </React.Fragment>
              )}

              <div className={`${styles.defaultFormContents} ${!isHomeOwnerLoan && 'hiddenItem'}`}>
                <StepHeader title={props.fields.sectionTwoTitle} />

                <span ref={cardReference.current[0]} />
                <LenderDetails
                  {...getLenderDetailsProps()}
                  {...commonCardProps}
                  id="lenderDetails"
                  userInfo={externalUserDetails}
                  isHomeOwnerLoan={isHomeOwnerLoan}
                />

                <span ref={cardReference.current[1]} />
                <LoanDetails
                  {...getLoanDetailsProps(props)}
                  {...commonCardProps}
                  id="loanDetails"
                />

                <span ref={cardReference.current[2]} />
                <Borrower
                  {...getBorrowerDetailsProps(props)}
                  {...commonCardProps}
                  cardId="borrowerDetails"
                  borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                  setBorrowerTotalMonthlyGrossIncome={setBorrowerTotalMonthlyGrossIncome}
                />

                <span ref={cardReference.current[3]} />
                <Assets
                  {...getAssetsDetailsProps(props)}
                  {...commonCardProps}
                  cardId="assets"
                  totalAssetsArray={totalAssetsArray}
                  setTotalAssetsArray={setTotalAssetsArray}
                  cumulativeTotalAssets={cumulativeTotalAssets}
                  setCumulativeTotalAssets={setCumulativeTotalAssets}
                />

                <span ref={cardReference.current[4]} />
                <Liabilities
                  {...getLiabilitiesDetailsProps(props)}
                  {...commonCardProps}
                  id="liabilities"
                  totalLiabilitiesArray={totalLiabilitiesArray}
                  setTotalLiabilitiesArray={setTotalLiabilitiesArray}
                  cumulativeTotalLiabilities={cumulativeTotalLiabilities}
                  setCumulativeTotalLiabilities={setCumulativeTotalLiabilities}
                  cumulativeTotalAssets={cumulativeTotalAssets}
                  setValueHandler={setValue}
                  trigger={trigger}
                />

                <span ref={cardReference.current[5]} />
                <MortgageObligations
                  {...getMortgageDetailsProps(props)}
                  {...commonCardProps}
                  id="mortgageObligations"
                />

                <span ref={cardReference.current[6]} />
                <GdsTdsCalculations
                  {...getGdsTdsCalculationsProps()}
                  {...commonCardProps}
                  id="gdsTdsCalulations"
                  borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                  totalMonthlyHouseholdHousingExpenses={totalMonthlyHouseholdHousingExpenses}
                  setTotalMonthlyHouseholdHousingExpenses={setTotalMonthlyHouseholdHousingExpenses}
                  totalMonthlyHouseholdUnsecuredDebt={totalMonthlyHouseholdUnsecuredDebt}
                  setTotalMonthlyHouseholdUnsecuredDebt={setTotalMonthlyHouseholdUnsecuredDebt}
                />

                <span ref={cardReference.current[7]} />
                <OtherFinancialObligations
                  {...getfinancialObligationsProps(props)}
                  {...commonCardProps}
                  id="otherFinancialObligations"
                />

                <span ref={cardReference.current[8]} />
                <ReasonsDefaultManagement
                  {...getReasonsDefaultManagementProps()}
                  {...commonCardProps}
                  id="reasonsDefaultManagement"
                  maxLength={Number(i18n.t('Globals-MultilineText-MaxLength'))}
                />

                <span ref={cardReference.current[9]} />
                <DefaultManagementTools
                  {...getDefaultManagementToolsProps(props)}
                  {...commonCardProps}
                  id="defaultManagementToolsDefaultSubmissionForm"
                  title={{
                    field: {
                      value: i18n.t('DefaultSubmission-Card-DefaultManagementToolsHeading')
                    }
                  }}
                  targetSection={TargetSection.DEFAULTSUBMISSION}
                  linedCardProps={{
                    id: 'defaultManagementToolsDefaultSubmissionForm',
                    testId: 'defaultManagementToolsTest',
                    linePosition: 'vertical',
                    lineColor: 'grey'
                  }}
                />

                <span ref={cardReference.current[10]} />
                <LenderAcknowledgment
                  {...getLenderAcknowledgmentProps()}
                  {...commonCardProps}
                  id="lenderAcknowledgement"
                />

                <span ref={cardReference.current[11]} />
                <MonitoringStrategy
                  {...getMonitoringStrategyProps()}
                  {...commonCardProps}
                  id="monitoringStrategy"
                />

                <span ref={cardReference.current[12]} />
                <HistoryDetails
                  {...getDefaultHistoryProps()}
                  {...commonCardProps}
                  id="defaultHistory"
                />

                <span ref={cardReference.current[13]} />
                <AttachmentForm
                  {...getAttachmentFormProps(props)}
                  {...commonCardProps}
                  id="attachments"
                  downloadMode={false}
                  uploadIcon={props.fields.uploadIcon}
                  indicateAttachmentUploadStatus={(status: boolean) => {
                    setIsAttachmentUploading(status);
                  }}
                  isInternalSite={isInternalSite}
                />
              </div>
            </div>
            <aside className={styles.showForDesktopOnly}>
              <div
                className={`${!isHomeOwnerLoan && 'hiddenItem'} ${styles.formProgressSticky}`}
                id="progressBar"
              >
                {isServer() === false && isHomeOwnerLoan !== false ? (
                  // ProgressBar calls window object, therefore an isServer() check is required prior to rendering
                  <ProgressBar
                    {...getProgressBarData(cardReference.current)}
                    isDisabledButton={isReadOnlyUser || isAttachmentUploading}
                    onCancelHandler={() => onReset()}
                    primaryButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')
                    }}
                    submitButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')
                    }}
                    cancelButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarCancelButton')
                    }}
                    onSaveDraftHandler={saveDraft}
                    saveDraftButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSaveDraftButton')
                    }}
                    isDraftLocked={false}
                    isSumbit={true}
                  />
                ) : (
                  ''
                )}
              </div>
            </aside>
          </div>
          <StickyFooter>
            <div className={`${styles.hideForDesktop} ${styles.stickyFooter}`}>
              {/* <Button
                name="primary-button"
                text={props.fields.submit}
                ariaText={props.fields.submit}
                type="submit"
                disabled={isReadOnlyUser}
              />
              <Button
                secondaryButton
                name="cancelEditBtn"
                text={props.fields.cancel}
                ariaText={props.fields.cancel}
                onClick={() => onReset()}
                type="button"
              /> */}
              <Button
                textButton
                name="cancelEditBtn"
                text={props.fields.cancel}
                ariaText={props.fields.cancel}
                onClick={() => onReset()}
                type="button"
              />
              {/* <Button
                id="buttonSubmitAdaptive"
                name="primary-button"
                disabled={isReadOnlyUser}
                buttonType={ButtonType.PRIMARY}
                ariaText={i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')}
                displayText={i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')}
                type="submit"
              />
              <Button
                id="buttonCancelAdaptive"
                name="secondary-button"
                displayText={i18n.t('DefaultSubmission-Card-ProgressBarCancelButton')}
                ariaText={i18n.t('DefaultSubmission-Card-ProgressBarCancelButton')}
                disabled={isReadOnlyUser}
                buttonType={ButtonType.SECONDARY}
                onClick={() => onReset()}
              /> */}
            </div>
          </StickyFooter>
        </form>
        <ToastNotification
          type={draftSuccessToast ? ApplicationStates.SUCCESS : ApplicationStates.ERROR}
          isActive={draftSuccessToast || showDefaultToast}
          title={
            draftSuccessToast
              ? i18n.t('DefaultsInventoryTable-SuccessToastMessage-Title')
              : i18n.t('DefaultSubmission-ErrorToastTitle')
          }
          content={
            draftSuccessToast
              ? i18n.t('DefaultSubmission-Card-Save-Draft-ToastMessage')
              : errorToastMsg
          }
          onCloseCallback={() => {
            setShowDefaultToast(false);
            setDraftSuccessToast(false);
            draftSuccessToast && history.push(`/${i18n.language}/default-inventory`);
            setIsDuplicateLoanNumberErrorToast(false);
            isDuplicateLoanNumberErrorToast && history.push(`/${i18n.language}/default-inventory`);
          }}
        />
        <Modal
          isActive={showLoanWarning}
          type={ApplicationStates.WARNING}
          title={i18n.t('DefaultSubmission-ModalTitle')}
          onCloseCallback={() => resetModal()}
          content={() => (
            <>
              <br />
              <p>
                {i18n.t(
                  isIncorrectLoanNumber
                    ? 'DefaultSubmission-IncorrectLoanNumber'
                    : 'DefaultSubmission-IncorrectLoanStatus'
                )}
              </p>
            </>
          )}
          buttons={() => (
            <>
              <Button
                secondaryButton
                name="cancelEditBtn"
                text={{ value: i18n.t('DefaultSubmission-ModalBackButton') }}
                ariaText={{ value: i18n.t('DefaultSubmission-ModalBackButton') }}
                onClick={() => resetModal()}
                type="button"
                disabled={isReadOnlyUser}
              />
              <Button
                name="primary-button"
                text={{ value: i18n.t('DefaultSubmission-ModalSubmitButton') }}
                ariaText={{ value: i18n.t('DefaultSubmission-ModalBackButton') }}
                type="button"
                onClick={handleSubmit(bypassWarningSubmit, onError)}
                disabled={isReadOnlyUser}
              />
            </>
          )}
        />
      </DefaultFormContextProvider>
    </div>
  );
};

export default withTranslation()(DefaultSubmissionForm);
