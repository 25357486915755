import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UserRole, Module } from '@hobt/constants';
import { StringTemplate } from '@hobt/data-vis-components';
import { defaultsPortal } from '@hobt/form-schema-validators';
import { joiResolver } from '@hookform/resolvers/joi';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ConcatenateAmortizationValuesToString } from 'Components/Common/Helpers';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { StickyFooter } from 'Components/PageComponents/StickyFooter';
import { removeEmptyFields } from 'Constants/Utils/RemoveEmptyFields';
import {
  ToastNotification,
  NotificationCard,
  NotificationCardProps
} from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates, CardType } from 'Feature/CommonComponents/Enums';
import { ProgressBar, getProgressBarData } from 'Feature/CommonComponents/ProgressBarComponents';
import { LoaderAnimation, Button, ButtonType } from 'Feature/CommonComponents/UserControls';
import { DefaultApiCallHandler } from 'Feature/DefaultsInventory/components/api/ApiCallHandlers';
import { CardCountsObject } from 'Feature/DefaultsInventory/models/DefaultInventoryDetails';
import { ICard } from 'Feature/DefaultSubmissionForm/components/Card.types';
import {
  Assets,
  getAssetsDetailsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/Assets';
import {
  AttachmentForm,
  getAttachmentFormProps
} from 'Feature/DefaultSubmissionForm/components/Cards/AttachmentForm';
import {
  Borrower,
  getBorrowerDetailsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/Borrower';
import {
  DefaultManagementTools,
  getDefaultManagementToolsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/DefaultManagementTools';
import {
  GdsTdsCalculations,
  getGdsTdsCalculationsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/GdsTdsCalculations';
import {
  getDefaultHistoryProps,
  HistoryDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/History';
import {
  getLenderAcknowledgmentProps,
  LenderAcknowledgment
} from 'Feature/DefaultSubmissionForm/components/Cards/LenderAcknowledgment';
import {
  getLenderDetailsProps,
  LenderDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/LenderDetails';
import {
  getLiabilitiesDetailsProps,
  Liabilities
} from 'Feature/DefaultSubmissionForm/components/Cards/Liabilities';
import {
  getLoanDetailsProps,
  LoanDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/LoanDetails';
import {
  getMonitoringStrategyProps,
  MonitoringStrategy
} from 'Feature/DefaultSubmissionForm/components/Cards/MonitoringStrategy';
import {
  getMortgageDetailsProps,
  MortgageObligations
} from 'Feature/DefaultSubmissionForm/components/Cards/MortgageObligations';
import { withFeature } from 'flagged';
import i18n from 'i18next';
import { Prompt, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import stickybits from 'stickybits';
import {
  getfinancialObligationsProps,
  OtherFinancialObligations
} from 'Feature/DefaultSubmissionForm/components/Cards/OtherFinancialObligations';
import {
  getReasonsDefaultManagementProps,
  ReasonsDefaultManagement
} from 'Feature/DefaultSubmissionForm/components/Cards/ReasonsDefaultManagement';
import { TargetSection } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import GetErrorCount from 'Feature/DefaultSubmissionForm/components/TopErrorMessages';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

// Analytics Service
import useCustomEvent from 'Foundation/Analytics/useCustomEvent';
import { useAppInsightsContext } from 'Foundation/Analytics/AppInsightsContext';
import { config } from '../../../../../config';
import { mapDefaultDetailsSubmissionFields } from '../DefaultDetailSubmissionFormMapings';
import { DefaultDetailSubmissionFormProps } from './DefaultDetailSubmissionForm.types';
import { DefaultSubmissionFormPropsFields } from 'Feature/DefaultSubmissionForm/models/DefaultSubmissionFormProps';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { DefaultRequestStatus } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';

const DefaultDetailSubmissionForm: React.FC<DefaultDetailSubmissionFormProps> = ({
  detailsData,
  watchLoanValidation,
  setDisableCardBtns,
  defaultSubmissionFormProps,
  onSubmitCallback,
  activeTab,
  disableCalendar
}) => {
  const errorCardId = 'DefaultInventoryErrorForm';

  const draftDefaultID = detailsData?.cmhcDefaultAccountID;
  const parsedUUID = draftDefaultID !== null ? draftDefaultID : '';
  const authContext = useAuthenticationContext();
  const { masterEditStatusCall } = DefaultApiCallHandler(parsedUUID, authContext, {
    timeout: config.defaultApi.requestTimeout
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const loggedInUserID: string = sitecoreContext?.user?.userID;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    Module.Default,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const isMasterUser: boolean = isUserInRoles(
    Module.Default,
    [UserRole.MasterUser],
    sitecoreContext?.user?.moduleRoleMapping
  );

  const isClerkUser: boolean = isUserInRoles(
    Module.Default,
    [UserRole.Clerk],
    sitecoreContext?.user?.moduleRoleMapping
  );

  // Master user has access to all drafts regardless of owner
  const isDraftOwner = isMasterUser
    ? true
    : loggedInUserID === detailsData?.operation?.requestCreatedUserID;

  const isSubmitButtonDisabled = !isMasterUser
    ? loggedInUserID !== detailsData?.operation?.requestCreatedUserID
    : false;

  const [borrowerTotalMonthlyGrossIncome, setBorrowerTotalMonthlyGrossIncome] = useState(0);
  const [totalMonthlyHouseholdHousingExpenses, setTotalMonthlyHouseholdHousingExpenses] =
    useState(0);
  const [totalMonthlyHouseholdUnsecuredDebt, setTotalMonthlyHouseholdUnsecuredDebt] = useState(0);
  const [totalAssetsArray, setTotalAssetsArray] = useState([0]);
  const [totalLiabilitiesArray, setTotalLiabilitiesArray] = useState([0]);
  const [cumulativeTotalAssets, setCumulativeTotalAssets] = useState(0);
  const [cumulativeTotalLiabilities, setCumulativeTotalLiabilities] = useState(0);

  const [totalFormErrorCount, setTotalFormErrorCount] = useState(0);
  const [formHasError, setFormHasError] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState<string[]>([]);
  const [bypassIsDirty, setBypassIsDirty] = useState<boolean>(false);
  const [isAttachmentDirty, setIsAttachmentDirty] = useState<boolean>(false);

  const history = useHistory();

  // manually setting own isDirty check for card deletions
  const [cardDeleted, setCardDeleted] = useState<boolean>(false);

  const [isAttachmentUploading, setIsAttachmentUploading] = useState<boolean>(false);

  // pre-populated data cardCounts:
  const [cardCounts, setCardCounts] = useState<CardCountsObject>({});

  // manually setting own isDirty check for stage three tool deletions
  const [stageThreeToolDeleted, setStageThreeToolDeleted] = useState<boolean>(false);
  const [showEditInProgressToast, setShowEditInProgressToast] = useState<any | boolean>(true);
  const [isDraftTab, setIsDraftTab] = useState<boolean>(false);
  const [showSaveDraftButton, setShowSaveDraftButton] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(true);
  const [sessionTimeout, setSessionTimeout] = useState<boolean>(false);
  const [isCurrentUserLocking, setIsCurrentUserLocking] = React.useState<boolean>(false);
  const [editStatusData, setEditStatusData] = React.useState({
    isBeingEdited: false,
    isBeingEditedByUserID: ''
  });
  const [continueSession, setContinueSession] = useState<boolean>(false);

  const secondaryButtonText = showSaveDraftButton
    ? i18n.t('DefaultSubmission-Card-ProgressBarSaveDraftButton')
    : i18n.t('DefaultSubmission-Card-ProgressBarEditButton');

  const primaryButtonText = isDraftTab
    ? i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')
    : i18n.t('DefaultSubmission-Card-ProgressBarSaveButton');

  // Creating card referance of use ref and send to progress bar
  const cardReference = useRef(
    Array.from({ length: 20 }, () => React.createRef() as React.MutableRefObject<HTMLSpanElement>)
  );

  const validationErrNotification: NotificationCardProps = {
    title: StringTemplate(
      i18n.t('DefaultsInventoryDetails-NotificationCard-ValidationErrStrTemplate'),
      '{{}}',
      '1'
    ),
    message: [i18n.t('DefaultsInventoryDetails-NotificationCard-ValidationErrorMessage')],
    notificationType: CardType.VERTICAL_WARNING,
    formWarningIcon: defaultSubmissionFormProps.fields.formWarningIcon
  };

  // App Insights
  const reactPlugin = useAppInsightsContext();
  const trackDefaultSubmission = useCustomEvent(reactPlugin, 'Default Detail Form Submission', {});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    getValues,
    reset,
    formState,
    setError,
    clearErrors
  } = useForm({
    resolver: joiResolver(defaultsPortal),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  const commonCardProps: ICard = {
    register,
    errors,
    setValueHandler: setValue,
    control,
    watch,
    getValues,
    reset,
    setCardDeleted,
    setStageThreeToolDeleted
  };

  const preApprovalValue = getValues('managementInformation.preApprovalRequired');
  const isPreApprovalRequired = preApprovalValue === 'true' || preApprovalValue === true;

  const lenderAcknowledgementDetails = getValues(
    'managementInformation.lenderAcknowledgementAdditionalDetails'
  );
  const isEmptyOrNull =
    (lenderAcknowledgementDetails !== null && !lenderAcknowledgementDetails?.trim().length) ||
    lenderAcknowledgementDetails === null;

  useEffect(() => {
    if (isPreApprovalRequired && isEmptyOrNull) {
      setError('managementInformation.lenderAcknowledgementAdditionalDetails', {
        type: 'manual',
        message: 'Required'
      });
      setValue?.('managementInformation.lenderAcknowledgementAdditionalDetails', null);
    } else if (!isPreApprovalRequired && lenderAcknowledgementDetails === null) {
      setValue?.('managementInformation.lenderAcknowledgementAdditionalDetails', '');
      clearErrors('managementInformation.lenderAcknowledgementAdditionalDetails');
    }
  }, [isPreApprovalRequired, isEmptyOrNull, lenderAcknowledgementDetails]);
  // Form Handling
  const onSubmit = (formData: any) => {
    const updateFormData = formData;

    updateFormData.managementInformation.submittedStageTwo.newAmortizationPeriod =
      ConcatenateAmortizationValuesToString(
        getValues('managementInformation.submittedStageTwo.newAmortizationPeriodYears'),
        getValues('managementInformation.submittedStageTwo.newAmortizationPeriodMonths')
      );

    updateFormData.detail.firstLoan.currentAmortizationPeriod =
      ConcatenateAmortizationValuesToString(
        getValues('detail.firstLoan.currentAmortizationPeriodYears'),
        getValues('detail.firstLoan.currentAmortizationPeriodMonths')
      );

    isDraftSubmit
      ? (updateFormData.other.defaultStatus = DefaultRequestStatus.InDraft)
      : (updateFormData.other.defaultStatus = DefaultRequestStatus.PendingCmhcReview);

    const strippedValues = removeEmptyFields(updateFormData);

    setStageThreeToolDeleted(false);
    setFormHasError(false);
    onSubmitCallback(false, strippedValues);
  };

  const onError = (errs: any) => {
    trackDefaultSubmission({ frontendFormErrors: errs });
    setFormHasError(true);
    setDisableCardBtns(true);
    setTimeout(() => {
      setTotalFormErrorCount(document.querySelectorAll('[class^=thisFieldHasError]').length);
      setFormErrorMessages(GetErrorCount);

      const errorCardElement = document.getElementById(errorCardId);
      if (errorCardElement != null) {
        errorCardElement.focus();
      }
    }, 0);
    console.log('errs', errs);
  };

  useEffect(() => {
    return () => {
      if (isServer() === false) {
        // clean up onbeforeunload, on component unmount
        window.onbeforeunload = null;
      }
    };
  }, []);

  // return number of pre-populated cards, if exists
  const prepopulatedLength = (name: string): number | undefined => {
    return detailsData[name] && detailsData[name].length;
  };

  const prepopToolsLength = () => {
    const stageThreeDataObj = detailsData.managementInformation.submittedStageThree;
    return (
      stageThreeDataObj.combinationFlag &&
      stageThreeDataObj.toolDetail &&
      stageThreeDataObj.toolDetail.length
    );
  };

  const setValueData = useCallback(() => {
    setStageThreeToolDeleted(false); // disable save and cancel btns

    // Manually register unbound fields
    register('other.defaultStatus');
    register('other.homeownerLoan');
    register('other.validLoan');

    // setting these values to 0000 as these are just needed for schema validation
    register('detail.firstLoan.currentAmortizationPeriod');
    setValue('detail.firstLoan.currentAmortizationPeriod', '0000');

    register('managementInformation.submittedDefaultStage');
    setValue('managementInformation.submittedDefaultStage', '0000');

    register('managementInformation.submittedStageTwo.newAmortizationPeriod');
    setValue('managementInformation.submittedStageTwo.newAmortizationPeriod', '0000');

    if (detailsData.other.defaultStatusComment !== undefined) {
      register('other.defaultStatusComment');
    }

    if (detailsData.other.defaultStatusReason !== undefined) {
      register('other.defaultStatusReason');
    }
  }, []);

  useEffect(() => {
    // functions to set pre-populated data card counts:
    setCardCounts({
      asset: prepopulatedLength(`asset`),
      borrower: prepopulatedLength(`borrower`),
      liability: prepopulatedLength(`liability`),
      mortgageObligation: prepopulatedLength(`mortgageObligation`),
      financialObligation: prepopulatedLength(`financialObligation`),
      stageThreeTools: prepopToolsLength()
    });

    const mappedData = mapDefaultDetailsSubmissionFields(detailsData);

    reset(mappedData);
    setValueData();
  }, [detailsData]);

  const validLoanWatch: boolean = watch('other.validLoan');

  useEffect(() => {
    if (validLoanWatch != null) {
      watchLoanValidation(validLoanWatch);
    }
  }, [validLoanWatch]);

  // scroll fix for IE:
  useEffect(() => {
    stickybits('#progressBarDetail', {
      stickyClass: 'IEsticky',
      stuckClass: 'IEstuck'
    });
  });

  const formErrorsLength = Object.keys(formState.errors).length;
  const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
  // isDirty check
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if ((isDirtyAlt || isAttachmentUploading) && bypassIsDirty === false) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }

        return null;
      };
    }
    setDisableCardBtns(isDirtyAlt || formErrorsLength > 0 || formHasError);
  }, [isDirtyAlt, formErrorsLength, bypassIsDirty]);

  const handleToastClose = () => {
    setShowEditInProgressToast(false);
    !isMasterUser && history.replace(`/${i18n.language}/default-inventory`);
  };

  useEffect(() => {
    const defaultStatus = getValues()?.other?.defaultStatus;
    if (defaultStatus === DefaultRequestStatus.InDraft) setIsDraftTab(true);
  }, []);

  const isDraftLocked =
    detailsData?.editingStatus !== undefined &&
    detailsData?.editingStatus?.isBeingEdited == true &&
    loggedInUserID !== detailsData?.editingStatus?.isBeingEditedByUserID;

  const handleOnEditLock = (isBeingEdited: boolean) => {
    let EditingStatus = {
      isBeingEdited
    };
    masterEditStatusCall(parsedUUID, EditingStatus)
      .then((res: any) => {
        setEditStatusData(res.data.data[0].editingStatus);
      })
      .catch((error: any) => {
        console.error('Error occurred while making API call:', error);
      });
  };

  const conditionForLockOnComponentMount = React.useMemo(() => {
    if (isMasterUser) {
      return false;
    }
    return isDraftTab && !isDraftLocked && isDraftOwner;
  }, [isDraftTab, isDraftLocked, isDraftOwner, isInEditMode, isMasterUser]);

  // making lock api call when component mounts
  React.useEffect((): any => {
    if (conditionForLockOnComponentMount) {
      handleOnEditLock(true);
      return () => {
        handleOnEditLock(false);
      };
    }
  }, [isDraftTab, isDraftLocked, conditionForLockOnComponentMount]);

  const conditionForUnlock = React.useMemo(() => {
    return (
      detailsData?.editingStatus?.isBeingEdited === true &&
      loggedInUserID === detailsData?.editingStatus?.isBeingEditedByUserID
    );
  }, [detailsData, loggedInUserID]);

  React.useEffect(() => {
    if (
      editStatusData.isBeingEdited === true &&
      loggedInUserID === editStatusData.isBeingEditedByUserID
    )
      setIsCurrentUserLocking(true);
  }, [editStatusData]);

  // Custom event listener for session timeout
  React.useEffect(() => {
    const handleEvent = (e: any) => {
      setSessionTimeout(e.detail?.sessionTimeOut);
      setContinueSession(e?.detail?.continueSession);
    };
    window.addEventListener('logoutEvent', handleEvent);
    return () => {
      window.removeEventListener('logoutEvent', handleEvent);
    };
  }, []);

  // Releasing lock when session times out
  React.useEffect(() => {
    if (isCurrentUserLocking) {
      if (sessionTimeout) {
        handleOnEditLock(false);
      } else if (continueSession) {
        handleOnEditLock(true);
      }
    }
  }, [sessionTimeout, isCurrentUserLocking]);

  // API unmount call for master user
  React.useEffect(() => {
    return () => {
      if (isDraftTab && isMasterUser && editStatusData?.isBeingEdited === true)
        handleOnEditLock(false);
    };
  }, [isDraftTab, editStatusData]);

  // Log out dispatch event
  const dispatchDefaultEvent = (editStatusData: any) => {
    const event = new CustomEvent('defaultEvent', {
      detail: {
        draftDefaultID,
        editingStatus: editStatusData
      }
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    dispatchDefaultEvent(editStatusData);
    return () => {
      dispatchDefaultEvent(editStatusData);
    };
  }, [editStatusData]);

  // Unlock draft while closing browser
  React.useEffect((): any => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();

      if (conditionForUnlock) handleOnEditLock(false);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up the event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Unlock draft if current tab is closed
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (conditionForUnlock) handleOnEditLock(false);
      event.preventDefault();
      event.returnValue = '';
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleEdit = () => {
    if (isMasterUser && isDraftTab) {
      setIsInEditMode(false);
      setShowSaveDraftButton(true);
      handleOnEditLock(true);
    }
  };

  let isDraftSubmit = false;
  const handleSaveDraft = () => {
    if (isDraftTab) {
      if (isMasterUser) {
        setIsInEditMode(true);
        setShowSaveDraftButton(false);
      }
      handleOnEditLock(false);
    }

    isDraftSubmit = true;
    onSubmit(getValues());
  };

  if (Object.keys(cardCounts).length > 0) {
    return (
      <>
        {/* React Router prompt on route navigations */}
        <Prompt
          when={isDirtyAlt || isAttachmentUploading}
          message={i18n.t('DefaultSubmission-IsDirty')}
        />
        {isDraftTab && isDraftLocked && (
          <ToastNotification
            type={ApplicationStates.CANCEL}
            isActive={showEditInProgressToast}
            title={i18n.t('DefaultSubmission-MasterEditToast-Title')}
            content={{
              value: i18n.t('DefaultSubmission-MasterEditToast-Content')
            }}
            onCloseCallback={handleToastClose}
          />
        )}
        <form
          id="defaultInventoryDetailsForm"
          name="default-inventory-details-form"
          className="form default-submission-form"
          onSubmit={handleSubmit(onSubmit, onError)}
          noValidate
        >
          <div className={styles.layoutContainer}>
            <div>
              {/* Loan validation warning */}
              {!validLoanWatch && <NotificationCard {...validationErrNotification} />}

              {/* Card level error */}
              {formHasError && formErrorMessages.length >= 0 && (
                <NotificationCard
                  notificationType={CardType.VERTICAL_ERROR}
                  title={i18n
                    .t('DefaultSubmission-Card-TopErrorMessage')
                    .replace('{count}', totalFormErrorCount.toString())}
                  message={formErrorMessages}
                  formWarningIcon={defaultSubmissionFormProps.fields.formErrorIcon}
                  id={errorCardId}
                />
              )}

              {/* Cards */}
              <span ref={cardReference.current[0]} />
              <fieldset
                disabled={
                  isDraftTab
                    ? (isInEditMode && isMasterUser) ||
                      (!isMasterUser && !isDraftOwner) ||
                      isDraftLocked
                    : false
                }
              >
                <LenderDetails
                  {...getLenderDetailsProps()}
                  {...commonCardProps}
                  id="lenderDetails"
                  activeTab={activeTab}
                />

                <span ref={cardReference.current[1]} />
                <LoanDetails
                  {...getLoanDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="loanDetails"
                  disableCalendar={disableCalendar}
                />

                <span ref={cardReference.current[2]} />
                <Borrower
                  {...getBorrowerDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  cardId="borrowerDetails"
                  cardCount={cardCounts.borrower}
                  borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                  setBorrowerTotalMonthlyGrossIncome={setBorrowerTotalMonthlyGrossIncome}
                />

                <span ref={cardReference.current[3]} />
                <Assets
                  {...getAssetsDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  cardId="assets"
                  cardCount={cardCounts.asset}
                  totalAssetsArray={totalAssetsArray}
                  setTotalAssetsArray={setTotalAssetsArray}
                  cumulativeTotalAssets={cumulativeTotalAssets}
                  setCumulativeTotalAssets={setCumulativeTotalAssets}
                />

                <span ref={cardReference.current[4]} />
                <Liabilities
                  {...getLiabilitiesDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="liabilities"
                  cardCount={cardCounts.liability}
                  totalLiabilitiesArray={totalLiabilitiesArray}
                  setTotalLiabilitiesArray={setTotalLiabilitiesArray}
                  cumulativeTotalLiabilities={cumulativeTotalLiabilities}
                  setCumulativeTotalLiabilities={setCumulativeTotalLiabilities}
                  cumulativeTotalAssets={cumulativeTotalAssets}
                />

                <span ref={cardReference.current[5]} />
                <MortgageObligations
                  {...getMortgageDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="mortgageObligations"
                  cardCount={cardCounts.mortgageObligation}
                />

                <span ref={cardReference.current[6]} />
                <GdsTdsCalculations
                  {...getGdsTdsCalculationsProps()}
                  {...commonCardProps}
                  id="gdsTdsCalulations"
                  borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                  totalMonthlyHouseholdHousingExpenses={totalMonthlyHouseholdHousingExpenses}
                  setTotalMonthlyHouseholdHousingExpenses={setTotalMonthlyHouseholdHousingExpenses}
                  totalMonthlyHouseholdUnsecuredDebt={totalMonthlyHouseholdUnsecuredDebt}
                  setTotalMonthlyHouseholdUnsecuredDebt={setTotalMonthlyHouseholdUnsecuredDebt}
                />

                <span ref={cardReference.current[7]} />
                <OtherFinancialObligations
                  {...getfinancialObligationsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="otherFinancialObligations"
                  cardCount={cardCounts.financialObligation}
                />

                <span ref={cardReference.current[8]} />
                <ReasonsDefaultManagement
                  {...getReasonsDefaultManagementProps()}
                  {...commonCardProps}
                  id="reasonsDefaultManagement"
                  maxLength={Number(i18n.t('Globals-MultilineText-MaxLength'))}
                  disableCalendar={disableCalendar}
                />

                <span ref={cardReference.current[9]} />
                <DefaultManagementTools
                  {...getDefaultManagementToolsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="defaultManagementToolsInventoryDetailsTab"
                  title={{
                    field: {
                      value: i18n.t('DefaultSubmission-Card-DefaultManagementToolsHeading')
                    }
                  }}
                  cardCount={cardCounts.stageThreeTools}
                  targetSection={TargetSection.DEFAULTSUBMISSION}
                  linedCardProps={{
                    id: 'defaultManagementToolsInventoryDetailsTab',
                    testId: 'defaultManagementToolsInventoryDetailsTab',
                    linePosition: 'vertical',
                    lineColor: 'grey'
                  }}
                  disableCalendar={disableCalendar}
                />

                <span ref={cardReference.current[10]} />
                <LenderAcknowledgment
                  {...getLenderAcknowledgmentProps()}
                  {...commonCardProps}
                  id="lenderAcknowledgement"
                />

                <span ref={cardReference.current[11]} />
                <MonitoringStrategy
                  {...getMonitoringStrategyProps()}
                  {...commonCardProps}
                  id="monitoringStrategy"
                />

                <span ref={cardReference.current[12]} />
                <HistoryDetails
                  {...getDefaultHistoryProps()}
                  {...commonCardProps}
                  id="defaultHistory"
                />

                <span ref={cardReference.current[13]} />
                <AttachmentForm
                  {...getAttachmentFormProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="attachments"
                  numLoadedAttachments={detailsData.attachment ? detailsData.attachment.length : 0}
                  indicateAttachmentUploadStatus={(status: boolean) => {
                    setIsAttachmentUploading(status);
                  }}
                  setAttachmentDirtyCallback={setIsAttachmentDirty}
                  isAttachmentDirty={isAttachmentDirty}
                  isInternalSite={FeatureFlags.INTERNAL}
                  onDownloadCallback={() => {
                    setBypassIsDirty(true);
                  }}
                  downloadMode={
                    detailsData.attachment != null && detailsData.attachment?.length !== 0
                  }
                  attachments={detailsData.attachment}
                />
              </fieldset>
            </div>
            <aside className={styles.showForDesktopOnly}>
              <div className={`form-progress__sticky`} id="progressBarDetail">
                {isServer() === false && activeTab === TargetSection.DEFAULTSUBMISSION && (
                  <ProgressBar
                    {...getProgressBarData(cardReference.current)}
                    isDisabledButton={isReadOnlyUser}
                    isMasterUser={isMasterUser}
                    isDraftTab={isDraftTab}
                    onCancelHandler={() => {
                      setTimeout(() => {
                        reset();
                        setValueData();
                      }, 0);
                    }}
                    onSubmitHandler={() => handleSubmit(onSubmit, onError)}
                    submitButtonText={{
                      // Give condition for draft tab
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')
                    }}
                    isSubmitButtonDisabled={isSubmitButtonDisabled}
                    onEditHandler={() => handleEdit()}
                    onSaveDraftHandler={() => handleSaveDraft()}
                    primaryButtonText={{
                      value: primaryButtonText
                    }}
                    secondaryButtonText={{
                      value: secondaryButtonText
                    }}
                    editButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarEditButton')
                    }}
                    saveDraftButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSaveDraftButton')
                    }}
                    cancelButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarDiscardChangesButton')
                    }}
                    showSaveDraftButton={showSaveDraftButton}
                    isSumbit={false}
                    isDraftLocked={isDraftLocked}
                  />
                )}
              </div>
            </aside>
          </div>
        </form>
      </>
    );
  }
  return <LoaderAnimation />;
};

export default withFeature(FeatureFlags.INTERNAL)(DefaultDetailSubmissionForm);
