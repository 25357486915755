import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { UserRole, ModuleMapping } from '@hobt/constants';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';

import { DefaultRequestStatus } from 'Foundation/Api';

import { DefaultSubmissionModals } from 'Feature/DefaultsInventory/components/DefaultSubmissionModals/DefaultSubmissionModals';
import FormInputDropdownText from 'Feature/DefaultSubmissionForm/components/FormInputDropdownText';

import { withFeature } from 'flagged';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { CardHeader } from 'Feature/CommonComponents/ContentComponents';
import {
  DECISION_RATIONALE_ENABLED_STATUSES,
  DefaultDetailsInternalHeaderProps,
  SitecoreItem
} from './DefaultDetailsInternalHeader.type';
import { ButtonType } from '../../../../CommonComponents/UserControls';

import styles from './styles.module.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { Modal as ConfirmationModal } from 'Components/Common/Modal';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';

const DefaultDetailsInternalCardHeader: React.FC<DefaultDetailsInternalHeaderProps> = ({
  detailsData,
  watchLoanValidation,
  areDisabledCardBtns,
  handleDecisioningSubmission,
  triggerModal,
  setPageModal,
  emailSubjectLine,
  cancelOptions,
  declineOptions
}) => {
  const hasDefaultStatus = (disabledStatuses: Set<number>) => {
    return detailsData && disabledStatuses.has(Number(detailsData.other.defaultStatus));
  };

  const enableDecisionRationale = DECISION_RATIONALE_ENABLED_STATUSES.includes(
    detailsData?.other?.defaultStatus
  );

  const isDisabledStatuses = new Set<number>([DefaultRequestStatus.Cancelled]);
  const isActionButtonDisabled = hasDefaultStatus(isDisabledStatuses) || areDisabledCardBtns;

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;
  const loggedInUserID: string = sitecoreContext?.user?.userID;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const isClerkUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.Clerk],
    moduleRoleMapping
  );

  const isMasterUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.MasterUser],
    moduleRoleMapping
  );

  const sitecoreVariables = sitecoreContext.route.placeholders['hbt-main']?.find(
    (item: SitecoreItem) => {
      return item.componentName === 'DefaultInventoryDetails';
    }
  );

  const isDraftTab = detailsData?.other?.defaultStatus === DefaultRequestStatus.InDraft;

  const rowID = detailsData?.cmhcDefaultAccountID;
  const requestCreatedUserID = detailsData?.operation?.requestCreatedUserID;
  const [isDeleteDropdownDisabled, setIsDeleteDropdownDisabled] = useState(false);
  const isCreatedBySameUser = loggedInUserID === requestCreatedUserID;
  const { refusalReason } = sitecoreVariables.fields;
  const decisionRationaleTitle = sitecoreVariables.fields.decisionRationale.value;
  const commentsTitle = sitecoreVariables.fields.commentsTitle.value;
  const viewDecisionRationale = sitecoreVariables.fields.viewDecisionRationale.value;
  const [openSidedrawer, setSidedrawerOpen] = useState<boolean>(false);
  const [showPageConfirmModal, setShowConfirmPageModal] = useState<boolean>(false);
  const [dropDownItems, setDropDownItems] = useState([
    {
      id: 'buttonCancelRequest',
      buttonType: ButtonType.PRIMARY,
      displayText: i18n.t('DefaultsInventoryDetails-Button-CancelRequest'),
      isDropDown: true,
      name: 'cancelRequest',
      disabled: isReadOnlyUser || isActionButtonDisabled,
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        handleActionButtonClick(e);
      }
    },
    {
      id: 'buttonInactivateRequest',
      buttonType: ButtonType.PRIMARY,
      displayText: i18n.t('DefaultsInventoryDetails-Button-InactivateRequest'),
      isDropDown: true,
      name: 'inactivateRequest',
      disabled: isReadOnlyUser || isActionButtonDisabled,
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        handleActionButtonClick(e);
      }
    }
  ]);

  const handleDeleteButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmPageModal(true);
  };

  const dropDownDeleteItems = [
    {
      id: 'buttonDeleteRequest',
      buttonType: ButtonType.PRIMARY,
      displayText: i18n.t('Delete'),
      isDropDown: true,
      name: 'deleteRequest',
      disabled: isReadOnlyUser || isActionButtonDisabled || isDeleteDropdownDisabled,
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        handleDeleteButtonClick(e);
      }
    }
  ];

  useEffect(() => {
    setIsDeleteDropdownDisabled(isMasterUser ? false : !isCreatedBySameUser);
  }, [isCreatedBySameUser]);

  const handleActionButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isServer() === false && e.currentTarget.name === 'emailLender') {
      e.preventDefault();
      const subjectLine = `${emailSubjectLine} ${detailsData.detail.cmhcLoanAccountNumber}`;
      window.location.href = `mailto:${detailsData.lender.contactEmail}?subject=${encodeURI(
        subjectLine
      )}`;
    } else {
      const modals = DefaultSubmissionModals(
        handleDecisioningSubmission,
        () => {
          triggerModal(false);
        },
        cancelOptions,
        declineOptions
      );

      const modalToShow: any = {
        approveLenderSubmission: () => setPageModal(modals.getApproveLenderSubmission()),
        approveCmhcWorkoutPlan: () => setPageModal(modals.getApproveCmhcWorkoutPlan()),
        decline: () => setPageModal(modals.getDecline()),
        cancelRequest: () => setPageModal(modals.getCancel()),
        inactivateRequest: () => setPageModal(modals.getInactivate())
      };

      modalToShow[e.currentTarget.name]();
      triggerModal(true);
    }
  };

  useEffect(() => {
    if (enableDecisionRationale) {
      dropDownItems.unshift({
        id: 'buttonViewRationale',
        buttonType: ButtonType.PRIMARY,
        displayText: viewDecisionRationale,
        isDropDown: true,
        name: 'viewRationale',
        disabled: isReadOnlyUser || !enableDecisionRationale,
        onClick: () => {
          setSidedrawerOpen(true);
        }
      });
      setDropDownItems(dropDownItems);
    }
  }, [dropDownItems, enableDecisionRationale]);

  useEffect(() => {
    isDraftTab && setDropDownItems(dropDownDeleteItems);
  }, [isDraftTab]);

  return (
    <div className={`dataVis ${styles.layout}`}>
      <ConfirmationModal
        type={ApplicationStates.SUCCESS}
        style={'success'}
        isActive={showPageConfirmModal}
        title={i18n.t('DefaultsInventoryDetails-Modal-Delete-Title')}
        onCloseCallback={() => setShowConfirmPageModal(false)}
        onPrimaryCallback={() =>
          handleDecisioningSubmission(DefaultRequestStatus.Delete, {}, rowID)
        }
        content={i18n.t('DefaultsInventoryDetails-Modal-Delete-Description')}
        fields={{
          saveButton: {
            value: i18n.t('DefaultsInventoryDetails-Modal-Delete-ButtonText')
          },
          cancelButton: { value: i18n.t('DefaultsInventoryDetails-Modal-Delete-CancelButton') }
        }}
      />
      <CardHeader
        preTitle={`${i18n.t('DefaultsInventoryDetails-Title-LenderReferenceText')}: ${
          detailsData && detailsData.lender ? detailsData.lender.approvedLenderReferenceNumber : ''
        }`}
        title={`${i18n.t('DefaultsInventoryDetails-Title-CmhcLoanNumberText')}: ${
          detailsData ? detailsData.detail.cmhcLoanAccountNumber : ''
        }`}
        tagText={
          detailsData ? i18n.t(`Globals-ApplicationStatus-${detailsData.other.defaultStatus}`) : ''
        }
        actionButtons={[
          {
            dropdownButton: {
              id: 'buttonApproveText',
              name: 'approve',
              buttonType: ButtonType.PRIMARY,
              displayText: i18n.t('DefaultsInventoryDetails-Button-ApproveText') as string,
              isDropDown: true,
              onClick: () => {}
            },
            dropdownItems: [
              {
                id: 'buttonApproveLender',
                buttonType: ButtonType.PRIMARY,
                displayText: i18n.t('DefaultsInventoryDetails-Button-ApproveLenderSubmissionText'),
                isDropDown: true,
                name: 'approveLenderSubmission',
                onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                  handleActionButtonClick(e);
                },
                disabled:
                  isReadOnlyUser || isActionButtonDisabled || isClerkUser || !watchLoanValidation
              },
              {
                id: 'buttonApproveCmhcWorkoutPlan',
                buttonType: ButtonType.PRIMARY,
                displayText: i18n.t('DefaultsInventoryDetails-Button-ApproveCmhcWorkoutPlanText'),
                isDropDown: true,
                name: 'approveCmhcWorkoutPlan',
                onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                  handleActionButtonClick(e);
                },
                disabled:
                  isReadOnlyUser || isActionButtonDisabled || isClerkUser || !watchLoanValidation
              }
            ]
          },
          {
            id: 'buttonEmailLender',
            additionalStyles: 'btn__noMinWidth',
            name: 'emailLender',
            buttonType: ButtonType.PRIMARY,
            displayText: i18n.t('DefaultsInventoryDetails-Button-EmailLenderText') as string,
            onClick: (e) => {
              handleActionButtonClick(e);
            },
            disabled: isReadOnlyUser
          },
          {
            id: 'buttonDecline',
            name: 'decline',
            buttonType: ButtonType.SECONDARY,
            displayText: i18n.t('DefaultsInventoryDetails-Button-DeclineText'),
            disabled: isReadOnlyUser || isActionButtonDisabled || !watchLoanValidation,
            onClick: (e) => {
              handleActionButtonClick(e);
            }
          },
          {
            dropdownButton: {
              id: 'buttonMoreActions',
              name: 'moreActions',
              buttonType: ButtonType.TEXT,
              isIcon: true,
              icon: 'more_vert',
              iconSize: 40,
              onClick: () => {}
            },
            dropdownItems: isDraftTab ? dropDownDeleteItems : dropDownItems,
            otherDropdownProps: { style: { minWidth: '100px' } }
          }
        ]}
      />
      <SideDrawer
        isActive={openSidedrawer}
        handleOutsideClick={() => {
          setSidedrawerOpen(false);
        }}
      >
        <div className={styles.scrollable}>
          <div className={styles.header}>
            <h2 className={styles.title}>{decisionRationaleTitle}</h2>
            <button
              className={styles.closebtn}
              onClick={() => {
                setSidedrawerOpen(false);
              }}
            ></button>
          </div>
          <div className={styles.content}>
            <div className={styles.selectContainer}>
              {detailsData?.other?.defaultStatus === DefaultRequestStatus.Cancelled ? (
                <FormInputDropdownText
                  id="reason"
                  name="reason"
                  title={refusalReason}
                  selectedValue={
                    cancelOptions?.find(
                      (item) => item.value === detailsData?.other?.defaultStatusReason
                    )?.label
                  }
                  options={cancelOptions}
                  hideError={true}
                  isDisabled={true}
                />
              ) : null}
              {detailsData?.other?.defaultStatus === DefaultRequestStatus.Declined ? (
                <FormInputDropdownText
                  id="reason"
                  name="reason"
                  title={refusalReason}
                  selectedValue={
                    declineOptions?.find(
                      (item) => item.value === detailsData?.other?.defaultStatusReason
                    )?.label
                  }
                  options={declineOptions}
                  hideError={true}
                  isDisabled={true}
                />
              ) : null}
            </div>
            <div>
              <label htmlFor="commentsProvided">{commentsTitle}</label>
              <textarea
                id="commentsProvided"
                className={styles.textArea}
                value={detailsData?.other?.defaultStatusComment}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </SideDrawer>
    </div>
  );
};

export default withFeature(FeatureFlags.INTERNAL)(DefaultDetailsInternalCardHeader);
