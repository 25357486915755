import {
  ApiClient,
  ApiClientConfig,
  DefaultDetailsResponse,
  DefaultDetailsUpdateResponse,
  DefaultRequestStatus
} from 'Foundation/Api';
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { config } from '../../../../config';
import {
  DecisioningFormModalData,
  SubmissionDecisionRequest,
  SubmissionDecisionResponse
} from './SubmissionDecisionPayload.type';

/** *
 *
 * @param cmhcDefaultAccountID Account number that is used as the UUID in the backend
 * @param authContext Authentication context that is passed in to the api calls
 * @param apiClientConfig Config for the API client
 * @constructor
 *
 * Function that handles calls for the default inventory/details
 */
export const DefaultApiCallHandler = (
  cmhcDefaultAccountID: string,
  authContext: any,
  apiClientConfig: ApiClientConfig
) => {
  const { getWithAuth, patchWithAuth, cancelTokenSource } = ApiClient(authContext, apiClientConfig);

  const baseRoute = config.defaultApi.urls.details;
  const idSpecificRoute =
    baseRoute !== undefined ? `${baseRoute + cmhcDefaultAccountID}/` : undefined;
  const decisioningRouteMulti = baseRoute !== undefined ? `${baseRoute}statuses` : undefined;
  const pathError = new Error('Invalid configuration. Could not read URL or path for API calls.');
  const masterEditUrl: string = config.defaultApi.urls.masterEditStatus;

  /** *
   * Gets the details for a specific default as specified when this handler is instantiated
   */
  const getDefaultSubmissionDetails = (
    requestConfig?: AxiosRequestConfig
  ): Promise<DefaultDetailsResponse> => {
    if (idSpecificRoute !== undefined) {
      return getWithAuth(idSpecificRoute, requestConfig)
        .then((res: AxiosResponse<DefaultDetailsResponse>) => res.data)
        .catch((e: AxiosError) => Promise.reject(e));
    }
    throw pathError;
  };

  /** *
   * Submits the change to a default request for the default submission specified on
   * @param formData Form data coming from React Hook form that will be submitted as part of payload in the call to the API
   * @param requestConfig is an optional additional config for the request
   */
  const saveDefaultSubmissionChanges = (
    formData: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<DefaultDetailsUpdateResponse> => {
    if (idSpecificRoute !== undefined) {
      return patchWithAuth(idSpecificRoute, formData, requestConfig)
        .then((res) => res.data)
        .catch((e: AxiosError) => Promise.reject(e));
    }
    throw pathError;
  };

  /** *
   * Updates the status of a submission
   * @param newStatus New Status that the submission will be updated to
   * @param formModalData data values from the form modal
   * @param requestConfig is the configuration object of the request
   * @package requestConfig optional additional request configurations
   */
  const updateSubmissionStatus = (
    newStatus: DefaultRequestStatus,
    formModalData: DecisioningFormModalData,
    requestConfig?: AxiosRequestConfig,
    cmhcDefaultAccountIDcancel?: string
  ): Promise<SubmissionDecisionResponse> => {
    const cancelDefaultRoute = cmhcDefaultAccountIDcancel
      ? baseRoute !== undefined
        ? `${baseRoute + cmhcDefaultAccountIDcancel}/`
        : undefined
      : baseRoute !== undefined
      ? `${baseRoute + cmhcDefaultAccountID}/`
      : undefined;
    const decisioningRouteCancel =
      idSpecificRoute !== undefined ? `${cancelDefaultRoute}status` : undefined;
    if (decisioningRouteCancel !== undefined) {
      const req: SubmissionDecisionRequest = {
        defaultStatus: newStatus,
        defaultStatusComment: formModalData.comment
      };

      if (formModalData.reason !== undefined && formModalData.reason !== null) {
        req.defaultStatusReason = Number(formModalData.reason);
      }

      return patchWithAuth(decisioningRouteCancel, req, requestConfig)
        .then((res: AxiosResponse<SubmissionDecisionResponse>) => res.data)
        .catch((e: AxiosError) => Promise.reject(e));
    }
    throw pathError;
  };

  const inactivateRequestMulti = (
    cmhcDefaultAccountIDs: string[],
    formModalData: DecisioningFormModalData,
    requestConfig?: AxiosRequestConfig
  ): Promise<SubmissionDecisionResponse> => {
    if (decisioningRouteMulti !== undefined) {
      const req = {
        cmhcDefaultAccountIDs,
        defaultStatus: DefaultRequestStatus.Inactive,
        defaultStatusComment: formModalData.comment
      };

      return patchWithAuth(decisioningRouteMulti, req, requestConfig)
        .then((res: AxiosResponse<SubmissionDecisionResponse>) => res.data)
        .catch((e: AxiosError) => Promise.reject(e));
    }
    throw pathError;
  };

  const masterEditStatusCall = async (params: any, body: any) => {
    const lockStatusUrl = `${masterEditUrl}/${params}`;
    try {
      const editStatus = await ApiClient(authContext, apiClientConfig).patchWithAuth(
        lockStatusUrl,
        body
      );
      return editStatus as AxiosResponse;
    } catch (err) {
      return err as AxiosError;
    }
  };

  return {
    getDefaultSubmissionDetails,
    saveDefaultSubmissionChanges,
    updateSubmissionStatus,
    inactivateRequestMulti,
    cancelTokenSource,
    masterEditStatusCall
  };
};
