import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { config } from '../../../../config';
import { SubmitDefaultReturn, DefaultSubmissionResponce } from '../../models/typeCode.types';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

const validatePath = config.loanApi.urls.validate;
const submitPath = config.defaultApi.urls.create;

const apiClientConfig: ApiClientConfig = { timeout: config.defaultApi.requestTimeout };

type ErrorEntity = {
  errorMessage?: string;
  errorCode?: any;
};

type DataObject = {
  statusCode: number;
  status?: string;
  data: any | any[];
  error: ErrorEntity;
};

type Response = {
  status?: number;
  data: DataObject;
};

export interface ErrorObject {
  response?: Response;
}

// TODO : type for data, change from any
export const submitDefaultRequest = async (
  authenticationContext: any,
  data: any,
  isBypass: boolean
): Promise<number> => {
  // Determine all incoming config values are defined.
  if (validatePath && submitPath) {
    const loanNumber = data.detail.cmhcLoanAccountNumber;
    const validateResponse = await validateLoan(authenticationContext, isBypass, loanNumber);

    if (isBypass === true || validateResponse === SubmitDefaultReturn.Success) {
      data.other.validLoan = !isBypass;
      const createResponse = await createDefault(authenticationContext, data);
      return createResponse;
    }
    return validateResponse;
  }
  return SubmitDefaultReturn.Error; // incoming config values do not exist
};

export const validateLoan = async (
  authenticationContext: any,
  isBypass: boolean,
  loanNumber: number
): Promise<number> => {
  let validate = SubmitDefaultReturn.Error;
  if (isBypass === false) {
    const url = `${validatePath}/${loanNumber}`;
    try {
      const response: AxiosResponse = await ApiClient(
        authenticationContext,
        apiClientConfig
      ).getWithAuth(url);
      if (
        response.data.data[0].cmhcAccountNumber === false ||
        response.data.data[0].accountStatusTypeCode === false
      ) {
        if (response.data.data[0].cmhcAccountNumber === false) {
          validate = SubmitDefaultReturn.InvalidLoanNumber;
        } else if (response.data.data[0].accountStatusTypeCode === false) {
          validate = SubmitDefaultReturn.InvalidLoanStatus;
        }
      } else {
        validate = SubmitDefaultReturn.Success;
      }
    } catch (err: any) {
      validate = errorCatch(err);
    }
  }
  return validate;
};

export const createDefault = async (authenticationContext: any, data: object): Promise<number> => {
  try {
    await ApiClient(authenticationContext, apiClientConfig).postWithAuth(submitPath, data);

    return SubmitDefaultReturn.Created;
  } catch (err: any) {
    return errorCatch(err);
  }
};

export const errorCatch = (err: ErrorObject): number => {
  if (err && err.response && err.response.status) {
    switch (err.response.status) {
      case 400:
        return SubmitDefaultReturn.BadRequest;
      case 401:
        return SubmitDefaultReturn.NotAuthorized;
      case 500:
        if (
          (err.response.data &&
            err.response.data.error &&
            err.response.data.error.errorCode &&
            err.response.data.error.errorCode === 'HBT_ERR_5008') ||
          err.response.data.error.errorCode === 'HBT_ERR_5040'
        ) {
          return SubmitDefaultReturn.DuplicateLoanNumber;
        }
        return SubmitDefaultReturn.Error;

      default:
        return SubmitDefaultReturn.Error;
    }
  } else {
    // err.response.status is undefined
    return SubmitDefaultReturn.Error;
  }
};

export const submitDraftDefaultRequest = async (
  authenticationContext: any,
  data: any,
  isBypass: boolean
): Promise<number> => {
  // Determine all incoming config values are defined.
  if (validatePath && submitPath) {
    const loanNumber = data.detail.cmhcLoanAccountNumber;
    const validateResponse = await validateLoan(authenticationContext, isBypass, loanNumber);

    if (isBypass === true || validateResponse === SubmitDefaultReturn.Success) {
      data.other.validLoan = !isBypass;
      const createResponse = await saveDefaultDraft(authenticationContext, data);
      return createResponse;
    }
    return validateResponse;
  }
  return SubmitDefaultReturn.Error; // incoming config values do not exist
};

export const saveDefaultDraft = async (authenticationContext: any, data: any): Promise<any> => {
  const isExternalPortal = config.app.siteType === FeatureFlags.EXTERNAL;

  if (isExternalPortal) {
    delete data.indicator;
  }
  try {
    await ApiClient(authenticationContext, apiClientConfig).postWithAuth(submitPath, data);
    return SubmitDefaultReturn.Created;
  } catch (err) {
    return errorCatch(err as ErrorObject);
  }
};
