import { withFeature } from 'flagged';
import { joiResolver } from '@hookform/resolvers/joi';
import i18n from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { pathNames } from 'Constants/pathNames';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { StringTemplate } from '@hobt/data-vis-components';
import { defaultsPortal } from '@hobt/form-schema-validators';

import { StickyFooter } from 'Components/PageComponents/StickyFooter';
import { removeEmptyFields } from 'Constants/Utils/RemoveEmptyFields';
import {
  ToastNotification,
  NotificationCard,
  NotificationCardProps
} from 'Feature/CommonComponents/ContentComponents';
import { ApplicationStates, CardType } from 'Feature/CommonComponents/Enums';
import { ProgressBar, getProgressBarData } from 'Feature/CommonComponents/ProgressBarComponents';
import { LoaderAnimation, Button, ButtonType } from 'Feature/CommonComponents/UserControls';
import {
  Assets,
  getAssetsDetailsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/Assets';
import {
  AttachmentForm,
  getAttachmentFormProps
} from 'Feature/DefaultSubmissionForm/components/Cards/AttachmentForm';
import {
  Borrower,
  getBorrowerDetailsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/Borrower';
import {
  DefaultManagementTools,
  getDefaultManagementToolsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/DefaultManagementTools';
import {
  GdsTdsCalculations,
  getGdsTdsCalculationsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/GdsTdsCalculations';
import {
  getDefaultHistoryProps,
  HistoryDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/History';
import {
  getLenderDetailsProps,
  LenderDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/LenderDetails';
import {
  getLenderAcknowledgmentProps,
  LenderAcknowledgment
} from 'Feature/DefaultSubmissionForm/components/Cards/LenderAcknowledgment';
import {
  getLiabilitiesDetailsProps,
  Liabilities
} from 'Feature/DefaultSubmissionForm/components/Cards/Liabilities';
import {
  getLoanDetailsProps,
  LoanDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/LoanDetails';
import {
  getMonitoringStrategyProps,
  MonitoringStrategy
} from 'Feature/DefaultSubmissionForm/components/Cards/MonitoringStrategy';
import {
  getMortgageDetailsProps,
  MortgageObligations
} from 'Feature/DefaultSubmissionForm/components/Cards/MortgageObligations';
import {
  getfinancialObligationsProps,
  OtherFinancialObligations
} from 'Feature/DefaultSubmissionForm/components/Cards/OtherFinancialObligations';
import {
  getReasonsDefaultManagementProps,
  ReasonsDefaultManagement
} from 'Feature/DefaultSubmissionForm/components/Cards/ReasonsDefaultManagement';
import GetErrorCount from 'Feature/DefaultSubmissionForm/components/TopErrorMessages';
import { CardCountsObject } from 'Feature/DefaultsInventory/models/DefaultInventoryDetails';
import { TargetSection } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { DefaultDetailSubmissionFormProps } from './DefaultDetailSubmissionForm.types';
import { mapDefaultDetailsSubmissionFields } from '../DefaultDetailSubmissionFormMapings';
import { ICard } from '../../../../DefaultSubmissionForm/components/Card.types';

import { useAuthenticationContext } from 'Foundation/Authentication';
import { DefaultApiCallHandler } from 'Feature/DefaultsInventory/components/api/ApiCallHandlers';
import { config } from '../../../../../config';

import styles from './styles.module.scss';
import { DefaultRequestStatus } from 'Foundation/Api';

const DefaultDetailSubmissionFormExternal: React.FC<DefaultDetailSubmissionFormProps> = ({
  detailsData,
  watchLoanValidation,
  defaultSubmissionFormProps,
  onSubmitCallback,
  activeTab,
  setDisableCardBtns
}) => {
  const [isAttachmentUploading, setIsAttachmentUploading] = useState<boolean>(false);

  const [borrowerTotalMonthlyGrossIncome, setBorrowerTotalMonthlyGrossIncome] = useState(0);
  const [totalMonthlyHouseholdHousingExpenses, setTotalMonthlyHouseholdHousingExpenses] =
    useState(0);
  const [totalMonthlyHouseholdUnsecuredDebt, setTotalMonthlyHouseholdUnsecuredDebt] = useState(0);
  const [totalAssetsArray, setTotalAssetsArray] = useState([0]);
  const [totalLiabilitiesArray, setTotalLiabilitiesArray] = useState([0]);
  const [cumulativeTotalAssets, setCumulativeTotalAssets] = useState(0);
  const [cumulativeTotalLiabilities, setCumulativeTotalLiabilities] = useState(0);
  const [bypassIsDirty, setBypassIsDirty] = useState<boolean>(false);

  const [totalFormErrorCount, setTotalFormErrorCount] = useState(0);
  const [formHasError, setFormHasError] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState<string[]>([]);
  const [editStatusData, setEditStatusData] = React.useState({
    isBeingEdited: false,
    isBeingEditedByUserID: ''
  });
  const [continueSession, setContinueSession] = useState<boolean>(false);

  const history = useHistory();
  const errorNotifications: NotificationCardProps = {
    title: StringTemplate(
      i18n.t('DefaultsInventoryDetails-NotificationCard-ValidationErrStrTemplate'),
      '{{}}',
      String(1)
    ),
    message: [i18n.t('DefaultsInventoryDetails-NotificationCard-ValidationErrorMessage')],
    notificationType: CardType.VERTICAL_WARNING,
    formWarningIcon: defaultSubmissionFormProps.fields.formWarningIcon
  };

  const draftDefaultID = detailsData?.cmhcDefaultAccountID;
  const parsedUUID = draftDefaultID !== null ? draftDefaultID : '';
  const authContext = useAuthenticationContext();

  const { masterEditStatusCall } = DefaultApiCallHandler(parsedUUID, authContext, {
    timeout: config.defaultApi.requestTimeout
  });
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const [showEditInProgressToast, setShowEditInProgressToast] = useState<any | boolean>(true);
  const [isDraftTab, setIsDraftTab] = useState<boolean>(false);

  const [showSaveDraftButton, setShowSaveDraftButton] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(true);
  const [sessionTimeout, setSessionTimeout] = useState<boolean>(false);
  const [isCurrentUserLocking, setIsCurrentUserLocking] = React.useState<boolean>(false);

  const loggedInUserID: string = sitecoreContext?.user?.userID;

  const isDraftLocked =
    detailsData?.editingStatus !== undefined &&
    detailsData?.editingStatus?.isBeingEdited == true &&
    loggedInUserID !== detailsData?.editingStatus?.isBeingEditedByUserID;
  // pre-populated data cardCounts:
  const [cardCounts, setCardCounts] = useState<CardCountsObject>({});

  // Creating card referance of use ref and send to progress bar
  const cardReference = useRef(
    Array.from({ length: 20 }, () => React.createRef() as React.MutableRefObject<HTMLSpanElement>)
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    getValues,
    reset,
    formState,
    trigger
  } = useForm({
    resolver: joiResolver(defaultsPortal),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const commonCardProps: ICard = {
    register,
    errors,
    setValueHandler: setValue,
    control,
    watch,
    getValues,
    reset,
    trigger
  };
  let isDraftSubmit = false;
  // Form Handling
  const onSubmit = (formData: any) => {
    const strippedValues = removeEmptyFields(formData);
    isDraftSubmit
      ? (strippedValues.other.defaultStatus = DefaultRequestStatus.InDraft)
      : (strippedValues.other.defaultStatus = DefaultRequestStatus.PendingCmhcReview);
    setFormHasError(false);
    onSubmitCallback(false, strippedValues);
  };

  const onError = () => {
    setFormHasError(true);
    setTotalFormErrorCount(document.querySelectorAll('[class^=thisFieldHasError]').length);
    setFormErrorMessages(GetErrorCount);
  };

  const validLoanWatch = watch('other.validLoan');

  // return number of pre-populated cards, if exists
  const prepopulatedLength = (name: string): number | undefined => {
    return detailsData[name]?.length;
  };

  const prepopToolsLength = () => {
    const stageThreeDataObj = detailsData.managementInformation.submittedStageThree;
    return (
      stageThreeDataObj.combinationFlag &&
      stageThreeDataObj.toolDetail &&
      stageThreeDataObj.toolDetail.length
    );
  };

  const resetData = () => {
    const mappedData = mapDefaultDetailsSubmissionFields(detailsData);

    reset(mappedData);

    register('other.defaultStatus');
    register('other.homeownerLoan');
    register('other.validLoan');

    if (detailsData.other.defaultStatusComment !== undefined) {
      register('other.defaultStatusComment');
    }

    if (detailsData.other.defaultStatusReason !== undefined) {
      register('other.defaultStatusReason');
    }
  };

  const formErrorsLength = Object.keys(formState.errors).length;

  useEffect(() => {
    watchLoanValidation(validLoanWatch);
  }, [validLoanWatch]);

  useEffect(() => {
    // functions to set pre-populated data card counts:
    setCardCounts({
      asset: prepopulatedLength(`asset`),
      borrower: prepopulatedLength(`borrower`),
      liability: prepopulatedLength(`liability`),
      mortgageObligation: prepopulatedLength(`mortgageObligation`),
      financialObligation: prepopulatedLength(`financialObligation`),
      stageThreeTools: prepopToolsLength()
    });

    resetData();
  }, [detailsData]);

  useEffect(() => {
    return function cleanup() {
      if (isServer() === false) {
        // clean up onbeforeunload, on component unmount
        window.onbeforeunload = null;
      }
    };
  }, []);
  const onDiscardChanges = (): void => {
    history.push(`/${i18n.language}${pathNames.dashboard}`);
  };
  // isDirty check
  const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if ((isDirtyAlt || isAttachmentUploading) && bypassIsDirty === false) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }

        return null;
      };
    }

    setDisableCardBtns(formState.isDirty || formErrorsLength > 0 || formHasError);
  }, [isDirtyAlt, formErrorsLength, bypassIsDirty]);

  useEffect(() => {
    const defaultStatus = getValues()?.other?.defaultStatus;
    if (defaultStatus === DefaultRequestStatus.InDraft) setIsDraftTab(true);
  }, []);

  const primaryButtonText = isDraftTab
    ? i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')
    : i18n.t('DefaultSubmission-Card-ProgressBarSaveButton');

  const handleClose = () => {
    setShowEditInProgressToast(false);
  };

  const handleOnEditLock = (isBeingEdited: boolean) => {
    let EditingStatus = {
      isBeingEdited
    };
    masterEditStatusCall(parsedUUID, EditingStatus)
      .then((res: any) => {
        setEditStatusData(res.data.data[0].editingStatus);
      })
      .catch((error: any) => {
        console.error('Error occurred while making API call:', error);
      });
  };

  // making lock api call when component mounts in non master users
  React.useEffect((): any => {
    if (isDraftTab && !isDraftLocked) {
      handleOnEditLock(true);
      return () => {
        handleOnEditLock(false);
      };
    }
  }, [isDraftTab, isDraftLocked]);

  // Unlock draft when browser is closed
  React.useEffect((): any => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();

      if (conditionForUnlock) handleOnEditLock(false);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up the event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Unlock draft if current tab is closed
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (conditionForUnlock) handleOnEditLock(false);
      event.preventDefault();
      event.returnValue = '';
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const conditionForUnlock = React.useMemo(() => {
    return (
      detailsData?.editingStatus?.isBeingEdited === true &&
      loggedInUserID === detailsData?.editingStatus?.isBeingEditedByUserID
    );
  }, [detailsData, loggedInUserID]);

  React.useEffect(() => {
    if (
      editStatusData.isBeingEdited === true &&
      loggedInUserID === editStatusData.isBeingEditedByUserID
    )
      setIsCurrentUserLocking(true);
  }, [editStatusData]);

  // Custom event listener for session timeout
  React.useEffect(() => {
    const handleEvent = (e: any) => {
      setSessionTimeout(e?.detail?.sessionTimeOut);
      setContinueSession(e?.detail?.continueSession);
    };
    window.addEventListener('logoutEvent', handleEvent);
    return () => {
      window.removeEventListener('logoutEvent', handleEvent);
    };
  }, []);

  // Releasing lock when session times out
  React.useEffect(() => {
    if (isCurrentUserLocking) {
      if (sessionTimeout) {
        handleOnEditLock(false);
      } else if (continueSession) {
        handleOnEditLock(true);
      }
    }
  }, [sessionTimeout, isCurrentUserLocking]);

  // Lock API unmount call
  React.useEffect(() => {
    if (isDraftTab && conditionForUnlock) {
      return () => {
        handleOnEditLock(false);
      };
    }
  }, [conditionForUnlock, isDraftTab]);

  // Log out dispatch event
  const dispatchDefaultEvent = (editStatusData: any) => {
    const event = new CustomEvent('defaultEvent', {
      detail: {
        draftDefaultID,
        editingStatus: editStatusData
      }
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    dispatchDefaultEvent(editStatusData);
    return () => {
      dispatchDefaultEvent(editStatusData);
    };
  }, [editStatusData]);

  const handleSaveDraft = () => {
    isDraftSubmit = true;
    onSubmit(getValues());
  };

  if (Object.keys(cardCounts).length > 0) {
    return (
      <>
        {/* React Router prompt on route navigations */}
        <Prompt when={isDirtyAlt} message={i18n.t('DefaultSubmission-IsDirty')} />
        {isDraftTab && isDraftLocked && (
          <ToastNotification
            type={ApplicationStates.CANCEL}
            isActive={showEditInProgressToast}
            title={i18n.t('DefaultSubmission-MasterEditToast-Title')}
            content={{
              value: i18n.t('DefaultSubmission-MasterEditToast-Content')
            }}
            onCloseCallback={handleClose}
          />
        )}
        <form
          id="defaultInventoryDetailsForm"
          name="default-inventory-details-form"
          className="form default-submission-form"
          onSubmit={handleSubmit(onSubmit, onError)}
          noValidate
        >
          <div className={styles.layoutContainer}>
            <div>
              <fieldset disabled={isDraftTab ? isDraftLocked : false}>
                {/* Loan validation warning */}
                {errorNotifications && !validLoanWatch && (
                  <NotificationCard {...errorNotifications} />
                )}

                {/* Card level error */}
                {formHasError && formErrorMessages.length >= 0 ? (
                  <NotificationCard
                    notificationType={CardType.VERTICAL_ERROR}
                    title={i18n
                      .t('DefaultSubmission-Card-TopErrorMessage')
                      .replace('{count}', totalFormErrorCount.toString())}
                    message={formErrorMessages}
                    formWarningIcon={defaultSubmissionFormProps.fields.formWarningIcon}
                  />
                ) : (
                  ''
                )}

                {/* Cards */}
                <span ref={cardReference.current[0]} />
                <LenderDetails
                  {...getLenderDetailsProps()}
                  {...commonCardProps}
                  id="lenderDetails"
                  activeTab={activeTab}
                />

                <span ref={cardReference.current[1]} />
                <LoanDetails
                  {...getLoanDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="loanDetails"
                  disableCalendar={true}
                />

                <span ref={cardReference.current[2]} />
                <Borrower
                  {...getBorrowerDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  cardId="borrowerDetails"
                  cardCount={cardCounts.borrower}
                  borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                  setBorrowerTotalMonthlyGrossIncome={setBorrowerTotalMonthlyGrossIncome}
                />

                <span ref={cardReference.current[3]} />
                <Assets
                  {...getAssetsDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  cardId="assets"
                  cardCount={cardCounts.asset}
                  totalAssetsArray={totalAssetsArray}
                  setTotalAssetsArray={setTotalAssetsArray}
                  cumulativeTotalAssets={cumulativeTotalAssets}
                  setCumulativeTotalAssets={setCumulativeTotalAssets}
                />

                <span ref={cardReference.current[4]} />
                <Liabilities
                  {...getLiabilitiesDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="liabilities"
                  cardCount={cardCounts.liability}
                  totalLiabilitiesArray={totalLiabilitiesArray}
                  setTotalLiabilitiesArray={setTotalLiabilitiesArray}
                  cumulativeTotalLiabilities={cumulativeTotalLiabilities}
                  setCumulativeTotalLiabilities={setCumulativeTotalLiabilities}
                  cumulativeTotalAssets={cumulativeTotalAssets}
                />

                <span ref={cardReference.current[5]} />
                <MortgageObligations
                  {...getMortgageDetailsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="mortgageObligations"
                  cardCount={cardCounts.mortgageObligation}
                />

                <span ref={cardReference.current[6]} />
                <GdsTdsCalculations
                  {...getGdsTdsCalculationsProps()}
                  {...commonCardProps}
                  id="gdsTdsCalulations"
                  borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                  totalMonthlyHouseholdHousingExpenses={totalMonthlyHouseholdHousingExpenses}
                  setTotalMonthlyHouseholdHousingExpenses={setTotalMonthlyHouseholdHousingExpenses}
                  totalMonthlyHouseholdUnsecuredDebt={totalMonthlyHouseholdUnsecuredDebt}
                  setTotalMonthlyHouseholdUnsecuredDebt={setTotalMonthlyHouseholdUnsecuredDebt}
                />

                <span ref={cardReference.current[7]} />
                <OtherFinancialObligations
                  {...getfinancialObligationsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="otherFinancialObligations"
                  cardCount={cardCounts.financialObligation}
                />

                <span ref={cardReference.current[8]} />
                <ReasonsDefaultManagement
                  {...getReasonsDefaultManagementProps()}
                  {...commonCardProps}
                  id="reasonsDefaultManagement"
                  maxLength={Number(i18n.t('Globals-MultilineText-MaxLength'))}
                  disableCalendar={true}
                  hideLimit={true}
                />

                <span ref={cardReference.current[9]} />
                <DefaultManagementTools
                  {...getDefaultManagementToolsProps(defaultSubmissionFormProps)}
                  {...commonCardProps}
                  id="defaultManagementToolsInventoryDetailsTab"
                  title={{
                    field: {
                      value: i18n.t('DefaultSubmission-Card-DefaultManagementToolsHeading')
                    }
                  }}
                  cardCount={cardCounts.stageThreeTools}
                  targetSection={TargetSection.DEFAULTSUBMISSION}
                  linedCardProps={{
                    id: 'defaultManagementToolsInventoryDetailsTab',
                    testId: 'defaultManagementToolsInventoryDetailsTab',
                    linePosition: 'vertical',
                    lineColor: 'grey'
                  }}
                  disableCalendar={true}
                />

                <span ref={cardReference.current[10]} />
                <LenderAcknowledgment
                  {...getLenderAcknowledgmentProps()}
                  {...commonCardProps}
                  id="lenderAcknowledgement"
                  hideLimit={true}
                />

                <span ref={cardReference.current[11]} />
                <MonitoringStrategy
                  {...getMonitoringStrategyProps()}
                  {...commonCardProps}
                  id="monitoringStrategy"
                  hideLimit={true}
                />

                <span ref={cardReference.current[12]} />
                <HistoryDetails
                  {...getDefaultHistoryProps()}
                  {...commonCardProps}
                  id="defaultHistory"
                  hideLimit={true}
                />
              </fieldset>
              <span ref={cardReference.current[13]} />
              <AttachmentForm
                {...getAttachmentFormProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="attachments"
                numLoadedAttachments={detailsData.attachment ? detailsData.attachment.length : 0}
                indicateAttachmentUploadStatus={(status: boolean) => {
                  setIsAttachmentUploading(status);
                }}
                downloadMode={
                  detailsData.attachment != null && detailsData.attachment?.length !== 0
                }
                onDownloadCallback={() => {
                  setBypassIsDirty(true);
                }}
                attachments={detailsData.attachment}
              />
            </div>
            <aside className={styles.showForDesktopOnly}>
              <div className={`form-progress__sticky`} id="progressBarDetail">
                {isServer() === false && activeTab === TargetSection.DEFAULTSUBMISSION ? (
                  // ProgressBar calls window object, therefore an isServer() check is required prior to rendering
                  <ProgressBar
                    {...getProgressBarData(cardReference.current)}
                    onCancelHandler={() => onDiscardChanges()}
                    onSubmitHandler={() => handleSubmit(onSubmit, onError)}
                    primaryButtonText={{
                      value: primaryButtonText
                    }}
                    submitButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSubmitButton')
                    }}
                    cancelButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarDiscardChangesButton')
                    }}
                    saveDraftButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSaveDraftButton')
                    }}
                    onSaveDraftHandler={() => handleSaveDraft()}
                    isDraftTab={isDraftTab}
                    isDraftLocked={isDraftLocked}
                  />
                ) : (
                  ''
                )}
              </div>
            </aside>
          </div>
        </form>
      </>
    );
  }
  return <LoaderAnimation />;
};

export default withFeature(FeatureFlags.EXTERNAL)(DefaultDetailSubmissionFormExternal);
