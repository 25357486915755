import { HttpResponseStatusCodes } from '@hobt/constants';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../../../config';
import { DraftDefaultResponseData } from './types';

export const useDraftDefaultClient = (
  authContext: AuthenticationContextType,
  apiClientConfig: ApiClientConfig
) => {
  const getDraftDefault = async (
    draftDefaultID: string
  ): Promise<DraftDefaultResponseData | any> => {
    const requestRoute = `${config.defaultApi.urls.draftDefault}/${draftDefaultID}/`;

    const response = await ApiClient(authContext, apiClientConfig).getWithAuth(requestRoute);

    const { claimPayees, draftClaim, lenderName } = response?.data?.data?.[0];

    return { claimPayees, ...draftClaim, lenderName };
  };

  const deleteDraftDefault = async (draftDefaultID: string): Promise<boolean> => {
    const requestRoute = `${config.defaultApi.urls.draftDefault}/${draftDefaultID}/`;

    const response = await ApiClient(authContext, apiClientConfig).deleteWithAuth(requestRoute);

    return response?.data?.statusCode === HttpResponseStatusCodes.OK;
  };

  return { deleteDraftDefault, getDraftDefault };
};
