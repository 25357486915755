/* eslint-disable */
import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFeature } from 'flagged';

import { AccountStatus } from '@hobt/constants';
import { UserAndAccesses } from '@hobt/user-domain';

import {
  ModifyUserActionType,
  ModifyUserAction,
} from 'Feature/UserManagement/components/ModifyUser/types';
import { ApiClient } from 'Foundation/Api';
import { pathNames } from 'Constants/pathNames';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { config } from '../../../../config';
import { preProcessFormData } from '../api/UserApiHandler.service';

export const useUserApiFunctions = (
  dispatch: React.Dispatch<ModifyUserAction>,
  push: Function,
  authenticationContext: any,
  userId?: string,
  sitecoreContext?:any
) => {
  const [userData, setUserData] = useState<UserAndAccesses | null>(null);
  let [formPayload, setFormPayload] = useState<any>();

  const cancelTokenSource = axios.CancelToken.source();
  const siteTypeIsExternal = useFeature(FeatureFlags.EXTERNAL);

  const { getWithAuth, putWithAuth } = ApiClient(authenticationContext, {
    timeout: config.userApi.requestTimeout,
  });

  const getUserUrl: string = config.userApi.urls.getUser;

  const onError = (err: any) => {
    console.log('User Details Err:', err);
    console.log('User Details Data', userData);
    dispatch({
      action: ModifyUserActionType.ON_ERROR,
      payload: {
        function: () => {
          dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
        },
      },
    });
  };

  const getUserData = async (): Promise<void> => {
    try {
      const { data: responseData } = await getWithAuth(
        `${getUserUrl}?id=${userId}&language=${i18n.language}`,
        {
          cancelToken: cancelTokenSource.token
        }
      );
      setUserData(responseData?.data?.[0]);
    } catch (e) {
      onError(e);
    }
  };

  const userUpdateApi = async () => {
    
    try {
      dispatch({ action: ModifyUserActionType.SET_IS_LOADING, payload: { value: true } });
      const payloadData = { ...formPayload };
      const payload = preProcessFormData(payloadData,sitecoreContext);

      const { data: responseData } = await putWithAuth(
        `${config.userApi.urls.modify}`,
        payload,
        {
          cancelToken: cancelTokenSource.token,
        }
      );
      setUserData(responseData?.data?.[0]);
      dispatch({
        action: ModifyUserActionType.SUBMIT_SUCCESS,
        payload: {
          function: () => {
            dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
            push(`/${i18n.language}${pathNames.userInventory}`);
          },
        },
      });
    } catch (e) {
      onError(e);
    }
  };

  const onUserUpdate = async () => {
    dispatch({ action: ModifyUserActionType.DISMISS_UPDATE_MODAL });
    userUpdateApi();
  };

  const onSubmit = async (formData: any) => {
    formPayload = formData;
    setFormPayload(formData);
    if (siteTypeIsExternal) {
      dispatch({ action: ModifyUserActionType.SHOW_UPDATE_MODAL });
    } else {
      userUpdateApi();
    }
  };

  const onUserDelete = async () => {
    const payload = {
      statusCode: AccountStatus.DISABLED.toString(10),
      emailID: userData?.emailID,
    };

    try {
      dispatch({
        action: ModifyUserActionType.SET_IS_MODAL_LOADING,
        payload: { value: true },
      });
      await putWithAuth(`${config.userApi.urls.delete}`, payload, {
        cancelToken: cancelTokenSource.token,
      });
      dispatch({
        action: ModifyUserActionType.SET_IS_MODAL_LOADING,
        payload: { value: false },
      });
      dispatch({
        action: ModifyUserActionType.SUBMIT_SUCCESS,
        payload: {
          function: () => {
            dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
            push(`/${i18n.language}${pathNames.userInventory}`);
          },
        },
      });
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    if (userId != null) {
      dispatch({
        action: ModifyUserActionType.SET_IS_PAGE_LOADING,
        payload: { value: true },
      });
      getUserData().then((_res) => {
        dispatch({
          action: ModifyUserActionType.SET_IS_PAGE_LOADING,
          payload: { value: false },
        });
      });
    }
    if (userId == null) {
      onError(userId);
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  return {
    userData,
    onSubmit,
    onUserDelete,
    onError,
    onUserUpdate,
    userUpdateApi
  };
};
