import React, { PropsWithChildren, useRef, useEffect } from 'react';

import { TableShiftButton } from 'Components/Common/DataTable/TableShiftButton';
import { useDataTableFunctions } from 'Components/Common/DataTable/useDataTableFunctions';

import { TableProps } from './Table.types';
import { TableHeader, TableRow, TableRowData } from '..';

export const Table: React.FC<TableProps> = ({
  name,
  hideTableShiftBtnsForDesktop = false,
  scrollOnHorizontalOverflow,
  tableHeaderColumns,
  tableRows,
  title,
  tableCaption,
  onHeaderColumnSort,
  onRowKeyDown,
  onRowClick,
  onRowSelect,
  onCancelClick,
  onInactiveClick,
  onDeleteClick
}: PropsWithChildren<TableProps>) => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);

  const { scrollLeft, scrollRight, updateScrollButtons, showShiftLeft, showShiftRight } =
    useDataTableFunctions(scrollContainer);

  useEffect(() => {
    updateScrollButtons();
  }, []);

  const table = (
    <table className="data-table" tabIndex={0}>
      {tableCaption != null && <caption className="sr-only">{tableCaption}</caption>}

      <thead tabIndex={0}>
        <TableHeader columns={tableHeaderColumns} onSortHandle={onHeaderColumnSort} />
      </thead>

      <tbody>
        {tableRows.map((row: TableRowData) => (
          <TableRow
            key={`row-${row.uuid}`}
            row={row}
            onSelectHandler={onRowSelect}
            onClickHandler={onRowClick}
            onRowKeyDown={onRowKeyDown}
            title={title}
            onCancelClick={onCancelClick}
            onInactiveClick={onInactiveClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </tbody>
    </table>
  );

  return scrollOnHorizontalOverflow === true ? (
    <>
      <div className="h-scroll-wrapper">
        <div
          id={`${name}ScrollContainer`}
          ref={scrollContainer}
          className="h-scroll-container"
          onScroll={updateScrollButtons}
          data-testid={`${name}ScrollContainer`}
        >
          {table}
        </div>
        <TableShiftButton
          onClickCallback={scrollRight}
          isVisible={showShiftRight}
          hideForDesktop={hideTableShiftBtnsForDesktop}
          ariaControls={`${name}ScrollContainer`}
        />
        <TableShiftButton
          shiftLeft
          onClickCallback={scrollLeft}
          isVisible={showShiftLeft}
          hideForDesktop={hideTableShiftBtnsForDesktop}
          ariaControls={`${name}ScrollContainer`}
        />
      </div>
    </>
  ) : (
    <div className="data-table__wrapper" id="filterContent">
      {table}
    </div>
  );
};
