import dotenv from 'dotenv';

dotenv.config();

export const config = {
  // Configurations that affect the entire application
  app: {
    applicationInsightKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
    internalOriginUrl: process.env.REACT_APP_INTERNAL_ORIGIN,
    sessionLengthInSeconds: process.env.REACT_APP_SESSION_LENGTH_IN_SECONDS,
    sessionTimeoutModalCountdownLengthInSeconds:
      process.env.REACT_APP_SESSION_TIMEOUT_MODAL_COUNTDOWN_LENGTH_IN_SECONDS,
    siteType: process.env.REACT_APP_SITE_TYPE,
    appName: `hbt-${process.env.REACT_APP_SITE_TYPE?.toLocaleLowerCase()}-portal`
  },

  // Authentication specific configurations
  auth: {
    activeDirectory: {
      clientId: process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID,
      authority: process.env.REACT_APP_ACTIVE_DIRECTORY_AUTHORITY
    },
    validateTermsAndConditionsAcceptance:
      process.env.REACT_APP_VALIDATE_TERMS_AND_CONDITIONS_ACCEPTANCE === 'YES',
    validateAuthenticatedUsersOnSecureRoutes:
      process.env.REACT_APP_VALIDATE_AUTHENTICATED_USERS_ON_SECURE_ROUTES === 'YES'
  },

  // Configurations that affect all APIs
  api: {
    hbtApiScope: process.env.REACT_APP_HBT_API_SCOPE
  },

  // Configurations specific to interactions with the notifications API
  notificationApi: {
    requestTimeout: Number(process.env.REACT_APP_NOTIFICATION_API_REQUEST_TIMEOUT) || 2000,
    pollingTimeout:
      Number(process.env.REACT_APP_NOTIFICATION_API_REQUEST_POLLING_INTERVAL) || 30000,
    urls: {
      notification: process.env.REACT_APP_NOTIFICATION_API_URL || ''
    }
  },

  // Configurations specific to interactions with the defaults API
  defaultApi: {
    requestTimeout: Number(process.env.REACT_APP_DEFAULTS_API_REQUEST_TIMEOUT) || 2000,
    urls: {
      details:
        (process.env.REACT_APP_DEFAULTS_API_URL || '') +
        (process.env.REACT_APP_DEFAULTS_API_PATH_DETAILS || ''),
      create:
        (process.env.REACT_APP_DEFAULTS_API_URL || '') +
        (process.env.REACT_APP_DEFAULTS_API_PATH_CREATE || ''),
      counts:
        (process.env.REACT_APP_DEFAULTS_API_URL || '') +
        (process.env.REACT_APP_DEFAULTS_API_PATH_COUNTS || ''),
      inventory:
        (process.env.REACT_APP_DEFAULTS_API_URL || '') +
        (process.env.REACT_APP_DEFAULTS_API_PATH_INVENTORY || ''),
      draftDefault:
        (process.env.REACT_APP_DEFAULTS_API_URL || '') +
        process.env.REACT_APP_DEFAULTS_API_PATH_DRAFT_CLAIM,
      masterEditStatus:
        (process.env.REACT_APP_DEFAULTS_API_URL || '') +
        (process.env.REACT_APP_DEFAULTS_API_PATH_EDITING_STATUS || '')
    }
  },

  // Configurations specific to interactions with the document API
  documentApi: {
    requestTimeout: Number(process.env.REACT_APP_DOCUMENT_API_REQUEST_TIMEOUT) || 2000,
    maxFileSize: process.env.REACT_APP_DOCUMENT_API_MAX_FILE_SIZE_IN_BYTES,
    maxDescriptionLength: process.env.REACT_APP_DOCUMENT_API_MAX_DESCRIPTION_LENGTH_IN_CHARS,
    urls: {
      base: process.env.REACT_APP_DOCUMENT_API_URL || '',
      upload:
        (process.env.REACT_APP_DOCUMENT_API_URL || '') +
        (process.env.REACT_APP_DOCUMENT_API_PATH_UPLOAD || ''),
      download:
        (process.env.REACT_APP_DOCUMENT_API_URL || '') +
        (process.env.REACT_APP_DOCUMENT_API_PATH_DOWNLOAD || ''),
      get:
        (process.env.REACT_APP_DOCUMENT_API_URL || '') +
        (process.env.REACT_APP_DOCUMENT_API_PATH_GET || '')
    }
  },

  // Configurations specific to interactions with the loan API
  loanApi: {
    urls: {
      validate:
        (process.env.REACT_APP_LOAN_API_URL || '') +
        (process.env.REACT_APP_LOAN_API_PATH_VALIDATE || '')
    }
  },

  // Configurations specific to interactions with the users API
  userApi: {
    requestTimeout: Number(process.env.REACT_APP_USER_API_REQUEST_TIMEOUT) || 5000,
    urls: {
      getUser: process.env.REACT_APP_USER_API_URL || '',
      // conatain the path to get all the users to choose from when delegating
      getTermsOfUseDisplayFlag:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_GET_TERMS_OF_USE_DISPLAY_FLAG_API_PATH || ''),
      getUsersToDelegate:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_FOR_DELEGATION_OPTIONS || ''),
      details:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_DETAILS || ''),
      download:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_DOWNLOAD || ''),
      downloadUserReport:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_DOWNLOAD_USER_REPORT || ''),
      inventory:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_INVENTORY || ''),
      externalInventory:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_INVENTORY_EXTERNAL || ''),
      create:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_ADD_USER_API_PATH_CREATE || ''),
      modify:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_MODIFY_USER_API_PATH_UPDATE || ''),
      claimAdjudicators:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_CLAIM_ADJUDICATORS || ''),
      delete:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_MODIFY_USER_API_PATH_DELETE || ''),
      upload:
        (process.env.REACT_APP_USER_API_URL || '') + process.env.REACT_APP_USER_API_PATH_UPLOAD,
      downloadValidationReport:
        (process.env.REACT_APP_USER_API_URL || '') +
        process.env.REACT_APP_USER_API_DOWNLOAD_VALIDATION,
      resyncUser:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_RESYNC || ''),
      reinviteUser:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_REINVITE || ''),
      positionTitles:
        (process.env.REACT_APP_USER_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_JOB_TITLES || '')
    }
  },

  // Configurations specific to interactions with the delegate API
  delegateApi: {
    requestTimeout: Number(process.env.REACT_APP_DELEGATE_API_REQUEST_TIMEOUT) || 5000,
    urls: {
      getDelegation: process.env.REACT_APP_DELEGATE_API_URL,
      inventory:
        (process.env.REACT_APP_DELEGATE_API_URL || '') +
        (process.env.REACT_APP_DELEGATE_API_PATH_INVENTORY || ''),
      create:
        (process.env.REACT_APP_DELEGATE_API_URL || '') +
        (process.env.REACT_APP_DELEGATE_API_PATH_CREATE || ''),
      modify:
        (process.env.REACT_APP_DELEGATE_API_URL || '') +
        (process.env.REACT_APP_DELEGATE_API_PATH_UPDATE || '')
    }
  },

  // Configurations specific to interactions with the audit API
  auditApi: {
    requestTimeout: Number(process.env.REACT_APP_AUDIT_API_REQUEST_TIMEOUT) || 5000,
    urls: {
      updateLoginTimestamp:
        (process.env.REACT_APP_AUDIT_API_URL || '') +
        (process.env.REACT_APP_UPDATE_LOGIN_TIMESTAMP_API_PATH || ''),
      updateLogoutTimestamp:
        (process.env.REACT_APP_AUDIT_API_URL || '') +
        (process.env.REACT_APP_UPDATE_LOGOUT_TIMESTAMP_API_PATH || ''),
      updateTermsOfUseTimestamp:
        (process.env.REACT_APP_AUDIT_API_URL || '') +
        (process.env.REACT_APP_UPDATE_TERMS_OF_USE_TIMESTAMP_API_PATH || '')
    }
  },

  // Configurations specific to interactions with the claim API
  claimApi: {
    requestTimeout: Number(process.env.REACT_APP_USER_API_REQUEST_TIMEOUT) || 5000,
    sessionTimeoutClaimInventory:
      Number(process.env.REACT_APP_AXIOS_CALL_TIMEOUT_VARIABLE_MILLISECONDS) || 30000,
    urls: {
      validate:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_USER_API_PATH_CREATE || ''),
      submit:
        (process.env.REACT_APP_CLAIM_API_URL || '') + process.env.REACT_APP_CLAIM_API_PATH_SUBMIT,
      inventory: (process.env.REACT_APP_CLAIM_API_URL || '') + process.env.REACT_APP_CLAIM_API_PATH,
      draftClaim:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_DRAFT_CLAIM,
      finalClaim:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM,
      finalClaimApprove:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM_APPROVE,
      finalClaimComments:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM_COMMENT,
      finalClaimGetComments:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM_GET_COMMENT,
      statusUpdate:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM_STATUS_UPDATE,
      getApprovals:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_PATH_GET_APPROVALS || ''),
      getApprovalsCounts:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIMS_APPROVALS_API_PATH_COUNTS || ''),
      autoAdjudicate:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM_AUTO_ADJUDICATE,
      claimInventoryCounts:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_CLAIM_INVENTORY_COUNTS,
      previewCalculations:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM +
        process.env.REACT_APP_CLAIM_API_PATH_FINAL_CLAIM_PREVIEW_CALCULATIONS,
      correspondences:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CORRESPONDENCE_API_PATH_CRUD,
      refundPrefill:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_REFUND_PREFILL,
      refund: (process.env.REACT_APP_CLAIM_API_URL || '') + process.env.REACT_APP_CLAIM_API_REFUND,
      refundAdjustment:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        process.env.REACT_APP_CLAIM_API_REFUND_ADJUSTMENTS,
      cancelClaim:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_CANCEL || ''),
      declineClaim:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_DECLINE || ''),
      assign:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_PATH_ASSIGN_CLAIM || ''),
      upload:
        (process.env.REACT_APP_CLAIM_API_URL || '') + process.env.REACT_APP_CLAIM_API_PATH_UPLOAD,
      claimAdjudicators:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_CLAIM_ADJUDICATORS || ''),
      underwritingLoanStatus:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_UNDERWRITING_LOAN_STATUS || ''),
      removeClaimDocument:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_REMOVE_CLAIM_DOCUMENT || ''),
      getFiTransitDetails:
        (process.env.REACT_APP_CLAIM_API_URL || '') + (process.env.REACT_APP_CLAIM_API_PATH || ''),
      deleteAttachements:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_REMOVE_ATTACHMENTS || ''),
      // masterEditStatus:
      editingStatus:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_PATH_EDITING_STATUS || '')
    }
  },

  // Pre Claims ITM
  preClaimItmApi: {
    urls: {
      submit:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_PRE_CLAIM_API_PATH_CREATE || ''),
      inventory:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_PRE_CLAIM_API_PATH_INVENTORY || ''),
      updateITMStatus:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_PRE_CLAIM_API_PATH_STATUS || ''),
      update:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_PRE_CLAIM_API_PATH_UPDATE || ''),
      delete:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_PRE_CLAIM_API_PATH_DELETE || '')
    }
  },

  // Pre Claims Property Repairs
  preClaimPropertyRepairsApi: {
    urls: {
      inventory:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_PROPERTY_REPAIR_INVENTORY || ''),
      submit:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_PROPERTY_REPAIR_CREATE || ''),
      update:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_PROPERTY_REPAIR_UPDATE || '')
    }
  },

  // Pre Claims Underwriting Review
  preClaimUnderwritingReviewApi: {
    urls: {
      inventory:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_UNDERWRITING_REVIEW_INVENTORY || ''),
      submit:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_UNDERWRITING_REVIEW_CREATE || ''),
      delete:
        (process.env.REACT_APP_CLAIM_API_URL || '') +
        (process.env.REACT_APP_CLAIM_API_UNDERWRITING_REVIEW_DELETE || '')
    }
  },

  // Default Requests API
  defaultRequestsApi: {
    urls: {
      latest:
        (process.env.REACT_APP_DEFAULT_REQUESTS_API_URL || '') +
        process.env.REACT_APP_DEFAULT_REQUESTS_API_PATH_LATEST
    }
  },

  // Configurations specific to interactions with the arrears API
  arrearsApi: {
    requestTimeout: Number(process.env.REACT_APP_ARREARS_API_REQUEST_TIMEOUT) || 5000,
    urls: {
      arrears: process.env.REACT_APP_ARREARS_API_URL || '',
      submission:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        process.env.REACT_APP_ARREARS_API_PATH_SUBMISSION_CRUD,
      upload:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        process.env.REACT_APP_ARREARS_API_PATH_UPLOAD,
      claim:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_CLAIM || ''),
      replace:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_REPLACE_FILE || ''),
      sendApproval:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_SEND_APPROVAL || ''),
      getApprovals:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_GET_APPROVALS || ''),
      getApprovalsCounts:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_APPROVALS_API_PATH_COUNTS || ''),
      files:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_GET_FILES || ''),
      inventory:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_INVENTORY || ''),
      fileList:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_ARREARSFILE_INVENTORY || ''),
      updateStatus:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_UPDATE_STATUS || ''),
      validationResult:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_ERRORRECORD_INVENTORY || ''),
      reAssessFile:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_ARREARSFILE_REASSESSS || ''),
      summaryReports:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_SUMMARYREPORTS_INVENTORY || ''),
      summaryReportDownload:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_SUMMARYREPORTS_DOWNLOAD || ''),
      initiateApproval:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_INITIATE_APPROVAL || ''),
      downloadValidationResults:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_ARREARSFILE_APPROVAL || ''),
      activeReportingPeriod:
        (process.env.REACT_APP_ARREARS_API_URL || '') +
        (process.env.REACT_APP_ARREARS_API_PATH_ACTIVING_REPORTING_PERIOD || '')
    }
  },

  // Configurations specific to interactions with the arrears files API
  arrearsFilesApi: {
    requestTimeout: Number(process.env.REACT_APP_ARREARS_FILES_API_REQUEST_TIMEOUT) || 5000,
    urls: {
      delete:
        (process.env.REACT_APP_ARREARS_FILES_API_URL || '') +
        (process.env.REACT_APP_ARREARS_FILES_API_PATH_DELETE || '')
    }
  },

  lenderApi: {
    requestTimeout: Number(process.env.REACT_APP_DELEGATE_API_REQUEST_TIMEOUT) || 5000,
    urls: {
      lenderDetails:
        (process.env.REACT_APP_LENDER_API_URL || '') + process.env.REACT_APP_LENDER_API_PATH_DETAILS
    }
  },

  purposeTestReportApi: {
    requestTimeout: Number(process.env.REACT_APP_PURPOSE_TEST_REPORT_API_REQUEST_TIMEOUT) || 60000,
    urls: {
      inventory:
        (process.env.REACT_APP_PURPOSE_TEST_REPORT_API_URL || '') +
        process.env.REACT_APP_PURPOSE_TEST_REPORT_API_PATH_INVENTORY,
      download:
        (process.env.REACT_APP_PURPOSE_TEST_REPORT_API_URL || '') +
        process.env.REACT_APP_PURPOSE_TEST_REPORT_API_PATH_DOWNLOAD
    }
  },

  uploadFilesApi: {
    requestTimeout: Number(process.env.REACT_APP_DOCUMENT_API_REQUEST_TIMEOUT) || 120000,
    urls: {
      uploadFiles:
        (process.env.REACT_APP_PI_REQUEST_API_URL || '') +
        process.env.REACT_APP_PI_REQUEST_UPLOAD_FILES_API_URL
    }
  },

  piRequestApi: {
    requestTimeout: Number(process.env.REACT_APP_DELEGATE_API_REQUEST_TIMEOUT) || 120000,
    urls: {
      submitRequest:
        (process.env.REACT_APP_PI_REQUEST_API_URL || '') +
        process.env.REACT_APP_PI_REQUEST_SUBMIT_API_URL
    }
  },

  iifReport: {
    url: process.env.REACT_APP_IIF_POWERBI_REPORT_URL
  },

  fileTransferApi: {
    requestTimeout: Number(process.env.REACT_APP_FILE_TRANSFER_API_REQUEST_TIMEOUT) || 120000,
    urls: {
      files:
        (process.env.REACT_APP_FILE_TRANSFER_API_URL || '') +
        process.env.REACT_APP_FILE_TRANSFER_API_PATH_FILES,
      lenders:
        (process.env.REACT_APP_FILE_TRANSFER_API_URL || '') +
        process.env.REACT_APP_FILE_TRANSFER_API_PATH_LENDERS,
      submitFileTransferRequest:
        (process.env.REACT_APP_FILE_TRANSFER_API_URL || '') +
        process.env.REACT_APP_FILE_TRANSFER_SUBMIT_API_PATH,
      downloadFile:
        (process.env.REACT_APP_FILE_TRANSFER_API_URL || '') +
        process.env.REACT_APP_FILE_TRANSFER_API_REQUEST_PATH_FILE_DOWNLOAD,
      deleteFile:
        (process.env.REACT_APP_FILE_TRANSFER_API_URL || '') +
        process.env.REACT_APP_FILE_TRANSFER_API_REQUEST_PATH_FILE_DELETE
    }
  }
};
