import { useState } from 'react';
import {
  CashReceiptTypeCode,
  masterUserSubmittedClaimSchema,
  NetOperatingIncomeRentalIncome,
  PartialPayment,
  submittedClaimSchema
} from '@hobt/claim-domain';
import { HbtServiceErrorCodes, HttpResponseStatusCodes } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';
import { deepCopy, isNullOrDefault } from '@hobt/utils';
import { formatDate } from 'Components/Common/Api/utils/DateParser';
import getPathInObject from 'Constants/Utils/GetPathInObject';
import { convertBooleanValuesToStringInJsonObject } from 'Constants/Utils/JSONConverters/convertBooleanValuesToStringInJsonObject';
import { convertStringValuesToBoolean } from 'Constants/Utils/JSONConverters/convertStringToBoolean';
import { setPathInObject } from 'Constants/Utils/SetPathInObject/setPathInObject';
import {
  useHBTFormContext,
  useHBTFormActionsContext
} from 'Feature/Claims/components/HBTFormContext';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import i18n from 'i18next';
import { useForm, UseFormReturn } from 'react-hook-form';
import { config } from '../../../../../config';
import {
  ClaimSubmissionBorrowers,
  ClaimSubmissionDetail,
  ClaimSubmissionListing,
  FinalClaimResponseData
} from '../../../models/ClaimsSubmissionDetailsProps';
import { BorrowerCharge } from '../../ClaimsSubmissionForm/types';
import { CashReceipt } from './types';

const useClaimsSubmissionDetails = () => {
  const {
    uuid,
    claimData,
    lockTabs,
    isMasterUserEditingPostAdjudicationClaim,
    updateClaimData,
    setLoadingStatus
  } = useHBTFormContext();
  const { enableEditMode, disableEditMode } = useHBTFormActionsContext();

  const claimID = uuid ?? '';
  const defaultValues = { detail: {}, ...claimData } ?? { detail: {} };

  // Need to have the authorizedOfficers array be a top-level property in the data to properly render with useFieldArray.
  defaultValues.authorizedOfficers = defaultValues?.lender?.authorizedOfficers;
  defaultValues.partialPayments = defaultValues?.cashReceipts?.filter?.(
    (receipt: CashReceipt) => receipt.typeCode === CashReceiptTypeCode.PartialPayment
  );
  defaultValues.netOperatingIncomesRentalIncomes = defaultValues?.cashReceipts?.filter?.(
    (receipt: CashReceipt) =>
      receipt.typeCode === CashReceiptTypeCode.NetOperatingIncomeRentalIncome
  );
  defaultValues.detail.receivedLenderNotificationDate =
    defaultValues?.assessment?.receivedLenderNotificationDate;
  defaultValues.detail.receivedDefaultInterestRatePercent =
    defaultValues?.assessment?.receivedDefaultInterestRatePercent;
  defaultValues.detail.receivedLastCompleteInstallmentPaidDueDate =
    defaultValues?.assessment?.receivedLastCompleteInstallmentPaidDueDate;
  defaultValues.detail.receivedOutstandingPrincipalBalanceAmount =
    defaultValues?.assessment?.receivedOutstandingPrincipalBalanceAmount;
  defaultValues.detail.receivedTaxAccountBalanceAmount =
    defaultValues?.assessment?.receivedTaxAccountBalanceAmount;
  defaultValues.detail.receivedDeficiencySalePriceAmount =
    defaultValues?.assessment?.receivedDeficiencySalePriceAmount;
  defaultValues.detail.receivedDeficiencySaleClosingDate =
    defaultValues?.assessment?.receivedDeficiencySaleClosingDate;
  defaultValues.detail.receivedDeficiencySaleCommissionAmount =
    defaultValues?.assessment?.receivedDeficiencySaleCommissionAmount;

  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastState, setToastState] = useState<ApplicationStates>(ApplicationStates.DEFAULT);
  const [toastMessage, setToastMessage] = useState<string | FieldValue>('');
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);

  const hookForm = useForm({
    resolver: hbtResolver(
      isMasterUserEditingPostAdjudicationClaim === true
        ? masterUserSubmittedClaimSchema
        : submittedClaimSchema
    ),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues
  } as Record<string, any>);

  const apiClientConfig: ApiClientConfig = {
    timeout: Number(config.defaultApi.requestTimeout) || 2000
  };

  const authContext = useAuthenticationContext();
  const { putWithAuth } = ApiClient(authContext, apiClientConfig);

  const formatDatesForSubmit = (listing: ClaimSubmissionListing[]): ClaimSubmissionListing[] => {
    return listing?.map(
      ({
        appraisalDate,
        date,
        expiryDate,
        comparativeMarketAnalysisDate,
        ...rest
      }: ClaimSubmissionListing) => {
        return {
          ...rest,
          appraisalDate: formatDate(appraisalDate),
          date: formatDate(date),
          expiryDate: formatDate(expiryDate),
          comparativeMarketAnalysisDate: formatDate(comparativeMarketAnalysisDate)
        } as ClaimSubmissionListing;
      }
    );
  };

  const arrayExists = <T>(data: T) => Array.isArray(data) && data.length > 0;

  const getCashReceiptsData = (
    partialPayments?: PartialPayment[],
    netOperatingIncomesRentalIncomes?: NetOperatingIncomeRentalIncome[]
  ): CashReceipt[] => {
    let cashReceipts: CashReceipt[] = [];

    if (arrayExists(partialPayments) && arrayExists(netOperatingIncomesRentalIncomes)) {
      cashReceipts = (partialPayments ?? [])
        ?.map((partialPayment: PartialPayment) => ({
          ...partialPayment,
          typeCode: CashReceiptTypeCode.PartialPayment
        }))
        .concat(
          (netOperatingIncomesRentalIncomes ?? [])?.map(
            (netOperatingIncomeRentalIncome: NetOperatingIncomeRentalIncome) => ({
              ...netOperatingIncomeRentalIncome,
              typeCode: CashReceiptTypeCode.NetOperatingIncomeRentalIncome
            })
          )
        );
    } else if (arrayExists(partialPayments) && !arrayExists(netOperatingIncomesRentalIncomes)) {
      cashReceipts = (partialPayments ?? [])?.map((partialPayment: PartialPayment) => ({
        ...partialPayment,
        typeCode: CashReceiptTypeCode.PartialPayment
      }));
    } else if (!arrayExists(partialPayments) && arrayExists(netOperatingIncomesRentalIncomes)) {
      cashReceipts = (netOperatingIncomesRentalIncomes ?? [])?.map(
        (netOperatingIncomeRentalIncome: NetOperatingIncomeRentalIncome) => ({
          ...netOperatingIncomeRentalIncome,
          typeCode: CashReceiptTypeCode.NetOperatingIncomeRentalIncome
        })
      );
    }

    return cashReceipts;
  };

  const convertBorrowersDataPostAdjudication = (borrowers: ClaimSubmissionBorrowers[]) => {
    return borrowers?.map((borrower) => {
      return {
        ...borrower,
        reasonNotPursuedTypeCode: !isNullOrDefault(borrower.reasonNotPursuedTypeCode)
          ? borrower.reasonNotPursuedTypeCode
          : undefined
      };
    });
  };

  const convertDetailDataPostAdjudication = (detail: ClaimSubmissionDetail) => {
    const optionalDropDownDetailFields = [
      'propertyOccupancyStatusCode',
      'propertySoldByTypeCode',
      'propertyTransferredByTypeCode',
      'propertyVacatedByTypeCode'
    ];

    optionalDropDownDetailFields.forEach((field: string) => {
      if (getPathInObject(detail, field) === '' || Number.isNaN(getPathInObject(detail, field))) {
        setPathInObject(detail, field, undefined);
      }
    });

    return detail;
  };

  const convertClaimSubmissionFormToFinalClaimData = (data: FinalClaimResponseData) => {
    let convertedData = deepCopy(data);

    const {
      approvedLenderReferenceNumber,
      assessment,
      borrowerCharges,
      detail,
      lender,
      listings,
      netOperatingIncomesRentalIncomes,
      partialPayments
    } = convertedData;

    convertedData = {
      ...convertedData,
      assessment: {
        ...(assessment ?? {}),
        receivedDeficiencySaleCommissionAmount: detail.receivedDeficiencySaleCommissionAmount ?? 0,
        receivedDeficiencySaleClosingDate: detail.receivedDeficiencySaleClosingDate ?? undefined,
        receivedDeficiencySalePriceAmount: detail.receivedDeficiencySalePriceAmount ?? 0,
        receivedDefaultInterestRatePercent: detail.receivedDefaultInterestRatePercent ?? 0,
        receivedLenderNotificationDate: detail.receivedLenderNotificationDate ?? undefined,
        receivedLastCompleteInstallmentPaidDueDate:
          detail.receivedLastCompleteInstallmentPaidDueDate ?? '',
        receivedOutstandingPrincipalBalanceAmount:
          detail.receivedOutstandingPrincipalBalanceAmount ?? 0,
        receivedTaxAccountBalanceAmount: detail.receivedTaxAccountBalanceAmount ?? 0
      },
      borrowerCharges:
        Array.isArray(borrowerCharges) && borrowerCharges.length > 0
          ? borrowerCharges?.map((borrowerCharge: BorrowerCharge) => ({
              ...borrowerCharge,
              originalClaimSequenceNumber: claimData?.sequenceNumber,
              adjustedAmount: borrowerCharge?.adjustedAmount,
              adjustedDate: borrowerCharge?.adjustedDate,
              previouslyApprovedAmount: borrowerCharge?.previouslyApprovedAmount,
              previouslyApprovedDate: borrowerCharge?.previouslyApprovedDate,
              previouslyApprovedComment: borrowerCharge?.previouslyApprovedComment,
              acceptableAmount: borrowerCharge?.acceptableAmount,
              acceptableDate: borrowerCharge?.acceptableDate,
              accruedInterestCalculations: borrowerCharge?.accruedInterestCalculations,
              creditOfReceiptInterestCalculation: borrowerCharge?.creditOfReceiptInterestCalculation
            }))
          : [],
      listings: formatDatesForSubmit(listings),
      cashReceipts: getCashReceiptsData(partialPayments, netOperatingIncomesRentalIncomes)
    };

    if (isMasterUserEditingPostAdjudicationClaim === true) {
      convertedData = {
        ...convertedData,
        approvedLenderReferenceNumber:
          lender.approvedLenderReferenceNumber != null
            ? lender.approvedLenderReferenceNumber
            : approvedLenderReferenceNumber,
        borrowers: convertBorrowersDataPostAdjudication(convertedData.borrowers),
        detail: convertDetailDataPostAdjudication(convertedData.detail)
      };
      delete lender.approvedLenderReferenceNumber;
    }

    // Remove the authorizedOfficers array from the object before sending the data.
    // The authorizedOfficers data is also set in lender.authorizedOfficers.
    delete convertedData.authorizedOfficers;
    // TODO: Add form type to the HBTContext and render card fields with appropriate name based on submit or adjudication form.
    delete convertedData.partialPayments;
    delete convertedData.netOperatingIncomesRentalIncomes;
    delete convertedData.detail.receivedDefaultRatePercent;
    delete convertedData.detail.receivedLastCompleteInstallmentPaidDueDate;
    delete convertedData.detail.receivedOutstandingPrincipalBalanceAmount;
    delete convertedData.detail.receivedTaxAccountBalanceAmount;
    delete convertedData.detail.receivedLenderNotificationDate;
    delete convertedData.detail.receivedDefaultInterestRatePercent;

    return convertedData;
  };

  const handleSubmit: any = async (data: FinalClaimResponseData) => {
    setIsSaveButtonDisabled(true);
    const url = `${config.claimApi.urls.finalClaim}/${claimID}`;

    try {
      setLoadingStatus?.({
        isLoading: true,
        spinnerHeading: 'Globals-Saving-Heading',
        spinnerDescription: 'Globals-Saving-Description'
      });

      const finalClaimUpdateData = convertClaimSubmissionFormToFinalClaimData(data);

      finalClaimUpdateData.borrowerCharges = finalClaimUpdateData.borrowerCharges?.filter?.(
        (borrowCharge: BorrowerCharge) => borrowCharge.code !== ''
      );
      const response = await putWithAuth(
        url,
        JSON.parse(JSON.stringify(finalClaimUpdateData, convertStringValuesToBoolean))
      );
      const transformedResponse = convertBooleanValuesToStringInJsonObject(
        response?.data?.data[0] ?? { detail: {} }
      );
      // When resetting the default values of the form also reset top-level authorizedOfficers.
      transformedResponse.authorizedOfficers = transformedResponse?.lender?.authorizedOfficers;

      // resetting default values after the user clicks submit so the values can be displayed without refresh
      transformedResponse.partialPayments = transformedResponse?.cashReceipts?.filter?.(
        (receipt: CashReceipt) => receipt.typeCode === CashReceiptTypeCode.PartialPayment
      );
      transformedResponse.netOperatingIncomesRentalIncomes =
        transformedResponse?.cashReceipts?.filter?.(
          (receipt: CashReceipt) =>
            receipt.typeCode === CashReceiptTypeCode.NetOperatingIncomeRentalIncome
        );
      transformedResponse.detail.receivedDefaultRatePercent =
        transformedResponse?.assessment?.receivedDefaultRatePercent;
      transformedResponse.detail.receivedLastCompleteInstallmentPaidDueDate =
        transformedResponse?.assessment?.receivedLastCompleteInstallmentPaidDueDate;
      transformedResponse.detail.receivedOutstandingPrincipalBalanceAmount =
        transformedResponse?.assessment?.receivedOutstandingPrincipalBalanceAmount;
      transformedResponse.detail.receivedTaxAccountBalanceAmount =
        transformedResponse?.assessment?.receivedTaxAccountBalanceAmount;
      hookForm.reset({
        ...transformedResponse
      });
      setLoadingStatus?.({
        isLoading: false
      });
      if (updateClaimData != null) {
        updateClaimData([response?.data?.data?.[0]]);
      }

      disableEditMode();

      if (lockTabs != null) lockTabs(false);
      setToastState(ApplicationStates.SUCCESS);
      setToastMessage(i18n.t('Globals-Toast-Success-Item-Saved'));
      setShowToast(true);
      setIsSaveButtonDisabled(false);
    } catch (error: any) {
      setIsSaveButtonDisabled(false);
      setLoadingStatus?.({
        isLoading: false
      });

      if (
        error?.response?.status === HttpResponseStatusCodes.ServerError &&
        error?.response?.data?.error?.errorCode ===
          HbtServiceErrorCodes.HBT_ERR_INVALID_HBT_ERR.code
      ) {
        setToastState(ApplicationStates.ERROR);
        setToastMessage(i18n.t('DefaultSubmission-OtherErrors'));
        setShowToast(true);
      }
    }
  };

  const onSubmitErrors: any = (errs: any) => {
    // TODO: Remove once validations are in place
    // eslint-disable-next-line no-console
    console.log('FORM ERRORS: ', errs);
    // eslint-disable-next-line no-console

    console.log('FORM VALUES: ', hookForm.getValues());
  };

  const onEditClick = () => {
    if (lockTabs != null) lockTabs(true);
    enableEditMode();
  };

  const onCancelClick = () => {
    if (lockTabs != null) lockTabs(false);
    disableEditMode();
    hookForm.reset();
  };

  const onCloseToast = () => {
    setShowToast(false);
  };

  return {
    hookForm,
    showToast,
    toastState,
    toastMessage,
    isSaveButtonDisabled,
    handleSubmit,
    onSubmitErrors,
    onEditClick,
    onCancelClick,
    onCloseToast,
    formatDatesForSubmit
  };
};

export default useClaimsSubmissionDetails;
