import { useEffect, useState } from 'react';
import { CorrespondenceType, correspondenceSchema } from '@hobt/claim-domain';
import { hbtResolver } from '@hobt/schema-validator';
import { UserInfo } from '@hobt/user-domain';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { useFeature } from 'flagged';
import { ApiClientConfig, ApiClient } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { UseFormReturn, useForm } from 'react-hook-form';
import { config } from '../../../../../config';
import { useHBTFormContext } from '../../HBTFormContext';
import { CorrespondenceSubmitResponse } from './types';

export const useCorrespondenceForm = (
  onSuccessCallback?: Function,
  onErrorCallback?: Function,
  onCancelCallback?: Function,
  claimID?: string
) => {
  const sitecoreContextFactory = useSitecoreContext();
  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const sitecoreContext = sitecoreContextFactory.sitecoreContext as HbtSitecoreContextType;
  const userDetails: UserInfo | undefined = sitecoreContext?.user;
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const correspondenceUrl: string = config.claimApi.urls.correspondences;

  const authContext = useAuthenticationContext();
  const apiClientConfig: ApiClientConfig = {
    timeout: config.defaultApi.requestTimeout
  };

  const isExternalPortal = config.app.siteType === FeatureFlags.EXTERNAL;

  const { postWithAuth } = ApiClient(authContext, apiClientConfig);
  const { setLoadingStatus } = useHBTFormContext();

  const submitCorrespondence = async (payload: object) => {
    try {
      if (claimID != null) {
        await postWithAuth(correspondenceUrl, { claimID, correspondencePayload: payload });
        return Promise.resolve(CorrespondenceSubmitResponse.Success);
      }
      return CorrespondenceSubmitResponse.Error;
    } catch (e: any) {
      if (e?.response?.status != null) {
        const errs: { [key: number]: CorrespondenceSubmitResponse } = {
          400: CorrespondenceSubmitResponse.BadRequest,
          401: CorrespondenceSubmitResponse.NotAuthorized,
          500: CorrespondenceSubmitResponse.ServerError
        };

        return errs[e?.response?.status] ?? CorrespondenceSubmitResponse.Error;
      }
      return CorrespondenceSubmitResponse.Error;
    }
  };

  const hookForm = useForm({
    resolver: hbtResolver(correspondenceSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const attachmentFlag = hookForm.watch('attachmentFlag');
  useEffect(() => {
    hookForm.register('commentTypeCode');
    hookForm.register('submitterUserID');
    hookForm.register('submitterUserTypeCode');
    hookForm.register('correspondenceTypeCode');
    hookForm.register('attachments');

    if (isExternalPortal) {
      hookForm.register('attachmentFlag');
      hookForm.setValue('attachmentFlag', true);
    }

    if (userDetails != null) {
      hookForm.setValue('submitterUserID', userDetails.userID);
      hookForm.setValue('submitterUserTypeCode', userDetails.userTypeCode);
    }

    hookForm.setValue(
      'correspondenceTypeCode',
      CorrespondenceType[isInternal ? 'Internal' : 'External']
    );
  }, []);

  const onCancel = () => {
    hookForm.reset();

    if (onCancelCallback != null) {
      onCancelCallback();
    }
  };

  const onSubmit = async (data: object) => {
    if ('otherAdjudicationNoteCategoryDescription' in data) {
      data = {
        ...data,
        otherAdjudicationNoteCategoryDescription: String(
          data?.otherAdjudicationNoteCategoryDescription
        ).replace(/[^\w\s\d]/g, '')
      };
    }

    if ('remarkText' in data) {
      data = {
        ...data,
        remarkText: String(data?.remarkText).replace(/[^\w\s\d]/g, '')
      };
    }

    setDisableSubmitBtn(true);
    const attachmentValues = Object.values(data)[6];
    if (attachmentFlag && attachmentValues == undefined) {
      hookForm.setError('attachments', {
        type: 'required',
        message: 'should have required property'
      });
      setLoadingStatus?.({
        isLoading: false
      });
      return;
    }

    setLoadingStatus?.({
      isLoading: true,
      spinnerHeading: 'Globals-Saving-Heading',
      spinnerDescription: 'Globals-Saving-Description',
      isFlyout: true
    });

    const res = await submitCorrespondence(data);

    setLoadingStatus?.({
      isLoading: false
    });
    if (res === CorrespondenceSubmitResponse.Success && onSuccessCallback != null) {
      onSuccessCallback();
    } else if (onErrorCallback != null) {
      onErrorCallback();
    }
  };

  const onError = () => {
    hookForm.setError('attachments', {
      type: 'required',
      message: 'should have required property'
    });
  };

  return {
    hookForm,
    onCancel,
    onSubmit,
    onError,
    submitCorrespondence,
    disableSubmitBtn
  };
};
