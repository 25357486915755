import i18n from 'i18next';
import React from 'react';

import { ClaimsInventoryTabEnum } from 'Feature/Claims/components/ClaimsInventory/types';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';

import { TableHeaderProps } from './types';

const ExternalTableHeader = ({
  fields,
  currentTab,
  onSort,
  getSortDirectionForField
}: TableHeaderProps) => {
  const claimTypeCodeLanguage =
    i18n.language === 'en' ? 'ClaimTypeCodeEnglish' : 'ClaimTypeCodeFrench';

  const StandardHeader: React.FC = () => (
    <>
      <DataTableHeaderItem name="rowAction" displayText={fields.rowActionLabel} />
      <DataTableHeaderItem
        name="lenderReference"
        displayText={fields.lenderReferenceLabel}
        colspan={2}
      />
      <DataTableHeaderItem name="cmhcLoan" displayText={fields.loanLabel} />
      <DataTableHeaderItem name="claimStatus" displayText={fields.claimStatusLabel} />
      <DataTableHeaderItem
        sortable
        sortDirection={getSortDirectionForField('claimReceivedDate')}
        onSortCallback={onSort}
        name="claimReceivedDate"
        displayText={fields.submittedDateLabel}
      />
      <DataTableHeaderItem name="claimTypeIndicator" displayText={fields.claimIndicatorLabel} />
      <DataTableHeaderItem
        name={i18n.language === 'en' ? 'ApprovedLenderEnglishName' : 'ApprovedLenderFrenchName'}
        displayText={fields.lenderNameLabel}
        sortable
        sortDirection={getSortDirectionForField('lenderName')}
        onSortCallback={onSort}
      />
      {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
        <DataTableHeaderItem
          name="ClaimPaidDate"
          displayText={fields.datePaidLabel}
          sortable
          sortDirection={getSortDirectionForField('ClaimPaidDate')}
          onSortCallback={onSort}
        />
      )}
      <DataTableHeaderItem name="transitNumber" displayText={fields.transitNumber} />
      <DataTableHeaderItem
        name={claimTypeCodeLanguage}
        displayText={fields.typeOfClaimLabel}
        sortable
        sortDirection={getSortDirectionForField(claimTypeCodeLanguage)}
        onSortCallback={onSort}
      />
      <DataTableHeaderItem name="primaryBorrower" displayText={fields.primaryBorrowerNameLabel} />
      <DataTableHeaderItem
        name="propertyAddress"
        // TODO: Update with value from sitecore, currently value is missing from sitecore
        displayText={fields.propertyAddressLabel}
      />
      <DataTableHeaderItem name="userName" displayText={fields.userName} />
    </>
  );

  const DraftHeader: React.FC = () => (
    <>
      <DataTableHeaderItem name="rowAction" displayText={fields.rowActionLabel} />
      <DataTableHeaderItem
        name="lenderReference"
        displayText={fields.lenderReferenceLabel}
        colspan={2}
      />
      <DataTableHeaderItem name="cmhcLoan" displayText={fields.loanLabel} />
      <DataTableHeaderItem name="mainSup" displayText={fields.mainSupp} />
      <DataTableHeaderItem
        name="lenderName"
        displayText={fields.lenderNameLabel}
        sortable
        sortDirection={getSortDirectionForField('lenderName')}
        onSortCallback={onSort}
      />
      {currentTab.name !== ClaimsInventoryTabEnum.Draft && (
        <DataTableHeaderItem
          name="datePaid"
          displayText={fields.datePaidLabel}
          sortable
          sortDirection={getSortDirectionForField('datePaid')}
          onSortCallback={onSort}
        />
      )}
      <DataTableHeaderItem name="transitNumber" displayText={fields.transitNumber} />
      <DataTableHeaderItem name="userName" displayText={fields.userName} />
      {currentTab.name === ClaimsInventoryTabEnum.Draft && (
        <DataTableHeaderItem name="lastSaved" displayText={fields.lastSaved} />
      )}
    </>
  );

  return currentTab.name === ClaimsInventoryTabEnum.Draft ? <DraftHeader /> : <StandardHeader />;
};

export default ExternalTableHeader;
